import React, { useEffect, useState } from 'react';
import { getProjectByTaskId, getTaskByProjectId } from '../services/TaskService';
import { useOidcAccessToken } from '@axa-fr/react-oidc';
import GanttChartComponent from '../components/treegridGantt/Gantt/GanttChartComponent';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Box, Stack, Typography, Grid } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { taskReschedule } from '../services/ProjectService';
import dayjs from 'dayjs';

const GanttParentPage: React.FC<any> = () => {
    const { accessToken } = useOidcAccessToken();
    const [ganttData, setGanttData] = useState<any[]>([]);
    const location = useLocation();
    let data = location?.state?.project;
    console.log('data', data);
    let navigate = useNavigate();


    const [tasks, setTasks] = useState<any>([]);

    useEffect(() => {
        const fetchProjectData = async () => {
            const response = await getTaskByProjectId(data.id, accessToken);
            const fetchedTasks = response.data;
            setTasks(fetchedTasks);
            console.log('tasks', tasks);
        };

        fetchProjectData();
    }, [accessToken, data.id]);

    const handleGanttDataUpdate = async (updatedData: any) => {
        const updatedata = updatedData?.Changes[0];
        console.log(parseInt(updatedata?.id));
        const matchingTask = tasks.find((task: any) => task?.taskId === parseInt(updatedata?.id));
        const wbs = (matchingTask.wbs).split(".").slice(0, -1);
        const startDate = updatedata?.Start ? dayjs(updatedata?.Start).format('YYYY-MM-DDTHH:mm:ss[Z]') : matchingTask.startTime;
        const endDate = dayjs(updatedata?.End).format('YYYY-MM-DDTHH:mm:ss[Z]');
        reschedule(updatedata?.id, wbs.join("."), startDate, endDate);
        setGanttData(updatedData);
    };

    const reschedule = async (taskId: any, wbs: any, startTime: any, endTime: any) => {
        console.log('time', taskId, startTime, endTime, wbs)

        try {
            const result = await taskReschedule(data.id, taskId, wbs, startTime, endTime, accessToken);


        } catch (error) {
            console.error('Error in reschedule:', error);
        }
    };

    const handleClose = (state: any) => {
        navigate('/soldproduction');
    };

    return (

        <>
            <Grid item xs={12}>
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    mb={3}
                >
                    <Button
                        onClick={handleClose}
                        variant="contained"
                        startIcon={<ArrowBackIcon />}
                        className="greyTonalButton"
                        sx={{ marginLeft: "32px" }}
                    >
                        Previous
                    </Button>
                    {/* <Button
                        onClick={handleClose}
                        variant="contained"
                        endIcon={<ArrowForwardIcon />}
                        className="createbidbtnnext"
                        sx={{ marginRight: "32px" }}
                    >
                        Next
                    </Button> */}
                </Box>

                <Typography
                    variant="h4"
                    sx={{ mb: 2, color: 'text.primary' }}
                >
                    Gantt Chart View  - Order No :{data.jobNo}
                </Typography>
            </Grid>

            <GanttChartComponent
                selected={tasks}
                onGanttDataUpdate={handleGanttDataUpdate}
            />
        </>


    );
};

export default GanttParentPage;
