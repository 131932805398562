import React, { useContext, useState } from 'react';
import { FormControl, Grid, TextField, Card, InputLabel, MenuItem, Select, SelectChangeEvent, Stack, IconButton, Checkbox, ListItemText, Button, Typography, Alert } from '@mui/material';
import { CodeList, ConditionDef, FormDef, ItemDef, ItemGroupDef } from '../../../interface/SdmInterfaces';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { UserContext } from '../../../hooks/UserSession';

interface Props {
    conditionalDef: ConditionDef[]; // Replace with your specific type
    setConditionalDef: React.Dispatch<React.SetStateAction<ConditionDef[]>>; // Replace with your specific type
    itemRef: any;
    itemDefs: ItemDef[];
    codeLists: CodeList[];
    itemGroupOID: any;
    questionOID: any;
    itemGroupDefs: any;
    setItemGroupDefs: React.Dispatch<React.SetStateAction<ItemGroupDef[]>>;
    setConditionOid: any;
    formDef: FormDef;
    formDefs: FormDef[];
    setFormDef: React.Dispatch<React.SetStateAction<FormDef[]>>;

}
interface Condition {
    OID: string;
    conditional: string;
    operand: string;
    codedVale: string;
}
const ItemGroupConditionDef: React.FC<Props> = ({ conditionalDef, setConditionalDef, itemRef, itemDefs, codeLists, itemGroupOID, itemGroupDefs, setItemGroupDefs, setConditionOid, formDef, formDefs, setFormDef }) => {
    const [conditionData, setConditionData] = useState<Condition[]>([]);
    // Force re-render
    const { userSessionDetails, setUserDetails } = useContext(UserContext);
    const [role, setRole] = React.useState<any>([] as any[]);




    React.useEffect(() => {
        setRole(userSessionDetails.role.name);
    }, [])
    //console.log(conditionalDef)
    //operand  changes 
    const operandOptions = [
        { value: '==', label: '==' },
        { value: '!=', label: '!=' },
        { value: '<=', label: '<=' },
        { value: '>=', label: '>=' },
        { value: ' ', label: ' ' },

    ];
    const handleAddConditionData = () => {
        setConditionData([...conditionData, { OID: '', conditional: '', operand: '', codedVale: '' }]);
    };
    const [conditionals, setConditionals] = useState<string[]>([]);
    const [operands, setOperands] = useState<string[]>([]);
    const [codedValues, setCodedValues] = useState<string[]>([]);
    const [conditional, setConditional] = useState<any>(''); // To handle conditional ref
    const [conditionalCodeList, setConditionalCodeList] = useState<CodeList[]>([]);
    const [codedVale, setCodedValue] = useState<any>( '' || []); // State to hold selected operand
    const [dataType, setDataType] = useState<any>(); // To handle conditional ref
    const [operand, setOperand] = useState<string>(''); // State to hold selected operand
    const [multiSelect, setMultiSelect] = useState<any>([]); // State to hold selected operand
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    // State to hold the list of conditions
    const [conditions, setConditions] = useState([
        { conditional: '', operand: '', codedVale: '', dataType: 'text' }
    ]);

    // Handlers
    // Handlers
    const handleAddCondition = (conditionOid) => {
        // Find the selected condition
        const selectedCondition = conditionalDef.find(condition => condition.OID === conditionOid);

        // Check if any existing FormalExpression contains an empty string
        const hasEmptyCondition = selectedCondition && selectedCondition.FormalExpression.text.includes('"" "" ""');

        if (hasEmptyCondition) {
            // Set alert message and show the alert
            setAlertMessage("Cannot add new condition: an empty condition already exists.");
            setShowAlert(true);
            return; // Stop execution if validation fails
        }

        // Proceed with updating the condition
        const updatedConditionalDef = conditionalDef.map(condition => {
            if (condition.OID === conditionOid) {
                const newCondition = `("" "" "")`;

                // Return the updated condition with new FormalExpression
                return {
                    ...condition,
                    FormalExpression: {
                        ...condition.FormalExpression,
                        text: condition.FormalExpression?.text
                            ? `${condition.FormalExpression.text} || ${newCondition}`
                            : newCondition,
                        Context: condition.FormalExpression?.Context || "" // Ensure Context is set
                    }
                };
            }
            // Return the original condition for non-matching items
            return condition;
        });

        // Update the state with the modified conditionalDef array
        setConditionalDef(updatedConditionalDef);

        // Optional: Log the updated array or the specific item
        //console.log(updatedConditionalDef.find(res => res.OID === conditionOid));
    };

    //handleQuestions selecting for conditional Rendering
    // const handleConditionalItemChange = (itemGroupOid, event, index) => {
    //     const itemDef = itemDefs.find(item => item.OID === event.target.value);
    //     const newConditional = itemDef?.OID;

    //     // Reset operand and coded value when a new question is selected
    //     setOperand(''); // or null
    //     setCodedValue(''); // or null

    //     const questionItemDef = itemDefs?.filter(q => q.OID === event.target.value);
    //     let newDataType, updatedCodeLists;

    //     questionItemDef.map(itemData => {
    //         newDataType = itemData.DataType;
    //         updatedCodeLists = codeLists.filter(cl => cl.OID === itemData.CodeListRef?.CodeListOID);
    //     });

    //     setConditional(newConditional); // Update the selected question (conditional)
    //     setDataType(newDataType); // Update the datatype based on the selected question
    //     setConditionalCodeList(updatedCodeLists || []); // Update code lists

    //     // You can skip calling createConditionalDefFormalExpression here since operand and codedVale are not yet selected
    // };
    const handleDefaultItemChange = (selectedValue: any) => {
        //////console.log(selectedValue);

        const itemDef = itemDefs.find(item => item.OID === selectedValue);
        if (itemDef) {
            setConditional(itemDef.OID);
            //////console.log('Conditional:', itemDef.Question.TranslatedText.text);
        }

        const questionItemDef = itemDefs.filter(q => q.OID === selectedValue);
        questionItemDef.forEach(itemData => {
            //////console.log('DataType:', itemData.DataType, 'CodeListRef:', itemData.CodeListRef);
            setDataType(itemData.DataType);

            const updatedCodeLists = codeLists.filter(cl => cl.OID === itemData.CodeListRef?.CodeListOID);
            if (updatedCodeLists.length > 0) {
                //////console.log('Updated Code List:', updatedCodeLists);
                setConditionalCodeList(updatedCodeLists);
            }
        });
    };

    React.useEffect(() => {
        handleConditionExpression();
        ////console.log(codedVale, operand, conditional)

    }, [itemRef.CollectionExceptionConditionOID]);// Empty dependency array ensures it runs only once on component mount

    const handleConditionExpression = () => {
        const conditionsToProcess = conditionalDef.filter(condition =>
            condition.OID === itemRef.CollectionExceptionConditionOID
        );

        const newDataTypes: string[] = [];
        const newCodedValues: string[] = [];
        const newOperands: string[] = [];
        const newConditionals: string[] = [];

        conditionsToProcess.forEach(conditionDef => {
            const FormalExpression = conditionDef?.FormalExpression.text;
            const matches = FormalExpression?.match(/([^\s()]+)\s*([!<>=]+)\s*"([^"]+)"/);

            if (matches) {
                const itemFilteredDef = itemDefs.find(id => id.OID === matches[1]);

                if (itemFilteredDef) {
                    newDataTypes.push(itemFilteredDef.DataType);

                    const codeList = codeLists.find(cl => cl.OID === itemFilteredDef.CodeListRef?.CodeListOID);

                    if (itemFilteredDef.CodeListRef) {
                        if (itemFilteredDef.DataType === 'multiselect' || itemFilteredDef.DataType === 'multiSelectComboBox') {
                            const selectedValues = matches[3] ? matches[3].split(',') : [];
                            newCodedValues.push(...selectedValues); // Spread operator to add multiple values
                            newOperands.push(matches[2]);
                            newConditionals.push(itemFilteredDef.OID || '');
                            handleDefaultItemChange(itemFilteredDef.OID);
                        } else {
                            const codeListItem = codeList?.CodeListItem.find(codedValue => codedValue.CodedValue === matches[3]);
                            newCodedValues.push(codeListItem?.CodedValue || '');
                            newOperands.push(matches[2]);
                            newConditionals.push(itemFilteredDef.OID || '');
                            handleDefaultItemChange(itemFilteredDef.OID);
                        }
                    } else {
                        newCodedValues.push(matches[3] || '');
                        newOperands.push(matches[2]);
                        newConditionals.push(itemFilteredDef.OID || '');
                    }
                }
            }
        });

        // Update states with the collected values
        setDataType(newDataTypes);
        setCodedValues(newCodedValues);
        setOperands(newOperands);
        setConditionals(newConditionals);
    };

    // createConditionalDefFormalExpression
    const createConditionalDefFormalExpression = (
        itemGroupConditionOid,
        formOid,
        conditionalCodeLists, // Changed to plural to indicate an array
        operands,             // Changed to plural to indicate an array
        codedValues,          // Changed to plural to indicate an array
        index
    ) => {
        //console.log(itemGroupConditionOid, formOid, conditionalCodeLists, operands, codedValues, index);

        const existingFormIndex = formDefs.findIndex(form => form.OID === formOid);

        if (existingFormIndex !== -1) {
            const existingFormGroup = { ...formDefs[existingFormIndex] };

            const conditionItemGroupData = existingFormGroup.ItemGroupRef.find(
                fi => fi.ItemGroupOID === itemGroupConditionOid
            );

            if (conditionItemGroupData?.CollectionExceptionConditionOID) {
                const existingConditionDefIndex = conditionalDef.findIndex(
                    condition => condition.OID === conditionItemGroupData.CollectionExceptionConditionOID
                );

                if (existingConditionDefIndex !== -1) {
                    const updatedConditionDef = { ...conditionalDef[existingConditionDefIndex] };

                    // Split existing conditions
                    const conditions = updatedConditionDef.FormalExpression.text
                        .split(/\|\|/)
                        .map(c => c.trim());

                    //console.log(conditions);

                    // Ensure values are not empty
                    const safeConditionalCodeList = conditionalCodeLists[index] || ''; // Get the value at the index
                    const safeOperand = operands[index] || ''; // Get the value at the index
                    const safeCodedVale = codedValues[index] || ''; // Get the value at the index

                    // Update the specific condition at the given index
                    if (index < conditions.length) {
                        conditions[index] = `!(${safeConditionalCodeList} ${safeOperand} "${safeCodedVale}")`;
                    } else {
                        // Handle the case where the index might be out of bounds (optional)
                        console.warn(`Index ${index} is out of bounds for conditions array.`);
                    }

                    //console.log(conditions[index], safeConditionalCodeList, safeOperand, safeCodedVale);

                    // Rebuild the FormalExpression
                    updatedConditionDef.FormalExpression = {
                        Context: "OpenEDC",
                        text: conditions.join(' || ') // Join conditions back into a single string
                    };

                    // Update the conditionalDef array
                    const updatedConditionalDefArray = [
                        ...conditionalDef.slice(0, existingConditionDefIndex),
                        updatedConditionDef,
                        ...conditionalDef.slice(existingConditionDefIndex + 1)
                    ];

                    setConditionalDef(updatedConditionalDefArray);
                    //console.log(conditionalDef);
                }
            }
        }
    };

    // for creating ConditionalDefFormalExpression
    const handleConditionalItemChange = (itemGroupOID: string, e: React.ChangeEvent<{ value: unknown }>, index: number) => {
        const questionItemDef = itemDefs?.filter(q => q.OID === e.target.value);
        let newDataType, updatedCodeLists;

        questionItemDef.map(itemData => {
            newDataType = itemData.DataType;
            updatedCodeLists = codeLists.filter(cl => cl.OID === itemData.CodeListRef?.CodeListOID);
        });

        // setConditional(newConditional); // Update the selected question (conditional)
        setDataType(newDataType);
        const newConditionals = [...conditionals];
        newConditionals[index] = e.target.value as string;
        setConditionals(newConditionals);
        if (updatedCodeLists.length > 0) {
            //////console.log('Updated Code List:', updatedCodeLists);
            setConditionalCodeList(updatedCodeLists);
        }

        // if (conditional[index] !== null && operand[index] !== null && codedVale[index] !== null) {
        createConditionalDefFormalExpression(itemGroupOID, formDef.OID, newConditionals, operands, codedValues, index);

        // }
    };

    const handleOperandChange = (e: React.ChangeEvent<{ value: unknown }>, itemGroupOID: string, index: number) => {
        const newOperands = [...operands];
        newOperands[index] = e.target.value as string;
        setOperands(newOperands);
        if (conditional[index] !== null && operand[index] !== null && codedVale[index] !== null) {
            createConditionalDefFormalExpression(itemGroupOID, formDef.OID, conditionals, newOperands, codedValues, index);

        }
    };

    const handleItemValue = (e: React.ChangeEvent<{ value: unknown }>, itemGroupOID: string, index: number) => {
        const newCodedValues = [...codedValues];
        newCodedValues[index] = e.target.value as string;
        setCodedValues(newCodedValues);
        if (conditional[index] !== null && operand[index] !== null && codedVale[index] !== null) {
            createConditionalDefFormalExpression(itemGroupOID, formDef.OID, conditionals, operands, newCodedValues, index);

        }
    };

    // const addNewCondition = () => {
    //     // Implement your logic to add the new condition
    //     const newCondition = {
    //         itemGroupOID,
    //         questionOID: conditional,
    //         operand,
    //         codedVale
    //     };

    //     // Logic to find existing condition and update its formal expression
    //     const updatedConditionalDefArray = conditionalDef.map(cond => {
    //         if (cond.OID === newCondition.itemGroupOID) {
    //             const newFormalExpression = `!(${conditionalCodeList} ${operand} "${codedVale}")`;
    //             return {
    //                 ...cond,
    //                 FormalExpression: {
    //                     Context: "OpenEDC",
    //                     text: cond.FormalExpression.text ? `${cond.FormalExpression.text} || ${newFormalExpression}` : newFormalExpression
    //                 }
    //             };
    //         }
    //         return cond;
    //     });

    //     setConditionalDef(updatedConditionalDefArray);
    // };

    // Function to delete a condition
    const handleDeleteCondition = (conditionOID: string, itemGroupOid: string) => {
        // Find the index of the condition to delete
        //console.log(conditionOID, itemGroupOid)
        const index = conditionalDef.findIndex(cd => cd.OID === conditionOID);
        if (index === -1) {
            return; // Condition not found, handle accordingly
        }
        //console.log(index)

        // Remove condition from conditionalDef state
        const updatedConditionalDef = [...conditionalDef];
        const deletedConditionalDef = updatedConditionalDef.splice(index, 1)[0];
        setConditionalDef(updatedConditionalDef);

        // Update itemGroupDefs to set CollectionExceptionConditionOID to null for the specified ItemGroupDef
        const updatedFormDefs = formDefs.map((form) => {
            if (form.OID === formDef.OID) {
                return {
                    ...form,
                    ItemGroupRef: form.ItemGroupRef.map((itemRef) => {
                        if (itemRef.CollectionExceptionConditionOID === deletedConditionalDef.OID) {

                            // Use destructuring to create a new object without the CollectionExceptionConditionOID property
                            const { CollectionExceptionConditionOID, ...rest } = itemRef;
                            return rest;
                        }
                        else if (itemRef.CollectionExceptionConditionOID === conditionOID) {
                            const { CollectionExceptionConditionOID, ...rest } = itemRef;
                            return rest;
                        }
                        return itemRef;
                    }),
                };
            }
            return form;
        });
        // Update state with the modified itemGroupDefs
        setFormDef(updatedFormDefs);
        //console.log(itemGroupDefs)
    };

    // Filter out items with OID === questionOID
    const filterAndRemoveItemDefs = (itemDefs: ItemDef[]) => {
        const seenOIDs = new Set<string>();

        // Filter by removing duplicate OIDs
        const filteredItemDefs = itemDefs.filter(item => {
            // If OID has not been seen yet, add it to the set and include it in the filtered result
            if (!seenOIDs.has(item.OID)) {
                seenOIDs.add(item.OID);
                return true;
            }
            // If OID is already in the set, skip this item
            return false;
        });

        // Remove the item with the specific questionOID from the filteredItemDefs
        // const finalFilteredItemDefs = filteredItemDefs.filter(item => item.OID !== questionOID);

        // //console.log(finalFilteredItemDefs);
        return filteredItemDefs;
    };

    // Example usage
    const filteredItemDefs = filterAndRemoveItemDefs(itemDefs);
    return (

        <>
            {showAlert && (
                <Alert className="mt-2" severity="warning" onClose={() => setShowAlert(false)}>
                    {alertMessage}
                </Alert>
            )}
            <Card className="questionBuilderCard itemGroupConditionalBorder">
                <Grid container alignItems="center">
                    <Grid item xs={11}>
                        {/* Conditional Rendering */}
                        Item Group Conditional Rendering
                    </Grid>
                    <Grid item xs={1} mt={0} display="flex" justifyContent="flex-end">

                        {role !== 'admin' && (<IconButton
                            aria-label="delete"
                            onClick={() => handleDeleteCondition(itemRef.CollectionExceptionConditionOID, itemGroupOID)}
                        >
                            <DeleteOutlineIcon />
                        </IconButton>
                        )}
                    </Grid>
                </Grid>
                {/* <Typography>
                    {conditionalDef.filter((re: any) => re.OID).map((re: any) => re.FormalExpression.text).join(", ")}
                </Typography>           */}
                <>
                    {conditionalDef
                        .filter(res => res.OID === itemRef.CollectionExceptionConditionOID) // Filter based on the condition
                        .map(res => {
                            const FormalExpression = res.FormalExpression.text || '';
                            //console.log(FormalExpression, res)
                            const conditions = FormalExpression.split(/\|\|/).map(condition => condition.trim());

                            return conditions.map((condition, index) => {
                                //console.log(condition)
                                const conditionMatch = condition.match(/(I\.[\d]+)\s*(==|!=|>|<)\s*["']?([\d]*)["']?|(["']?)$/);
                                if (!conditionMatch) return null; // Skip if no match

                                const [, conditional = '', operand = '', codedValue = ''] = conditionMatch;

                                // Retrieve the current values from state or fallback to the extracted values
                                const currentConditional = conditionals[index] || conditional;
                                const currentOperand = operands[index] || operand;
                                const currentCodedValue = codedValues[index] !== undefined ? codedValues[index] : codedValue;
                                //console.log(codedValues)
                                return (
                                    <Grid container spacing={2} key={index}>
                                        {/* Question Select */}
                                        <Grid item xs={12} md={5} mt={1}>
                                            <FormControl fullWidth>
                                                <InputLabel id={`demo-select-small-label-${itemRef.CollectionExceptionConditionOID}`}>Question</InputLabel>
                                                <Select
                                                    labelId={`demo-select-small-label-${itemRef.CollectionExceptionConditionOID}`}
                                                    id={`demo-select-small-${itemRef.CollectionExceptionConditionOID}`}
                                                    value={currentConditional || undefined}
                                                    size="small"
                                                    label="Question"
                                                    onChange={(e: any) => handleConditionalItemChange(itemGroupOID, e, index)}  // Pass index here
                                                    fullWidth
                                                    readOnly={role==='admin'}

                                                >
                                                    {filteredItemDefs.map(option => (
                                                        <MenuItem key={option.OID} value={option.OID}>
                                                            {option.Question?.TranslatedText?.text}
                                                        </MenuItem>
                                                    ))}
                                                    <MenuItem value={undefined}>None</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>

                                        {/* Operand Select */}
                                        <Grid item xs={12} md={2} mt={1}>
                                            <FormControl fullWidth>
                                                <InputLabel id={`operand-select-label-${itemRef.CollectionExceptionConditionOID}`}>Operand</InputLabel>
                                                <Select
                                                    labelId={`operand-select-label-${itemRef.CollectionExceptionConditionOID}`}
                                                    id={`operand-select-${itemRef.CollectionExceptionConditionOID}`}
                                                    value={currentOperand || undefined}
                                                    label="Operand"
                                                    onChange={(e: any) => handleOperandChange(e, itemGroupOID, index)}
                                                    fullWidth
                                                    size='small'
                                                    readOnly={role==='admin'}

                                                >
                                                    {operandOptions.map(option => (
                                                        <MenuItem key={option.value} value={option.value}>
                                                            {option.label}
                                                        </MenuItem>
                                                    ))}
                                                    <MenuItem value={undefined}>None</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>

                                        {/* Coded Value Input */}
                                        <Grid item xs={12} md={5} mt={1}>
                                            {(() => {
                                                const selectedItem = itemDefs.find(item => item.OID === currentConditional);
                                                const currentCodedValueSafe = currentCodedValue !== undefined ? currentCodedValue : ''; // Handle undefined
                                                //console.log(currentCodedValueSafe, "currentCodedValueSafe")
                                                if (!selectedItem) return null;

                                                const { DataType } = selectedItem;

                                                switch (DataType) {
                                                    case 'boolean':
                                                        return (
                                                            <FormControl fullWidth>
                                                                <InputLabel id={`value-select-label-${itemRef.CollectionExceptionConditionOID}`}>Value</InputLabel>
                                                                <Select
                                                                    labelId={`value-select-label-${itemRef.CollectionExceptionConditionOID}`}
                                                                    id={`value-select-${itemRef.CollectionExceptionConditionOID}`}
                                                                    value={currentCodedValueSafe}
                                                                    label="Value"
                                                                    onChange={(event: any) => handleItemValue(event, itemGroupOID, index)}
                                                                    fullWidth
                                                                    size="small"
                                                                    readOnly={role==='admin'}

                                                                >
                                                                    <MenuItem value="Yes">Yes</MenuItem>
                                                                    <MenuItem value="No">No</MenuItem>
                                                                    <MenuItem value={undefined}>None</MenuItem>
                                                                </Select>
                                                            </FormControl>
                                                        );

                                                    case 'text':
                                                    case 'multilinetext':
                                                        return (
                                                            <TextField
                                                                label="Text Field"
                                                                fullWidth
                                                                size="small"
                                                                value={currentCodedValueSafe}
                                                                onChange={(event) => handleItemValue(event, itemGroupOID, index)}
                                                            />
                                                        );

                                                    case 'integer':
                                                        return (
                                                            <TextField
                                                                id="integer-input"
                                                                label="Integer Input"
                                                                fullWidth
                                                                size="small"
                                                                value={currentCodedValueSafe}
                                                                onChange={(event) => handleItemValue(event, itemGroupOID, index)}
                                                                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                                            />
                                                        );

                                                    case 'datetime':
                                                        return (
                                                            <TextField
                                                                id="datetime-input"
                                                                label="Date Time"
                                                                fullWidth
                                                                size="small"
                                                                type="datetime-local"
                                                                value={currentCodedValueSafe}
                                                                onChange={(event) => handleItemValue(event, itemGroupOID, index)}
                                                                InputLabelProps={{ shrink: true }}
                                                            />
                                                        );

                                                    case 'date':
                                                        return (
                                                            <TextField
                                                                id="date-input"
                                                                label="Date"
                                                                fullWidth
                                                                size="small"
                                                                type="date"
                                                                value={currentCodedValueSafe}
                                                                onChange={(event) => handleItemValue(event, itemGroupOID, index)}
                                                                InputLabelProps={{ shrink: true }}
                                                                
                                                            />
                                                        );

                                                    case 'multiSelectComboBox':
                                                    case 'multiselect':
                                                        return (
                                                            <FormControl fullWidth size="small">
                                                                <InputLabel id={`multi-select-label-${itemGroupOID}`}>Value</InputLabel>
                                                                <Select
                                                                    labelId={`multi-select-label-${itemGroupOID}`}
                                                                    id={`operand-select-${itemGroupOID}`}
                                                                    multiple
                                                                    label="Value"
                                                                    value={currentCodedValueSafe || []} // Use an empty array if currentCodedValue is undefined
                                                                    onChange={(e: any) => handleItemValue(e, itemGroupOID, index)} // TypeScript: no need for 'any' if types are defined
                                                                    readOnly={role==='admin'}

                                                                >
                                                                    {conditionalCodeList.flatMap((option) =>
                                                                        option.CodeListItem.map((codeListItem) => (
                                                                            <MenuItem key={codeListItem.CodedValue} value={codeListItem.CodedValue}>
                                                                                <Checkbox checked={codedVale.indexOf(codeListItem.CodedValue) > -1} />
                                                                                <ListItemText primary={codeListItem.Decode.TranslatedText.text} />
                                                                            </MenuItem>
                                                                        ))
                                                                    )}
                                                                    <MenuItem value={undefined}>None</MenuItem>

                                                                </Select>
                                                            </FormControl>
                                                        );

                                                    default:
                                                        return (
                                                            <FormControl fullWidth>
                                                                <InputLabel id={`value-select-label-${itemRef.CollectionExceptionConditionOID}`}>Value</InputLabel>
                                                                <Select
                                                                    labelId={`value-select-label-${itemRef.CollectionExceptionConditionOID}`}
                                                                    id={`value-select-${itemRef.CollectionExceptionConditionOID}`}
                                                                    value={currentCodedValueSafe}
                                                                    label="Value"
                                                                    onChange={(e: any) => handleItemValue(e, itemGroupOID, index)}
                                                                    fullWidth
                                                                    size="small"
                                                                    readOnly={role==='admin'}
                                                                >
                                                                    {conditionalCodeList.flatMap(option =>
                                                                        option.CodeListItem.map(codeListItem => (
                                                                            <MenuItem key={codeListItem.CodedValue} value={codeListItem.CodedValue}>
                                                                                {codeListItem.Decode.TranslatedText.text}
                                                                            </MenuItem>
                                                                        ))
                                                                    )}
                                                                    <MenuItem value={undefined}>None</MenuItem>
                                                                </Select>
                                                            </FormControl>
                                                        );
                                                }
                                            })()}
                                        </Grid>
                                    </Grid>
                                );
                            });
                        })}




                    {role !== 'admin' && (<Grid item xs={12}>
                        <Button onClick={() => handleAddCondition(itemRef.CollectionExceptionConditionOID)}>Add condition</Button>
                    </Grid>
                    )}
                </>



            </Card>


        </>
    );
};

export default ItemGroupConditionDef;
