import React, { useEffect, useState } from 'react';
import { Box, Container, CssBaseline, Typography, CircularProgress } from '@mui/material';
import { useOidcAccessToken } from '@axa-fr/react-oidc';
import { getResourceByUid } from '../../../services/ResourceService';
import { getAllHolidays } from '../../../services/Holiday';

interface GanttChartComponentProps {
  onGanttDataUpdate: (data: any) => void;
  selected: any;
}

const GanttChartComponent: React.FC<GanttChartComponentProps> = ({ onGanttDataUpdate, selected }) => {
  console.log('selected', selected);
  const [ganttData, setGanttData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const { accessToken } = useOidcAccessToken();
  const [holidays, setHolidays] = useState<any[]>([]);
  // const [taskData, setTaskData] = useState<any[]>([]);


  useEffect(() => {
    const fetchAssignees = async (task: any) => {
      if (task.assignees && task.assignees.length > 0) {
        const assigneeNames = await Promise.all(
          task.assignees.map(async (resourceId: any) => {
            try {
              const resource = await getResourceByUid(resourceId, accessToken);
              return resource?.data?.name || '';
            } catch (err) {
              console.error(`Error fetching resource for ID ${resourceId}:`, err);
              return 'Unknown';
            }
          })
        );
        return assigneeNames.join(', ');
      }
      const res = await getAllHolidays(accessToken);
      setHolidays(res.data);
      const dates = holidays.map(holiday => holiday.date);
      console.log('holidays',dates);
     
      // console.log('day', res.data.date);
      return '';
    };

    function ExcludeHolidays(start, end) {
      const excludedDates = holidays;
      const weekendDays = [0, 6];
  
      let currentDate = new Date(start);
      const endDate = new Date(end);
  
      while (currentDate <= endDate) {
          const day = currentDate.getDay();
  
          if (weekendDays.includes(day) || excludedDates.includes(currentDate.toISOString().split('T')[0])) {
              return true; 
          }
  
          currentDate.setDate(currentDate.getDate() + 1);
      }
      return false;
  }

  
    // const formatDependencies = (dependencies: any) => {
    //   if (!Array.isArray(dependencies)) {
    //     dependencies = dependencies ? [dependencies] : [];
    //   }

    //   const formattedDeps = dependencies
    //     .map((dep: string) => {
    //       if (!dep.includes('FS') && !dep.includes('SS') && !dep.includes('SF')) {
    //         return `${dep}FS`;
    //       }
    //       return dep;
    //     })
    //     .join(',');
    //   return formattedDeps;
    // };

    
  // const processTasks = async () => {
  
  // setIsLoading(true);
  // if (selected && selected.length > 0) {
  //   const transformedTasks: any[] = [];
  //   let currentMainTask: any = null;
  //   let index = 1;

  //   for (const task of selected) {
  //     const assigneeNames = await fetchAssignees(task);
  //     // const formattedDependencies = task.dependencies
  //     //   ? formatDependencies(task.dependencies)
  //     //   : '';

  //         if (!task.taskType || task.taskType === '') {
  //           currentMainTask = {
  //             id: index,
  //             TaskId: task.taskId,
  //             Task: task.taskName,
  //             Start: task.startTime,
  //             End: task.endTime,
  //             Dependency: task.dependencies,
  //             Duration: task.estimatedHours,
  //             taskType: task.taskType,
  //             PrimaryTask: `Task ${index}: ${task.taskName}`,
  //             Resource: assigneeNames,
  //           };
  //           index++;
  //         } else {           
  //           var x = task.taskId - 1;
  //           //console.log("x", x);
  //           let previousTask = selected[task.taskId - 2];
  //           //console.log("previousTask", previousTask);
  //           let startTime: any;
  //           let endTime: any;
  //           let endTimeString :any;
  //           if (task.startTime === null) {          
  //             startTime = previousTask.endTime;
  //           } else {
  //             //console.log("task.startTime1", task.startTime);
  //             startTime = task.startTime;
  //           }
  //           if (task.endTime === null) {
  //             //console.log("task.endTime", task.endTime);
  //             // endTime = previousTask.endTime;
  //             endTime = new Date(previousTask.endTime);  // Create a new Date object from the previous endTime
  //             //console.log("task.endTime", endTime, task.taskId);
  //             endTime.setMinutes(endTime.getMinutes() + 15);
  //             //console.log("task.endTime", endTime);
  //             endTimeString = endTime.toLocaleString();
  //           } else {
  //             //console.log("task.endTime1", task.endTime);
  //             endTimeString = task.endTime;
  //           }

  //           transformedTasks.push({
  //             id: index++,
  //             TaskId: task.taskId,
  //             Task: task.taskName,
  //             Start: startTime,
  //             End: endTimeString,
  //             Dependency: task.dependencies,
  //             Duration: task.estimatedHours,
  //             taskType: task.taskType,
  //             PrimaryTask: currentMainTask ? `Task ${currentMainTask.id}: ${currentMainTask.Task}` : '',
  //             Resource: assigneeNames,
  //           });
  //         }
  //       }

  //       setGanttData(transformedTasks);
  //       console.log('transformed data', transformedTasks);
  //       // onGanttDataUpdate(transformedTasks);
  //     }
  //     setIsLoading(false);
  //   };


  const processTasks = async () => {
    setIsLoading(true);
    if (selected && selected.length > 0) {
      const transformedTasks: any[] = [];
      let currentMainTask: any = null;
      
      let Phaseindex = 0; 
    
      for (let taskIndex = 0; taskIndex < selected.length; taskIndex++) {
        const task = selected[taskIndex];
        const assigneeNames = await fetchAssignees(task);
    
        if (!task.taskType || task.taskType === '') {
          currentMainTask = {
            id: task.taskId,
            PrimaryTaskId: Phaseindex,
            Task: task.taskName,
            Start: task.startTime,
            End: task.endTime,
            Dependency: task.dependencies,
            Duration: task.estimatedHours,
            taskType: task.taskType,
            PrimaryTask: `Phase ${Phaseindex}: ${task.taskName}`,
            Resource: assigneeNames,
          };
          Phaseindex++;
        } else {
          const previousTask = selected[taskIndex - 1];
          if (!previousTask) {
            console.error(`Previous task not found for task with ID: ${task.taskId}`);
            continue;
          }
  
          let startTime = task.startTime;
          let endTime;
  
          if (startTime === null) {
            startTime = previousTask.endTime;
          }
  
          if (task.endTime === null) {
            if (previousTask.endTime) {
              endTime = new Date(previousTask.endTime);
              endTime.setMinutes(endTime.getMinutes() + 1);
            } else {
              console.error(`End time for previous task is invalid for task with ID: ${task.taskId}`);
              continue;
            }
          } else {
            endTime = new Date(task.endTime);
          }
  
          const endTimeString = endTime.toISOString();
  
          transformedTasks.push({
            id: task.taskId,
            PrimaryTaskId: Phaseindex,
            Task: task.taskName,
            Start: startTime ? new Date(startTime).toISOString() : null,
            End: endTimeString,
            Dependency: task.dependencies,
            Duration: task.estimatedHours,
            taskType: task.taskType,
            PrimaryTask: currentMainTask ? `Phase ${Phaseindex}: ${currentMainTask.Task}` : '',
            Resource: assigneeNames,
          });
        }
      }
  
      setGanttData(transformedTasks);
      console.log('Transformed data:', transformedTasks);
    }
    setIsLoading(false);
  };
  

 
    processTasks();
  }, [selected]);

  useEffect(() => {
    if (ganttData.length > 0 && window.StartTreeGrid) {
      window.StartTreeGrid();
      window.Grids.OnCustomAjax = (G: any, IO: any, data: any, func: any) => {
        MyAjax(IO, data);
      };
    } else {
      console.log('Gantt chart data is empty or StartTreeGrid is not defined');
    }
  }, [ganttData]);

  const MyAjax = (Url: any, data: string) => {
    try {
      console.log('MyAjax received data:', data);
      const parsedData = JSON.parse(data);
      console.log('Parsed data:', parsedData);
      setGanttData(parsedData);
      onGanttDataUpdate(parsedData);
    } catch (error) {
      console.error('Failed to parse data in MyAjax:', error);
    }
  };



  return (
    <React.Fragment>
      <CssBaseline />
      {/* <Container sx={{ paddingLeft: 0, paddingRight: 0, width:"100vh" }}> */}
        <Box component="section" sx={{ p: 2 }}>
          {isLoading ? (
            <Box display="flex" justifyContent="center" alignItems="center" height="400px">
              <CircularProgress />
              <Typography marginLeft={2}>Loading Gantt Chart...</Typography>
            </Box>
          ) : (
            <div className="ExampleBorder">
              <div className="ExampleMain" style={{ width: '100%' }} id="TreeGridMainTag">
                <bdo
                  debug=""
                  layout_url="Layouts/GanttTreeDef.js"
                  data_data={JSON.stringify({ Body: [ganttData] })}
                  
                  // upload_url="http://localhost:8000/set?table=GanttTree&idcol=id"
                  upload_url={`http://localhost:8080/gms-provider/api/v2/tasks/projectByTask,${accessToken}`}
                  style={{ width: '100%', height: '100%',}}
                />
                
              </div>
            </div>
          )}
        </Box>
      {/* </Container> */}
    </React.Fragment>
  );
};

export default GanttChartComponent;
