import { TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { ItemData, ItemDef } from "../../../interface/SdmInterfaces";
import { Stack } from "@mui/system";
import { set } from "date-fns";

interface Props {
    itemDef: ItemDef;
    onItemchange: React.Dispatch<React.SetStateAction<any>>;
}

const IntegerText: React.FC<any> = ({ itemDef, measureDef, onItemchange, itemDatas, error }) => {

    // console.log(error);

    const [itemData, setItemData] = useState<ItemData>((itemDef?.OID === itemDatas[0]?.ItemOID) ? itemDatas[0] : []);
    const [errors, setErrors] = useState<any>(false);

    const inputchange = (e: any) => {

        if (itemDatas.length > 0 && itemDatas[0] !== undefined) {
            setItemData({ ...itemDatas[0], Value: e.target.value });
            onItemchange({ ...itemDatas[0], Value: e.target.value });
        } else {
            let tempItemData: ItemData = { ItemOID: itemDef.OID, Value: e.target.value, CodeList: [], MetaKey: itemDef.MetaKey };
            setItemData(tempItemData);
            onItemchange(tempItemData);
        }
    }

    // useEffect(() => {
    //     if (mandatory === "Yes") {
    //         if (itemData?.Value === "") {
    //             setErrors(true);
    //         } else {
    //             setErrors(false);
    //         }
    //     }
    // })

    return (
        <>
            <Stack direction="row" spacing={2}>
                <TextField
                    error={(error?.OID === itemDef.OID) ? true : false}
                    id="demo-helper-text-aligned"
                    label=""
                    name="name"
                    size="small"
                    sx={{ m: 1 }}
                    type="number"
                    fullWidth
                    value={itemData?.Value}
                    inputProps={{ step: "1", min: 0 }}
                    helperText={(error?.OID === itemDef.OID) ? 'Please fill this field' : ''}
                    onChange={inputchange}
                />
                <Typography sx={{ textAlign: 'center' }}>{measureDef?.Symbol?.TranslatedText?.text}</Typography>
            </Stack>
        </>
    )
}

export default IntegerText;