import React, { useState, ReactNode, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import { useOidcAccessToken } from '@axa-fr/react-oidc';
import { Box, Button, Card, Dialog, DialogActions, DialogContent, Grid, IconButton, Stack, TextField, Typography } from '@mui/material';
import { UserContext } from '../../hooks/UserSession';
import { getTenantDetails, getVersionDetails } from '../../services/TenantService';
import './ErrorBoundary.scss'
import AdbIcon from '@mui/icons-material/Adb';
import { addJiraContent } from '../../services/UserService';
interface ErrorBoundaryProps {
  children: ReactNode;
  componentName?: string;  // Optional: Name of the component where the error boundary is used
}

interface SystemInfo {
  os: string;
  browser: string;
  date: string;
  memory: string | null;
  url: string;
  component: string;
}

const getSystemInfo = (componentName: string): SystemInfo => {
  const os = window.navigator.userAgent; // OS/Browser information
  const browser = window.navigator.appVersion; // Browser version
  const date = new Date().toLocaleString(); // Get date and time
  const memory = (performance as any).memory
    ? `${(performance as any).memory.usedJSHeapSize / 1048576} MB`
    : 'Not available'; // Get memory usage (only works in certain browsers)
  const url = window.location.href; // Get the current URL

  return { os, browser, date, memory, url, component: componentName };
};

const ErrorBoundary: React.FC<ErrorBoundaryProps> = ({ children, componentName = 'Unknown Component' }) => {
  const [hasError, setHasError] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [systemInfo, setSystemInfo] = useState<SystemInfo | null>(null);
  const navigate = useNavigate();
  const [tenantDetails, setTenantDetails] = useState<any>();
  const { accessToken } = useOidcAccessToken();
  const [dialogOpen, setDialogOpen] = useState(false); // Should be a boolean
  const [comments, setComments] = useState(''); // Should be a boolean
  const [versionDetails, setVeresionDetails] = useState<any>();


  const getTenantConfig = async () => {
    const response = await getTenantDetails(accessToken);
    if (response.data === undefined) {
      navigate("/tenantconfiguration");
    }
    let details = response.data;
    setTenantDetails(details);
  };
  const getVersionDetail = async () => {
    const response = await getVersionDetails(accessToken);
    setVeresionDetails(response.data);
    //console.log(response);
}
  useEffect(() => {
    getTenantConfig();
    const handleError = (event: ErrorEvent) => {
      setHasError(true);
      setError(event.error);

      const systemDetails = getSystemInfo(componentName);
      setSystemInfo(systemDetails);
      setDialogOpen(true); // Open the dialog on error
      console.error('Global error caught:', event.error);
      console.log('System Info:', systemDetails);
    };

    window.addEventListener('error', handleError);

    return () => {
      window.removeEventListener('error', handleError);
    };
  }, [componentName]);

  const handleClose = async () => {
    let jiraContent = {}; // Initialize the variable to store jira content
    // Update jiraContent if the issue is 'Add Question'
    // Update jiraContent if the issue is 'Add Question'
    const response = await getVersionDetails(accessToken);
 
    jiraContent = {
      questions: comments,
      type: 'BugReport',
      errorMessage: error?.message,
      date: systemInfo?.date,
      os: systemInfo?.os,
      browserVersion: systemInfo?.browser,
      memoryUsage: systemInfo?.memory,
      pageUrl: systemInfo?.url,
      tenant: tenantDetails?.name,
      stackTrace:error?.stack,
      aseVersion:response.data?.aseVersion,
      gmsCloud:'0.4.0',
      tenantApiVersion:response.data?.tenantApiVersion,
      tenantBuildDate:response.data?.tenantApiBuildDate,
      aseBuildDate:response.data?.aseBuildDate
 
    };
    console.log(jiraContent)
    addJiraContent(jiraContent, accessToken)
      .then((res) => {
        // if(res.ok)
        console.log(res);

      })
      .catch((error) => {
        console.error("Error adding Jira content:", error);  // Optional: handle errors
      });

    setDialogOpen(false);
    // navigate("/commondashboard");
  window.location.reload();
  };

  const handleInputChange = (e: any) => {
    console.log(e?.target.value);
    setComments(e?.target.value);


  }
  if (hasError && error && systemInfo) {
    return (
      <Dialog
        fullWidth={true}
        maxWidth={'md'}
        open={dialogOpen}

      >
        <Grid m={3} position="relative">
          <>
            {/* Arch-shaped decoration in the top-left corner */}
            <Box
            // sx={{
            //   position: 'absolute',
            //   top: '0px',
            //   left: '0px',
            //   width: '60px',
            //   height: '60px',
            //   backgroundColor: 'orange',
            //   borderTopLeftRadius: '50px',
            //   borderTopRightRadius: '0',
            //   borderBottomLeftRadius: '0',
            //   borderBottomRightRadius: '50px',
            // }}
            />

            <Card className='m-2 p-3 questionBuilderCard'>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                textAlign="center"
              >
                <Typography variant="h6" className='reportBug'>
                  Sorry You Have Encountered An Issue
                </Typography>

                <Stack spacing={2} mt={2} className='reportDescription'>
                  <Typography variant="body1">
                    We sincerely apologize for any inconvenience caused. Our team is committed to resolving any issues you may encounter as quickly as possible.
                    Please provide us with the details of the issue, and we will work on a fix immediately.
                  </Typography>
                  <Typography variant="body1">
                    Your feedback is incredibly valuable in helping us improve our system. Thank you for your patience and for reporting this issue.
                  </Typography>
                </Stack>
                {/* <Grid item xs={12} sm={12} md={12} mt={2}> */}

                <TextField
                  fullWidth
                  size='small'
                  label={`Comments`}
                  multiline
                  rows={2}
                  // // value={value}
                  onChange={(e: any) => handleInputChange(e)}
                  margin="none"
                  variant="outlined"
                  sx={{ fontSize: '6rem', marginTop: '10px' }}
                />
                {/* </Grid> */}
                {/* Make the icon button larger */}
                <IconButton className='reportBug bugIcon' sx={{ fontSize: '6rem', marginTop: '10px' }}>
                  <AdbIcon fontSize="inherit" />
                </IconButton>


                {/* Add a 'Report this bug' button at the bottom */}
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ marginTop: '20px' }}
                  onClick={handleClose}
                >
                  Report this bug
                </Button>
              </Box>
            </Card>
          </>
        </Grid>
      </Dialog>


    );
  }

  return <>{children}</>;
};

export default ErrorBoundary;
