import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { Checkbox, FormControl, FormControlLabel, Grid, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, TextField } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { useNavigate } from 'react-router-dom';
import { getAllVendors } from '../../services/VendorService';
import { useOidcAccessToken } from '@axa-fr/react-oidc';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { addMaterial } from '../../services/materialService';
import CustomDialog from '../../pages/Dialog';
import VendorRegister from '../vendor/VendorRegister';
import { validQuestionTrade } from '../../services/TradeConfigurationService';

const units = [
    'SQ', 'Bundle-100 LF', 'Bundle-33 LF', 'RL', 'EA', '1.85inches - 10', 'BD', '5LB', 'QTY', 'BX', 'TB', 'LF', 'Hour'
];

const gradeList = [
    'a', 'b', 'c'
]
const manufacturers = [
    'Owens Corning', 'GAF', 'Certain Teed', 'Norandex', 'James Hardie', 'Smart Side', 'ACM', 'Berger', ' US Aluminum Inc',
    'Cross Country','Meridian','BC Greenhouse'
]


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 5;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 2.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
const AddMaterialForm: React.FC<any> = (props) => {
    const [materialData, setMaterialData] = React.useState<any>({
        vendorId: '',
        companyName: '',
        name: "",
        itemCode: "",
        tags: '',
        manufacturer: '',
        unit: "",
        grade: '',
        versionStartdate: "",
        cost: "",
        inStock: true
    });
    const filter = createFilterOptions<any>();
    const [vendorList, setVendorList] = React.useState<any>([]);
    const { accessToken, accessTokenPayload } = useOidcAccessToken();
    const [tradeName, setTradeName] = React.useState<string[]>([]);
    const [count, setCount] = React.useState(Number);
    const [openSaveDialog, setOpenSaveDialog] = React.useState(false);
    const [saveSuccess, setSaveSuccess] = React.useState(false);
    const [saveError, setSaveError] = React.useState(false);
    const [openVendorForm, setOpenVendorForm] = React.useState(false);
    const navigate = useNavigate();
    const [tradeList, setTradeList] = React.useState<any[]>([]);

    const handleClose = () => {
        props.onClose();
        setMaterialData({
            vendorId: '',
            companyName: '',
            name: "",
            itemCode: "",
            tags: '',
            manufacturer: '',
            unit: "",
            grade: '',
            versionStartdate: "",
            cost: "",
            inStock: true
        })
        setCount(0);
    };

    const setVendorNameAndId = (e: any, value: any) => {
        setMaterialData({ ...materialData, companyName: value?.companyName, vendorId: value?.id });
    };
    const handleTrades = () => {
        validQuestionTrade(accessToken)
            .then(res => {
                //console.log(res.data)
                setTradeList(res.data)
            })
    
    }
    const openVendorRegForm = () => {
        setOpenVendorForm(true);
    };


    const getVendors = async () => {
        let result = await getAllVendors(accessToken);
        setVendorList(result.data);
    }

    const handleTradeNameChange = (event: any) => {
        const {
            target: { value },
        } = event;
        setTradeName(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const materialInputChange = (e: any) => {
        if (e.target.name === "companyName") {
            setMaterialData({ ...materialData, companyName: (e.target.value)?.companyName, vendorId: (e.target.value)?.id });
        }
        else if (e.target.name === "cost") {
            if (e.target.value > 0) {
                setMaterialData({ ...materialData, cost: e.target.value });
            }
        }
        else {
            setMaterialData({ ...materialData, [e.target.name]: e.target.value });
        }

    };
    const onChangeVersionDate = (e: any) => {
        setMaterialData({ ...materialData, versionStartdate: dayjs(e.$d).format('YYYY-MM-DD') + "T00:00:00.000Z" });
    }

    const addMaterials = async () => {
        console.log(materialData);
        setCount(count + 1);
        materialData['tags'] = tradeName.join(';');
        console.log(materialData);
        let validate = validation(materialData);
        if (validate === true) {
            let result = await addMaterial(accessToken, [materialData]);
            if (result.status === 201) {
                setOpenSaveDialog(true);
                setSaveSuccess(true);
                setTradeName([]);
                setCount(0);
                handleClose();
            }
            else {
                setOpenSaveDialog(true);
                setSaveError(true);
            }
        }
    }

    const validation = (materialData: any) => {
        let validated: any = true;
        if (materialData?.companyName === '' || materialData?.tags === '' || materialData?.name === '' || materialData?.itemCode === ''
            || materialData?.cost === '' || materialData?.vendorId === '' || materialData?.unit === '' || materialData?.versionStartdate === ''
            || materialData?.manufacturer === '' || materialData?.grade === '' || materialData?.leadTime === ''
        ) {
            validated = false;
        }
        return validated;
    }

    React.useEffect(() => {
        handleTrades();
        getVendors();
    }, [])

    return (
        <React.Fragment>
            <Dialog
                aria-labelledby="customized-dialog-title"
                open={props.open}
                fullWidth={true}
                maxWidth={"sm"}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    Add Material
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <FormControl fullWidth size="small" sx={{ minWidth: 150 }}>
                                <Autocomplete
                                    value={materialData?.companyName}
                                    onChange={(e, newValue) => {
                                        if (typeof newValue === 'string') {
                                            setVendorNameAndId(e, newValue);
                                        } else if (newValue && newValue.inputValue) {
                                            openVendorRegForm();
                                        } else {
                                            setVendorNameAndId(e, newValue);
                                        }
                                    }}
                                    // onChange={(e, newValue) => setVendorNameAndId(e, newValue,index)}
                                    filterOptions={(options, params) => {
                                        const filtered = filter(options, params);

                                        const { inputValue } = params;
                                        // Suggest the creation of a new value
                                        const isExisting = options.some((option) => inputValue === option.companyName);
                                        if (inputValue !== '' && !isExisting) {
                                            filtered.push({
                                                inputValue,
                                                companyName: `Add Vendor`,
                                            });
                                        }

                                        return filtered;
                                    }}
                                    selectOnFocus
                                    clearOnBlur
                                    handleHomeEndKeys
                                    id="free-solo-with-text-demo"
                                    options={vendorList}
                                    getOptionLabel={(option) => {
                                        // Value selected with enter, right from the input
                                        if (typeof option === 'string') {
                                            return option;
                                        }
                                        // Add "xxx" option created dynamically
                                        if (option.inputValue) {
                                            return option.inputValue;
                                        }
                                        // Regular option
                                        return option.companyName;
                                    }}
                                    renderOption={(props, option) => <li {...props}>{option.companyName}</li>}

                                    freeSolo
                                    renderInput={(params) => (
                                        <TextField {...params} label="Vendor Name" size='small'
                                            error={(materialData?.companyName === '' && count !== 0)}
                                        />
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl size='small' fullWidth>
                                <InputLabel id="demo-multiple-checkbox-label" error={tradeName.length === 0 && count !== 0}>Trade</InputLabel>
                                <Select
                                    labelId="demo-multiple-checkbox-label"
                                    id="demo-multiple-checkbox"
                                    multiple
                                    error={tradeName.length === 0 && count !== 0}
                                    value={tradeName}
                                    onChange={handleTradeNameChange}
                                    input={<OutlinedInput label="Trade" />}
                                    renderValue={(selected) => selected.join(', ')}                                   
                                >
                                    {tradeList.map((name) => (
                                        <MenuItem key={name} value={name}>
                                            <Checkbox checked={tradeName.indexOf(name) > -1} />
                                            <ListItemText primary={name} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth size="small" sx={{ minWidth: 150 }}>
                                <InputLabel id="demo-simple-select-label" error={materialData?.manufacturer === '' && count !== 0}>Manufacturer</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={materialData?.manufacturer}
                                    error={materialData?.manufacturer === '' && count !== 0}
                                    label="manufacturer"
                                    name="manufacturer"
                                    onChange={(e) => materialInputChange(e)}
                                    required
                                    MenuProps ={MenuProps}
                                >
                                    {manufacturers.map((m) => (
                                        <MenuItem value={m}>{m}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <TextField
                                    name="name"
                                    id="outlined-size-small"
                                    size="small"
                                    label="Name"
                                    value={materialData?.name}
                                    error={(materialData?.name === '' && count !== 0)}
                                    onChange={(e) => materialInputChange(e)}
                                />

                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <TextField
                                    name="itemCode"
                                    id="outlined-size-small"
                                    size="small"
                                    label="Item Code"
                                    value={materialData?.itemCode}
                                    error={(materialData?.itemCode === '' && count !== 0)}
                                    onChange={(e) => materialInputChange(e)}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>

                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        // format='DD MMM YYYY'
                                        label="Version Start Date"
                                        slotProps={{ textField: { fullWidth: true, size: "small", error: materialData?.versionStartdate === '' && count !== 0 } }}
                                        // disablePast
                                        name="versionStartdate"
                                        // value={materialData?.versionStartdate === '' ? null : dayjs(materialData?.versionStartdate)}
                                        onChange={(e) => onChangeVersionDate(e)}

                                    // disableHighlightToday   
                                    />
                                </LocalizationProvider>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth size="small">
                                <InputLabel id="demo-simple-select-label" error={materialData?.grade === '' && count !== 0}>Grade</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={materialData?.grade}
                                    error={materialData?.grade === '' && count !== 0}
                                    label="Grade"
                                    name="grade"
                                    onChange={(e) => materialInputChange(e)}
                                    required
                                >
                                    {gradeList.map((grade) => (
                                        <MenuItem value={grade}>{grade.toUpperCase()}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth size="small">
                                <InputLabel id="demo-simple-select-label" error={materialData?.unit === '' && count !== 0}>Unit</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={materialData?.unit}
                                    error={materialData?.unit === '' && count !== 0}
                                    label="Unit"
                                    name="unit"
                                    // disabled={show}
                                    onChange={(e) => materialInputChange(e)}
                                    required
                                    MenuProps={MenuProps}
                                >
                                    {units.map((unit) => (
                                        <MenuItem value={unit}>{unit}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <TextField
                                    name="cost"
                                    id="outlined-size-small"
                                    size="small"
                                    label="Unit Cost"
                                    type="number"
                                    value={materialData?.cost}
                                    error={materialData?.cost === '' && count !== 0}
                                    onChange={(e) => materialInputChange(e)}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={6}>
                            <FormControlLabel
                                value="end"
                                control={
                                    <Checkbox
                                        name="checkbox"
                                        checked={materialData.inStock}
                                        onChange={(e) => {setMaterialData({...materialData, inStock:e.target.checked})}}
                                        size='small'
                                        // disabled={(assetDataObject?.isBilling === true)}
                                    />
                                }
                                label="In Stock"
                                labelPlacement="end"
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <TextField
                                    name="leadTime"
                                    id="outlined-size-small"
                                    size="small"
                                    label="lead Time"
                                    type="number"
                                    disabled={materialData.inStock}
                                    value={materialData?.leadTime}
                                    error={materialData?.leadTime === '' && count !== 0}
                                    onChange={(e) => materialInputChange(e)}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant='contained' className="successButton" onClick={addMaterials}>save</Button>

                    <Button variant='contained' sx={{ backgroundColor: "#df3333ed" }} onClick={handleClose}>Close</Button>

                </DialogActions>
            </Dialog>
            <VendorRegister
                open={openVendorForm}
                onClose={() => setOpenVendorForm(false)}
            />
            <CustomDialog
                open={openSaveDialog}
                onClose={() => setOpenSaveDialog(false)}
                success={saveSuccess}
                error={saveError}
                Content={saveSuccess ? "Saved" : "Not Saved"}
            />
        </React.Fragment>
    );
}
export default AddMaterialForm;