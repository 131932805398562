/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 4-Nov-2024
* @author      :asish
* @since       : 0.0.1
* Requirement# :
* Purpose      : flaoting butto[n for issue reporting
* -----------------------------------------------------------------------------
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem |   DATE         |   AUTHOR          |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
* 1103           |  18-04-2024    |   shiyamkumar     | Bid Chat Cost Issue Fix
* 
*/
import React from 'react';
import { Box, IconButton, Zoom, Tooltip, useTheme } from '@mui/material';
import ChatIcon from '@mui/icons-material/Chat';
import CancelIcon from '@mui/icons-material/Cancel';
import { SxProps } from '@mui/system';
import { useOidcAccessToken } from '@axa-fr/react-oidc';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import HavingIssue from './havingIssue/HavingIssue';

const fabStyle: SxProps = {
  position: 'fixed',
  bottom:76,
  right: 0,
};

const transitionDuration = {
  enter: 225,
  exit: 195,
};

const FloatHavingIssue = () => {
  const theme = useTheme();
  const { accessToken, accessTokenPayload } = useOidcAccessToken();
  const [value, setValue] = React.useState(0);
  const [isChatbotOpen, setIsChatbotOpen] = React.useState(false);
  const [issueOpen, setIssueOpen] = React.useState(false);

  const toggleChatbot = () => {
    setIsChatbotOpen(!isChatbotOpen);
    setIssueOpen(!issueOpen); // Toggle issueOpen when the BugReportIcon is clicked
  };

  const fabs = [
    {
      color: 'primary' as 'primary',
      sx: fabStyle as SxProps,
      icon: <ChatIcon />,
      label: 'Add',
    },
  ];

  return (
    <>
      <Box sx={{ '& > :not(style)': { m: 1 } }}>
        {fabs.map((fab, index) => (
          <Zoom
            key={fab.color}
            in={value === index}
            timeout={transitionDuration}
            style={{
              transitionDelay: `${value === index ? transitionDuration.exit : 0}ms`,
            }}
            unmountOnExit
          >
           <Tooltip title={isChatbotOpen ? " " : "Report the issues"} placement="left" arrow>
  <IconButton
    color={fab.color}
    sx={fab.sx}
    onClick={toggleChatbot}
  >
    <ContactSupportIcon sx={{ color: 'linear-gradient(#7f00ffd4, #3c5bfa)', fontSize: 50 }} />
  </IconButton>
</Tooltip>

           </Zoom>
        ))}
      </Box>

      {issueOpen && (
        <Box sx={{ position: 'fixed', bottom: 200, right:36, zIndex: 10 }}>
          <HavingIssue open={issueOpen} onClose={() => setIssueOpen(false)} />
        </Box>
      )}
    </>
  );
};

export default FloatHavingIssue;
