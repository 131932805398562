import * as React from 'react';
import {
  Box, Card, Button, FormControlLabel, Switch, Typography, TextField, OutlinedInput, InputAdornment, Grid,
  InputLabel, Table, TableHead, TableBody, TableRow, TableCell, FormControl, Select, SelectChangeEvent,
  Modal, Stack, Checkbox, ListItemText, IconButton, MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { useOidcAccessToken } from "@axa-fr/react-oidc";
import "../Tenanatconfiguration.scss";
import CustomDialog from '../../../pages/Dialog';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import { addFinanceData, editFinanceData, getInvoiceIdProviderParts, getTenantDataAll } from '../../../services/FinanceSettingService';
import Invoice from '../../../pages/Invoice';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';


interface Item {
  id: number;
  name: string;
}


const initialItems: Item[] = [
  { id: 1, name: 'Fiserv' },
  // { id: 2, name: 'Stripe' },
  // { id: 3, name: 'Braintree' },
  // { id: 4, name: 'Adyen' },
  // { id: 5, name: 'Amazon pay' },
  // { id: 6, name: 'CCAvenue' },
  // { id: 7, name: 'Payu' },
  // { id: 8, name: 'Instamojo' },
  // { id: 9, name: 'Paytm' },
  // { id: 10, name: 'Paypal' }
];


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const manualpayments = [
  'Cheque',
  'Cash',
];


const digitalpayments = [
  'Google Pay',
  'Apple Pay',
  'ACH',
];

const Android12Switch = styled(Switch)(({ theme }) => ({
  padding: 8,
  '& .MuiSwitch-track': {
    borderRadius: 22 / 2,
    '&::before, &::after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 16,
      height: 16,
    },
    '&::before': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main),
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: 12,
    },
    '&::after': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main),
      )}" d="M19,13H5V11H19V13Z" /></svg>')`,
      right: 12,
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    width: 16,
    height: 16,
    margin: 2,
  },
}));


const Financesettings: React.FC = () => {
  const [loading, setLoading] = React.useState(false);

  const [showContent, setShowContent] = React.useState(false);
  const [selectedOption, setSelectedOption] = React.useState<string>('');
  const { accessToken, accessTokenPayload } = useOidcAccessToken();
  const [count, setCount] = React.useState(0);
  const [show, setShow] = React.useState(false);
  const [tenantDatas, setTenantDatas] = React.useState<any>({})

  const [tenantData, setTenantData] = React.useState<any>(
    {
      retainage: 0,
      paymentGateway:
      {
        incomingUsername: "",
        incomingPassword: "",
        retailMerchantId: "",
        achMerchantId: "",
        incomingAccountNo: "",
        incomingAccountName: "",
        incomingRoutingNo: "",
        outGoingAccountNo: "",
        outGoingAccountName: "",
        outGoingRoutingNo: "",
        outGoingUsername: "",
        outGoingPassword: "",
      },
      payments: [
        {
          name: "",
          value: "",
          description: ""
        }
      ],
      manualPayment: '',
      digitalPayment: '',
      tax: '',
      margin: '',
      overHead: '',
      invoiceNo: '',
      validityPeriod: ''
    }
  );
  const [data, setData] = React.useState<any>([]);
  const [manulPayment, setmanulPayment] = React.useState<string[]>([]);
  const [digitalPayment, setdigitalPayment] = React.useState<string[]>([]);
  const [paymentGateway, setPaymentGatWay] = React.useState<string[]>(["Fiserv"]);
  const [createSuccess, setCreateSuccess] = React.useState(false);
  const [createError, setCreateError] = React.useState(false);
  const [openDialogs, setOpenDialogs] = React.useState(false);
  const [updateSuccess, setUpdateSuccess] = React.useState(false);
  const [updateError, setUpdateError] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [showPaymentTerms, setShowPaymentTerms] = React.useState(false);
  const [percentage, setPercentage] = React.useState<any>(0);
  const [invoiceSettingValidated, setInvoiceSettingValidated] = React.useState<any>(true);
  const [state, setState] = React.useState<any>("new");
  const [noChange, setNoChange] = React.useState(false);
  const [password, setPassword] = React.useState('testing123');
  const handleNoChange = (event?: React.SyntheticEvent | Event, reason?: string) => {

    setNoChange(false)

  }

  const [message, setMessage] = React.useState<any>({
    paymentGateway: '',
    retainage: '',
    payments: '',
    manualPayment: '',
    digitalPayment: '',
    margin: '',
    overHead: '',
    tax: '',
    validityPeriod: '',
    invoiceParts: '',
    outGoingRoutingNo: '',
    outGoingAccountNo: '',
    outGoingAccountName: '',
    outGoingUsername: '',
    incomingRoutingNo: '',
    outGoingPassword: '',
    incomingAccountNo: '',
    incomingPassword: '',
    incomingAccountName: '',
    incomingUsername: '',
    retailMerchantId: "",
    achMerchantId: "",
  });

  const onclose = () => {

    setOpenDialog(false);
    setUpdateSuccess(false);
    setUpdateError(false);

  }

  const handleChange = (event: SelectChangeEvent) => {
    setSelectedOption(event.target.value as string);
    setShowPaymentTerms(true);

  };

  const validation = () => {
    let validated: any = true;
    if (tenantData.paymentGateway === '') {
      validated = false;
    }
    if (tenantData?.paymentGateway?.outGoingRoutingNo === '') {
      message.outGoingRoutingNo = 'Routing number is required';
      validated = false;
    }
    if (tenantData?.paymentGateway?.outGoingAccountNo === '') {
      message.outGoingAccountNo = 'account number is required';
      validated = false;
    }

    if (tenantData?.paymentGateway?.outGoingUsername === '') {
      message.outGoingUsername = 'user name is required';
      validated = false;
    }
    if (tenantData?.paymentGateway?.outGoingPassword === '') {
      message.outGoingPassword = 'password is required';
      validated = false;
    }
    if (tenantData?.paymentGateway?.incomingPassword === '') {
      message.incomingPassword = 'password is required';
      validated = false;
    }
    if (tenantData?.paymentGateway?.incomingAccountNo === '') {
      message.incomingAccountNo = 'account number is required';
      validated = false;
    }
    if (tenantData?.paymentGateway?.incomingRoutingNo === '') {
      message.incomingRoutingNo = 'Routing number is required';
      validated = false;
    }
    if (tenantData?.paymentGateway?.incomingAccountNo === '') {
      message.incomingAccountNo = 'account number is required';
      validated = false;
    }
    if (tenantData?.paymentGateway?.incomingUsername === '') {
      message.incomingUsername = 'account number is required';
      validated = false;
    }
    if (tenantData?.paymentGateway?.achMerchantId === '') {
      message.achMerchantId = 'ach Merchant Id is required';
      validated = false;
    }
    if (tenantData?.paymentGateway?.retailMerchantId === '') {
      message.retailMerchantId = 'ach Merchant Id is required';
      validated = false;
    }
    if (tenantData.retainage === '' && showContent === true) {
      validated = false;
    }
    if (tenantData.payments === '') {
      validated = false;
    }
    if (tenantData.manualPayment === '') {
      validated = false;
    }
    if (tenantData.digitalPayment === '') {
      validated = false;
    }
    if (tenantData.digitalPayment === '') {
      validated = false;
    }
    if (tenantData.margin === '') {
      validated = false;
    }
    if (tenantData.overHead === '') {
      validated = false;
    }
    if (tenantData.tax === '') {
      validated = false;
    }
    // if (tenantData.invoiceNo === '') {
    //   validated = false;
    // }
    if (tenantData.validityPeriod === '') {
      validated = false;
    }
    console.log(percentage)
    if (percentage !== 100 && selectedOption === "Part Payment") {
      validated = false;
    }
    tenantData?.invoiceNo?.parts.map((part: any, index: any) => {
      if ([0, 2, 4].includes(index)) {
        if (part.partValue === '' || part.type === '') {
          setInvoiceSettingValidated(false);
          validated = false;
        }
      }
    })
    return validated;
  }
  const handleUpdateSubmit = async () => {
    setCount(count + 1);
    let finalData: any = [];
    // if (showContent === false) {
    //   tenantData.retainage = null;
    // }
    Object.entries(tenantData).map((data: any) => {

      let type = typeof (data[1]);
      console.log(type);
      if (typeof (data[1]) === 'object') {
        finalData.push(
          {
            "key": data[0],
            "data": JSON.stringify(data[1])
          }
        )
      }
      else {
        finalData.push(
          {
            "key": data[0],
            "data": data[1]
          }
        )
      }

    })
    console.log(finalData.length, finalData);
    if (finalData.length !== 0) {
      let validated = validation();
      console.log(tenantDatas, tenantData);

      if (validated === true) {
        setOpenDialog(true);
        let result = await editFinanceData(finalData, accessToken);
        if (result.data === 'success') {
          setUpdateSuccess(true);
        }
        else {
          setUpdateError(true);
        }
        console.log(result);
        console.log(data);
      }
      else {
        // setOpenDialog(true);
        // setUpdateError(true);
      }
    }
    else {
      setNoChange(true);
    }
    setData('');
  }

  const handleSubmit = async (e: any) => {
    setCount(count + 1);

    setLoading(true);
    if (selectedOption === "Full Payment") {
      let newArray = [...tenantData.payments];
      newArray[0]["name"] = "Full Payment";
      newArray[0]["value"] = 100 - tenantData.retainage;
      setTenantData({ ...tenantData, payments: newArray });
    }
    else
      if (selectedOption === "Part Payment") {
        var totalPercentage = 0;
        tenantData.payments.map(payment => {
          totalPercentage = totalPercentage + Number(payment.value);
        }
        )
        if(tenantData.retainage !== "null") {
          setPercentage(totalPercentage + Number(tenantData.retainage));
        } else {
          setPercentage(totalPercentage + 0);
        }
        
      }

    setCount(count + 1);
    let finalData: any = [];
    if (showContent === false) {
      tenantData.retainage = null;
    }
    // eslint-disable-next-line array-callback-return
    Object.entries(tenantData).map((data: any) => {
      let type = typeof (data[1]);
      console.log(type);
      if (typeof (data[1]) === 'object') {
        finalData.push(
          {
            "key": data[0],
            "data": JSON.stringify(data[1])
          }
        )
      }
      else {
        finalData.push(
          {
            "key": data[0],
            "data": data[1]
          }
        )
      }

    })
    let validated = validation();
    if (validated) {
      setOpenDialogs(true);
      try {
        const result = await addFinanceData(finalData, accessToken);
        if (result.status === 201 || result.status === 200) {
          setCreateSuccess(true);
        } else {
          setCreateError(true);
        }
      } catch (error) {
        console.error(error);
        setCreateError(true);
      } finally {
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  }

  const handleToggle = () => {
    setShowContent((prev) => !prev);
  };
  const addItem = () => {
    const payment = {
      name: "",
      value: "",
      description: ""
    };

    const updatePayments = [
      // copy the current users state
      ...tenantData.payments,
      // now you can add a new object to add to the array
      payment,
    ];
    // update the state to the updatedUsers
    setTenantData({ ...tenantData, payments: updatePayments });
  };
  const onInputChange = (e: any) => {
    setTenantData({ ...tenantData, [e.target.name]: e.target.value });
    setData({ ...data, [e.target.name]: e.target.value });
  }

  const manulPaymentChange = (event: SelectChangeEvent<typeof manulPayment>) => {
    const {
      target: { value },
    } = event;
    setmanulPayment(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
    tenantData.manualPayment = typeof value === 'string' ? value.split(',') : value;
    data['manualPayment'] = typeof value === 'string' ? value.split(',') : value;
  };

  const digitalPaymentChange = (event: SelectChangeEvent<typeof digitalPayment>) => {
    const {
      target: { value },
    } = event;
    setdigitalPayment(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
    tenantData.digitalPayment = typeof value === 'string' ? value.split(',') : value;
    data['digitalPayment'] = typeof value === 'string' ? value.split(',') : value;
  };

  const paymentGatewayChange = (event: SelectChangeEvent<typeof digitalPayment>) => {
    const {
      target: { value },
    } = event;
    setPaymentGatWay(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
    tenantData.paymentGateway = typeof value === 'string' ? value.split(',') : value;
    data['paymentGateway'] = typeof value === 'string' ? value.split(',') : value;
  };

  const merchantChange = (e: any) => {
    setTenantData({ ...tenantData })
    setData({ ...tenantData });
    console.log(tenantData?.paymentGateway);
    const { name, value } = e.target;
    console.log(tenantData)

    setTenantData((tenantData) => ({
      ...tenantData,
      paymentGateway: {
        ...tenantData.paymentGateway,
        [name]: value,
      }
    }));
    setData((data) => ({
      ...data,
      paymentGateway: {
        ...data.paymentGateway,
        [name]: value,
      }
    }));


    // let newArray = [...tenantData?.paymentGateway];
    // newArray[index][e.target.name] = e.target.value;
    // setTenantData({ ...tenantData, paymentGateway: newArray });
    //setTenantData({ ...tenantData, paymentGateway: {[e.target.name]: e.target.value }});
  }

  const handleInputChange = (e: any, index: any) => {
    let a = tenantData?.invoiceNo;
    let newArray = [...a?.parts];
    newArray[index][e.target.name] = e.target.value;
    a.parts = newArray
    setTenantData({ ...tenantData, invoiceNo: a });
    setData({ ...data, invoiceNo: a });
  };

  const inputPaymentChange = (e: any, index: any) => {
    let newArray = [...tenantData?.payments];
    newArray[index][e.target.name] = e.target.value;
    setTenantData({ ...tenantData, payments: newArray });
    setData({ ...data, payments: newArray });
  };

  const handleToolRemoveItem = (index: any) => {
    let newArray = [...tenantData.payments];
    newArray.splice(index, 1)
    setTenantData({ ...tenantData, payments: newArray });
    setData({ ...data, payments: newArray });
  }

  const getInvoiceIdProvider = async () => {
    let result = await getInvoiceIdProviderParts(accessToken);
    var idProvider = result.data;
    setTenantData({ ...tenantData, invoiceNo: idProvider });
    //setData({ ...data, invoiceNo: idProvider });
  }

  const getAllTenantData = async () => {
    try {
      // Simulate API response (replace with actual fetch call)
      const result = await getTenantDataAll(accessToken);
      console.log(result.data);
      // if(result.data.length > 5){
      //   setState("edit");
      // }
      const requiredCases = new Set(['payments', 'manualPayment', 'digitalPayment', 'tax', 'margin', 'overHead', 'validityPeriod', 'retainage']);
      let shouldSetEdit = false;
      // Update tenantData state based on result
      result.data.forEach(item => {
        switch (item.key) {
          case 'payments':
            tenantData.payments = JSON.parse(item.data || '[]');
            break;
          case 'manualPayment':
            tenantData.manualPayment = [JSON.parse(item.data || '[]')[0] || ''];
            setmanulPayment([JSON.parse(item.data || '[]')[0] || '']);
            break;
          case 'digitalPayment':
            tenantData.digitalPayment = [JSON.parse(item.data || '[]')[0] || ''];
            setdigitalPayment([JSON.parse(item.data || '[]')[0] || '']);
            break;
          case 'tax':
            tenantData.tax = item.data || '';
            break;
          case 'margin':
            tenantData.margin = item.data || '';
            break;
          case 'overHead':
            tenantData.overHead = item.data || '';
            break;
          case 'validityPeriod':
            tenantData.validityPeriod = item.data || '';
            break;
          case 'paymentGateway':
            tenantData.paymentGateway = JSON.parse(item.data || '{}');
            break;
          case 'retainage':
            tenantData.retainage = item.data || '';
            if (tenantData.retainage !== "null" && tenantData.retainage.length > 0) {
              setShowContent(true);
            } else {
              setShowContent(false);
            }
            break;
            const requiredCases = new Set(['payments', 'manualPayment', 'digitalPayment', 'tax', 'margin', 'overHead', 'validityPeriod', 'retainage']);
            if (requiredCases.has(item.key)) {
              setState("edit");
            }
          default:
            break;
        }
        if (requiredCases.has(item.key)) {
          shouldSetEdit = true;
        }

      });
      if (shouldSetEdit) {
        setState("edit");
      }
      getInvoiceIdProvider()

      // Update state with new tenantData
      setTenantDatas({ ...tenantData });
      setTenantData({ ...tenantData });
      console.log(tenantData);

    } catch (error) {
      console.error("Error fetching tenant data:", error);
      // Handle error if needed
    }
  };
  React.useEffect(() => {
    getInvoiceIdProvider();
    setSelectedOption(tenantData.payments.length >= 1 ? "Part Payment" : "Full Payment");
    getAllTenantData();
  }, []);



  return (


    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2} >
        <Grid xs={12} md={12}>
          <Typography variant="h3" component="h2" align='left' sx={{ mx: 1, py: 1 }}>
            Finance Settings
          </Typography>
        </Grid>
        <Grid item xs={12} md={2}></Grid>
        <Grid item xs={12} md={8}>

          <Card sx={{ p: 3, boxShadow: 0, border: '1px solid #ccc' }}>
            <Typography variant="h6">
              Set Retainage
            </Typography>
            <div>
              <FormControlLabel
                control={<Android12Switch defaultChecked
                  checked={showContent} onChange={handleToggle} />}
                label=""
              />
              {showContent && (
                <div>
                  <FormControl variant="outlined" size='small'>
                    <InputLabel htmlFor="outlined-adornment-password">Set Retainage</InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-password"
                      endAdornment={
                        <InputAdornment position="end">
                          <div>
                            %
                          </div>
                        </InputAdornment>
                      }
                      name='retainage'
                      value={tenantData.retainage}
                      onChange={(e) => onInputChange(e)}
                      inputProps={{ min: 1 }}
                      label="Set Retainage"
                      error={(tenantData.retainage < 10 && count !== 0 && showContent === true)}
                      type='number'
                    />
                  </FormControl>
                </div>
              )}
            </div>

          </Card>
        </Grid>
        <Grid item xs={12} md={2}></Grid>
        <Grid item xs={12} md={2}></Grid>
        <Grid item xs={12} md={8}>
          <Card sx={{ p: 3, boxShadow: 0, border: '1px solid #ccc' }}>
            <Typography variant="h6">
              Payment Terms
            </Typography>
            <div>
              <FormControl fullWidth sx={{ mt: 3, mb: 3, width: 300 }} size='small'>
                <InputLabel id="demo-simple-select-label">Select Payment</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedOption}
                  // value={tenantData.payments.length>=1? "Part Payment" : "Full Payment"  }
                  onChange={handleChange}
                  label="Select Payment"
                  error={(selectedOption === '' && count !== 0)}
                >
                  <MenuItem value="Full Payment">Full Payment</MenuItem>
                  <MenuItem value="Part Payment">Part Payment</MenuItem>
                </Select>
              </FormControl>
              {selectedOption === "Part Payment" ?
                <div>
                  <Typography variant="h6">
                    <Table sx={{ border: '1px solid #000' }}>
                      <TableHead>
                        <TableRow>
                          <TableCell
                            sx={{ borderBottom: '1px solid #000', borderRight: '1px solid #000', backgroundColor: '#ccc' }}
                          >
                            Payment Name
                          </TableCell>
                          <TableCell
                            sx={{ borderBottom: '1px solid #000', borderRight: '1px solid #000', backgroundColor: '#ccc' }}
                          >
                            Percentage
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {tenantData?.payments?.map((payment: any, index: any) => {
                          return (
                            <TableRow>
                              <TableCell sx={{ borderBottom: '1px solid #000', borderRight: '1px solid #000' }}>
                                <TextField
                                  name="name"
                                  label="Payment Name"
                                  size='small'
                                  fullWidth
                                  value={payment?.name}
                                  onChange={(e) => inputPaymentChange(e, index)}
                                  error={(payment.name === '' && count !== 0)}
                                  required />
                              </TableCell>
                              <TableCell sx={{ borderBottom: '1px solid #000', borderRight: '1px solid #000' }}>
                                <Stack direction={'row'}>
                                  {/* <TextField
                                    name="value"
                                    label="percentage"
                                    size='small'
                                    fullWidth
                                    value={payment?.value}
                                    error={(payment.name === '' && count !== 0 )}
                                    inputProps = {{ min: 1 }}
                                    // error={(count !== 0 && percentage !== 100)}
                                    onChange={(e) => inputPaymentChange(e, index)}
                                    required /> */}
                                  <FormControl variant="outlined" size='small' fullWidth>
                                    <InputLabel htmlFor="outlined-adornment-password">Set Percentage</InputLabel>
                                    <OutlinedInput
                                      endAdornment={
                                        <InputAdornment position="end">
                                          <div>
                                            %
                                          </div>
                                        </InputAdornment>
                                      }
                                      fullWidth
                                      name='value'
                                      value={payment?.value === undefined ? '' : payment?.value}
                                      onChange={(e) => inputPaymentChange(e, index)}
                                      inputProps={{ min: 1 }}
                                      label="percentage"
                                      error={(payment.name === '' && count !== 0)}
                                      type='number'
                                      required
                                    />
                                  </FormControl>
                                  <IconButton onClick={() => handleToolRemoveItem(index)}>
                                    <DeleteRoundedIcon />
                                  </IconButton>
                                </Stack>
                              </TableCell>
                            </TableRow>
                          )
                        })}
                      </TableBody>
                    </Table>
                    <Button onClick={addItem}>Add Row</Button>
                  </Typography>
                </div>
                :
                <></>
              }
            </div>
          </Card>
        </Grid>
        <Grid item xs={12} md={2}></Grid>
        <Grid item xs={12} md={2}></Grid>
        <Grid item xs={12} md={8}>
          <Card sx={{ p: 3, boxShadow: 0, border: '1px solid #ccc' }}>
            <Typography variant="h6" sx={{ mx: 1, py: 1 }}>
              Payment Gateway Portal Configuration
            </Typography>
            <Typography variant="body1" sx={{ mx: 1, py: 1 }}>
              Select the Payment provides your requirement
            </Typography>
            <FormControl sx={{ m: 1, width: 300 }} size="small">
              <InputLabel id="demo-multiple-checkbox-label">Select Payment Gateway</InputLabel>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                error={(tenantData.paymentGateway === '' && count !== 0)}
                multiple
                value={paymentGateway}
                name="paymentGateway"
                onChange={paymentGatewayChange}
                input={<OutlinedInput label="Select Payment Gateway" />}
                renderValue={(selected) => selected.join(', ')}
                MenuProps={MenuProps}
              // readOnly
              >
                {initialItems.map((digital) => (
                  <MenuItem key={digital.name} value={digital.name}>
                    <Checkbox checked={paymentGateway.indexOf(digital.name) > -1} />
                    <ListItemText primary={digital.name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>


            <Stack direction="row" spacing={1}>
              {/* {tenantData?.paymentGateway?.map((p: any, index: any) => */}

              <>
                <div style={{ display: "block" }}>
                  <Typography variant="h5" sx={{ mx: 1, py: 1 }}>
                    Incoming Payments
                    <Divider sx={{ mt: 1 }} />

                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Typography variant="h6" sx={{ mx: 1, py: 1 }}>
                        Username
                      </Typography>
                      <FormControl sx={{ m: 1, width: '100%' }} variant="outlined" size='small'>
                        <TextField
                          size='small'
                          id="outlined-adornment-username"
                          name='incomingUsername'
                          onChange={(e) => merchantChange(e)}
                          label="Username"
                          value={tenantData?.paymentGateway?.incomingUsername}
                          error={(tenantData?.paymentGateway?.incomingUsername === '' && count !== 0)}
                          helperText={
                            tenantData?.paymentGateway?.incomingUsername === '' && count !== 0
                              ? message.incomingUsername
                              : null
                          }
                          required
                        // readOnly
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={6}>
                      <Typography variant="h6" sx={{ mx: 1, py: 1 }}>
                        Password
                      </Typography>
                      <FormControl sx={{ m: 1, width: '100%' }} variant="outlined" size='small'>
                        <TextField
                          size='small'

                          id="outlined-adornment-password"
                          name='incomingPassword'
                          onChange={(e) => merchantChange(e)}
                          label="Password"
                          value={tenantData?.paymentGateway?.incomingPassword}
                          error={(tenantData?.paymentGateway?.incomingPassword === '' && count !== 0)}
                          helperText={
                            tenantData?.paymentGateway?.incomingPassword === '' && count !== 0
                              ? message.incomingPassword
                              : null
                          }
                          required
                        // readOnly
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={6}>
                      <Typography variant="h6" sx={{ mx: 1, py: 1 }}>
                        Account No
                      </Typography>
                      <FormControl sx={{ m: 1, width: '100%' }} variant="outlined" size='small'>
                        <TextField
                          size='small'

                          id="outlined-adornment-account-no"
                          name='incomingAccountNo'
                          onChange={(e) => merchantChange(e)}
                          label="Account No"
                          type='number'

                          value={tenantData?.paymentGateway?.incomingAccountNo}
                          error={(tenantData?.paymentGateway?.incomingAccountNo === '' && count !== 0)}
                          helperText={
                            tenantData?.paymentGateway?.incomingAccountNo === '' && count !== 0
                              ? message.incomingAccountNo
                              : null
                          }
                          required
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={6}>
                      <Typography variant="h6" sx={{ mx: 1, py: 1 }}>
                        Account Name
                      </Typography>
                      <FormControl sx={{ m: 1, width: '100%' }} variant="outlined" size='small'>
                        <TextField
                          size='small'

                          id="outlined-adornment-account-name"
                          name='incomingAccountName'
                          onChange={(e) => merchantChange(e)}
                          label="Account Name"
                          value={tenantData?.paymentGateway?.incomingAccountName}
                          error={(tenantData?.paymentGateway?.incomingAccountName === '' && count !== 0)}
                          helperText={
                            tenantData?.paymentGateway?.incomingAccountName === '' && count !== 0
                              ? message.incomingAccountName
                              : null
                          }
                          required
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="h6" sx={{ mx: 1, py: 1 }}>
                        Retail Merchant Id
                      </Typography>
                      <FormControl sx={{ m: 1, width: '100%' }} variant="outlined" size='small'>
                        <TextField
                          size='small'

                          id="outlined-adornment-retail-merchant-id"
                          name='retailMerchantId'
                          onChange={(e) => merchantChange(e)}
                          label="Retail Merchant Id"
                          // type='number'

                          value={tenantData?.paymentGateway?.retailMerchantId}
                          error={(tenantData?.paymentGateway?.retailMerchantId === '' && count !== 0)}
                          helperText={
                            tenantData?.paymentGateway?.retailMerchantId === '' && count !== 0
                              ? message.retailMerchantId
                              : null
                          }
                          required
                        // readOnly
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="h6" sx={{ mx: 1, py: 1 }}>
                        Routing No
                      </Typography>
                      <FormControl sx={{ m: 1, width: '100%' }} variant="outlined" size='small'>
                        <TextField
                          size='small'

                          id="outlined-adornment-routing-no"
                          name='incomingRoutingNo'
                          onChange={(e) => merchantChange(e)}
                          label="Routing No"
                          type='number'

                          value={tenantData?.paymentGateway?.incomingRoutingNo}
                          error={(tenantData?.paymentGateway?.incomingRoutingNo === '' && count !== 0)}
                          helperText={
                            tenantData?.paymentGateway?.incomingRoutingNo === '' && count !== 0
                              ? message.incomingRoutingNo
                              : null
                          }
                          required
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </div>
              </>
            </Stack>
            <Stack direction="row" spacing={1}>
              <>
                <div style={{ display: "block" }}>
                  <Typography variant="h5" sx={{ mt: 3, mx: 1, py: 1 }}>
                    Outgoing Payments
                    <Divider sx={{ mt: 1 }} />

                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Typography variant="h6" sx={{ mx: 1, py: 1 }}>
                        Username
                      </Typography>
                      <FormControl sx={{ m: 1, width: '100%' }} variant="outlined" size='small'>
                        <TextField
                          size='small'

                          id="outlined-adornment-username"
                          name='outGoingUsername'
                          onChange={(e) => merchantChange(e)}
                          label="Username"
                          value={tenantData?.paymentGateway?.outGoingUsername}
                          error={(tenantData?.paymentGateway?.outGoingUsername === '' && count !== 0)}
                          required
                          helperText={
                            tenantData?.paymentGateway?.outGoingUsername === '' && count !== 0
                              ? message.outGoingUsername
                              : null
                          }
                        // readOnly
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={6}>
                      <Typography variant="h6" sx={{ mx: 1, py: 1 }}>
                        Password
                      </Typography>
                      <FormControl sx={{ m: 1, width: '100%' }} variant="outlined" size='small'>
                        {/* <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel> */}
                        <TextField

                          size='small'
                          id="outlined-adornment-password"
                          name='outGoingPassword'
                          onChange={(e) => merchantChange(e)}
                          label="Password"
                          value={tenantData?.paymentGateway?.outGoingPassword}
                          error={(tenantData?.paymentGateway?.outGoingPassword === '' && count !== 0)}
                          helperText={
                            tenantData?.paymentGateway?.outGoingPassword === '' && count !== 0
                              ? message.outGoingPassword
                              : null
                          }
                          required
                        // readOnly
                        />
                      </FormControl>
                    </Grid>


                    <Grid item xs={6}>
                      <Typography variant="h6" sx={{ mx: 1, py: 1 }}>
                        ACH Merchant Id
                      </Typography>
                      <FormControl sx={{ m: 1, width: '100%' }} variant="outlined" size='small'>
                        <TextField
                          size='small'

                          id="outlined-adornment-ach-merchant-id"
                          name='achMerchantId'
                          onChange={(e) => merchantChange(e)}
                          label="ACH Merchant Id"
                          // type='number'

                          value={tenantData?.paymentGateway?.achMerchantId}
                          error={(tenantData?.paymentGateway?.achMerchantId === '' && count !== 0)}
                          helperText={
                            tenantData?.paymentGateway?.achMerchantId === '' && count !== 0
                              ? message.achMerchantId
                              : null
                          }
                          required
                        // readOnly
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={6}>
                      <Typography variant="h6" sx={{ mx: 1, py: 1 }}>
                        Account No
                      </Typography>
                      <FormControl sx={{ m: 1, width: '100%' }} variant="outlined" size='small'>
                        <TextField
                          size='small'

                          id="outlined-adornment-account-no"
                          name='outGoingAccountNo'
                          onChange={(e) => merchantChange(e)}
                          label="Account No"
                          type='number'

                          value={tenantData?.paymentGateway?.outGoingAccountNo}
                          error={(tenantData?.paymentGateway?.outGoingAccountNo === '' && count !== 0)}
                          helperText={
                            tenantData?.paymentGateway?.outGoingAccountNo === '' && count !== 0
                              ? message.outGoingAccountNo
                              : null
                          }
                          required
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={6}>
                      <Typography variant="h6" sx={{ mx: 1, py: 1 }}>
                        Account Name
                      </Typography>
                      <FormControl sx={{ m: 1, width: '100%' }} variant="outlined" size='small'>
                        <TextField
                          size='small'

                          id="outlined-adornment-account-name"
                          name='outGoingAccountName'
                          onChange={(e) => merchantChange(e)}
                          label="Account Name"
                          value={tenantData?.paymentGateway?.outGoingAccountName}
                          error={(tenantData?.paymentGateway?.outGoingAccountName === '' && count !== 0)}
                          helperText={
                            tenantData?.paymentGateway?.outGoingAccountName === '' && count !== 0
                              ? message.outGoingAccountName
                              : null
                          }
                          required
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={6}>
                      <Typography variant="h6" sx={{ mx: 1, py: 1 }}>
                        Routing No
                      </Typography>
                      <FormControl sx={{ m: 1, width: '100%' }} variant="outlined" size='small'>
                        <TextField
                          size='small'

                          id="outlined-adornment-routing-no"
                          name='outGoingRoutingNo'
                          onChange={(e) => merchantChange(e)}
                          label="Routing No"
                          type='number'

                          value={tenantData?.paymentGateway?.outGoingRoutingNo}
                          error={(tenantData?.paymentGateway?.outGoingRoutingNo === '' && count !== 0)}
                          helperText={
                            tenantData?.paymentGateway?.outGoingRoutingNo === '' && count !== 0
                              ? message.outGoingRoutingNo
                              : null
                          }
                          required

                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </div>
              </>
              {/* )} */}
            </Stack>

          </Card>
        </Grid>
        <Grid item xs={12} md={2}></Grid>
        <Grid item xs={12} md={2}></Grid>
        <Grid item xs={12} md={8}>
          <Card sx={{ p: 3, boxShadow: 0, border: '1px solid #ccc' }}>
            <Typography variant="h6" sx={{ mx: 1, py: 1 }}>
              Payment Types
            </Typography>
            <Typography variant="body1" sx={{ mx: 1, py: 1 }}>
              Manual payment Methods
            </Typography>
            <div>
              <FormControl sx={{ m: 1, width: 300 }} size="small">
                <InputLabel id="demo-multiple-checkbox-label">Manual Payment</InputLabel>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  error={(tenantData.manualPayment === '' && count !== 0)}
                  value={manulPayment}
                  onChange={manulPaymentChange}
                  input={<OutlinedInput label="Manual payment" />}
                  renderValue={(selected) => selected.join(', ')}
                  MenuProps={MenuProps}
                >
                  {manualpayments.map((manual) => (
                    <MenuItem key={manual} value={manual}>
                      <Checkbox checked={manulPayment.indexOf(manual) > -1} />
                      <ListItemText primary={manual} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <Typography variant="body1" sx={{ mx: 1, py: 1 }}>
              Digital payment Methods
            </Typography>
            <div>
              <FormControl sx={{ m: 1, width: 300 }} size="small">
                <InputLabel id="demo-multiple-checkbox-label">Digital Payment</InputLabel>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  error={(tenantData.digitalPayment === '' && count !== 0)}
                  value={digitalPayment}
                  onChange={digitalPaymentChange}
                  input={<OutlinedInput label="Digital payment" />}
                  renderValue={(selected) => selected.join(', ')}
                  MenuProps={MenuProps}
                >
                  {digitalpayments.map((digital) => (
                    <MenuItem key={digital} value={digital}>
                      <Checkbox checked={digitalPayment.indexOf(digital) > -1} />
                      <ListItemText primary={digital} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </Card>
        </Grid>
        <Grid item xs={12} md={2}></Grid>
        <Grid item xs={12} md={2}></Grid>
        <Grid item xs={12} md={8}>
          <Card sx={{ p: 3, boxShadow: 0, border: '1px solid #ccc' }}>
            <Typography variant="h6" sx={{ mx: 1, py: 1 }}>
              Tax Percentage
            </Typography>
            <div >
              <FormControl sx={{ m: 1, width: 300 }} variant="outlined" size="small">
                <InputLabel htmlFor="outlined-adornment-password">Set Tax Percentage</InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  error={(tenantData.tax === '' && count !== 0)}
                  value={tenantData.tax}
                  onChange={(e) => onInputChange(e)}
                  name="tax"
                  endAdornment={
                    <InputAdornment position="end">
                      <div>
                        %
                      </div>
                    </InputAdornment>
                  }
                  inputProps={{ min: 1 }}
                  label="Set Tax Percentage %"
                  type='number'
                />
              </FormControl>
            </div>
          </Card>
        </Grid>
        <Grid item xs={12} md={2}></Grid>
        <Grid item xs={12} md={2}></Grid>
        <Grid item xs={12} md={8}>
          <Card sx={{ p: 3, boxShadow: 0, border: '1px solid #ccc' }}>
            <Typography variant="h6" sx={{ mx: 1, py: 1 }}>
              Margins
            </Typography>
            <Typography variant="body1" sx={{ mx: 1, py: 1 }}>
              Profit Pecentage
            </Typography>
            <div >
              <FormControl sx={{ m: 1, width: 300 }} variant="outlined" size="small">
                <InputLabel htmlFor="outlined-adornment-password">Profit Pecentage</InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  name='margin'
                  value={tenantData.margin}
                  error={(tenantData.margin === '' && count !== 0)}
                  onChange={(e) => onInputChange(e)}
                  endAdornment={
                    <InputAdornment position="end">
                      <div>
                        %
                      </div>
                    </InputAdornment>
                  }
                  inputProps={{ min: 1 }}
                  label="Profit Pecentage"
                  type='number'
                />
              </FormControl>
            </div>
            <Typography variant="body1" sx={{ mx: 1, py: 1 }}>
              Overhead
            </Typography>
            <div >
              <FormControl sx={{ m: 1, width: 300 }} variant="outlined" size="small">
                <InputLabel htmlFor="outlined-adornment-password">Overhead</InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  error={(tenantData.overHead === '' && count !== 0)}
                  name="overHead"
                  value={tenantData.overHead}
                  onChange={(e) => onInputChange(e)}
                  endAdornment={
                    <InputAdornment position="end">
                      <div>
                        %
                      </div>
                    </InputAdornment>
                  }
                  inputProps={{ min: 1 }}
                  label="Overhead %"
                  type='number'
                />
              </FormControl>
            </div>
          </Card>
        </Grid>
        <Grid item xs={12} md={2}></Grid>
        <Grid item xs={12} md={2}></Grid>
        <Grid item xs={12} md={8}>
          <Card sx={{ p: 3, boxShadow: 0, border: '1px solid #ccc' }}>
            <Typography variant="h6" sx={{ mx: 1, py: 1 }}>
              Invoice Settings
            </Typography>
            <Typography variant="body1" sx={{ mx: 1, py: 1 }}>
              Invoice No format
            </Typography>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'left',
                '& > *': {
                  m: 1,
                },
              }}
            >
              <Stack direction="row" spacing={1}>
                <TextField
                  label=""
                  name="partValue"
                  type="text" // Set type to 'text'
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    inputMode: 'numeric', // Specify numeric input mode
                    pattern: '[0-9]*', // Allow only numbers
                    // maxLength: 5, // Set maximum length to 5 characters
                  }}
                  value={tenantData?.invoiceNo?.parts !== undefined ? tenantData?.invoiceNo?.parts[0]?.partValue : ""}
                  error={tenantData?.invoiceNo?.parts !== undefined && (tenantData?.invoiceNo?.parts[0]?.partValue === '' && !invoiceSettingValidated)}
                  onChange={(e) => handleInputChange(e, 0)}
                  size="small"
                />
                <TextField
                  label=""
                  name="type"
                  type="text" // Set type to 'text'
                  inputProps={{
                    inputMode: 'numeric', // Specify numeric input mode
                    pattern: '[0-9]*', // Allow only numbers
                    // maxLength: 5, // Set maximum length to 5 characters
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => handleInputChange(e, 2)}
                  size="small"
                  value={tenantData?.invoiceNo?.parts !== undefined ? (tenantData?.invoiceNo?.parts[2]?.type).replace(/[\[\]']+/g, '') : ""}
                  error={tenantData?.invoiceNo?.parts !== undefined && (tenantData?.invoiceNo?.parts[2]?.type === '' && !invoiceSettingValidated)}
                />
                <TextField
                  label=""
                  type="text" // Set type to 'text'
                  inputProps={{
                    inputMode: 'numeric', // Specify numeric input mode
                    pattern: '[0-9]*', // Allow only numbers
                    // maxLength: 5, // Set maximum length to 5 characters
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="partValue"
                  onChange={(e) => handleInputChange(e, 4)}
                  size="small"
                  value={tenantData?.invoiceNo?.parts !== undefined ? tenantData?.invoiceNo?.parts[4]?.partValue : ""}
                  error={tenantData?.invoiceNo?.parts !== undefined && (tenantData?.invoiceNo?.parts[4]?.partValue === '' && !invoiceSettingValidated)}
                />
              </Stack>
            </Box>
          </Card>
        </Grid>
        <Grid item xs={12} md={2}></Grid>
        <Grid item xs={12} md={2}></Grid>
        <Grid item xs={12} md={8}>
          <Card sx={{ p: 3, boxShadow: 0, border: '1px solid #ccc' }}>
            <Typography variant="h6" sx={{ mx: 1, py: 1 }}>
              Invoice Validity Period
            </Typography>
            <TextField
              id="outlined-number"
              label="Days"
              type="number"
              size="small"
              value={tenantData.validityPeriod}
              error={(tenantData.validityPeriod === '' && count !== 0)}
              name="validityPeriod"
              onChange={(e) => onInputChange(e)}
              inputProps={{ min: 1 }}
            // InputLabelProps={{
            //   shrink: true,
            // }}
            />
          </Card>
        </Grid>
        <Grid item xs={12} md={2}></Grid>
        <Grid item xs={12} md={2}></Grid>
        <Grid item xs={12} md={8}>
          <Grid container justifyContent="flex-end">
            <Box m={2} className="poFooter">
              <Button
                sx={{ marginRight: "32px" }}
                variant="contained"
                className="primaryTonalButton"
                size="medium"
                color="error"
              // onClick={}
              >
                Close
              </Button>
              {state === "edit" ?
                <Button
                  className="sucessButton"
                  variant="contained"
                  size="medium"
                  onClick={handleUpdateSubmit}
                >
                  Update
                </Button>
                :
                <Button
                  className="sucessButton"
                  variant="contained"
                  size="medium"
                  onClick={handleSubmit}
                  disabled={loading}
                >
                  {loading ? "Saving..." : "Save"}
                </Button>
              }
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={12} md={2}></Grid>
      </Grid>
      <CustomDialog
        open={openDialogs}
        onClose={() => setOpenDialogs(false)}
        success={createSuccess}
        error={createError}
        Content={createSuccess ? "Created Successfully" : " Error Couldnot Created "} />

      <CustomDialog
        open={openDialog}
        onClose={() => onclose()}
        success={updateSuccess}
        error={updateError}
        Content={updateSuccess ? "Updated Successfully" : " Error Couldnot Updated"} />

      <Dialog open={noChange} >
        <DialogTitle className='success' sx={{ backgroundColor: "rgb(255,193,0) !important" }}>
          <Box paddingLeft={8} paddingRight={8}>
            <Typography textAlign={'center'}>
              <InfoOutlinedIcon sx={{ fontSize: "50px", color: "white" }} /></Typography>
            {/* <Typography textAlign={'center'} color='white' fontSize={'20px'} fontWeight={'400'} >
                               Success
                            </Typography> */}
          </Box>
        </DialogTitle>
        <Box paddingLeft={8} paddingRight={8}>
          <DialogContent >
            <DialogContentText textAlign={'center'} fontSize={'18px'} fontWeight={'500'}>No Value has been Changed</DialogContentText>
          </DialogContent>
          <DialogActions style={{ justifyContent: "center" }}>
            <Button variant='contained' className="NoChangeButton" onClick={handleNoChange}>Ok</Button>
          </DialogActions>
        </Box>
      </Dialog>
    </Box >
  )
};

export default Financesettings;
