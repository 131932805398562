/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 25-Nov-2024
* @author      : vijay kumar
* @since       : 0.0.1
* Requirement# :
* Purpose      : Confirm dialog to show message dependent task completed or not
* -----------------------------------------------------------------------------
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem |   DATE         |   AUTHOR          |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
*/
import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { DialogContentText, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import { Box, Stack } from '@mui/system';


const TaskWarningDialog: React.FC<any> = (props) => {
    const handleClose = () => {
        props.onClose();
    };



    return (
        <React.Fragment>

            <Dialog
                open={props?.open}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle sx={{ m: 0, p: 2, fontSize: '16px' }}>
                    {/* <Box paddingLeft={8} paddingRight={8}>
                        <Typography textAlign={'center'}>
                            <WarningAmberRoundedIcon sx={{ fontSize: "40px", color: "white" }} /></Typography>
                        <Typography textAlign={'center'} color='white' fontSize={'18px'} fontWeight={'400'} >
                            Warning
                        </Typography>
                    </Box> */}
                    Warning
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: "white",
                    }}
                >
                    <CloseIcon />
                </IconButton>
                {props?.isCompleteWarning === false ?
                    <DialogContent dividers>
                        <Typography gutterBottom marginBottom={1}>
                            Kindly complete the steps required in this current task before proceeding to the next one.
                        </Typography>
                        <DialogContentText gutterBottom>
                            The Following Steps are required to Proceed.
                        </DialogContentText>
                        <DialogContentText gutterBottom>
                            {props?.taskNames?.map((task: any, index: any) => {
                                return (
                                    <Stack direction={'row'} spacing={1}>
                                        <Typography>{index + 1}.</Typography>
                                        <Typography> {task}</Typography>
                                    </Stack>
                                );
                            })}
                        </DialogContentText>
                    </DialogContent>
                    :
                    <DialogContent dividers>
                        <Typography gutterBottom marginBottom={1}>
                            Kindly complete the steps required in this task to proceed further.
                        </Typography>
                    </DialogContent>
                }
                <DialogActions>
                    <Button onClick={handleClose} variant='contained'>Ok</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

export default TaskWarningDialog;