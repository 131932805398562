/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 2-Nov-2023
* @author      : Shiyamkumar.G
* @since       : 0.0.1
* Requirement# :
* Purpose      : Option Selection Component For Create Bid Form
* -----------------------------------------------------------------------------
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem |    DATE    |    AUTHOR     |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
*       1454     | 26-03-2024 | Shiyam Kumar  | Trade Selection Issue Fix.
*       1782     | 09-05-2024 | Shiyam Kumar  | MAde Changes For Trade Domain Change
*       1937     | 15-05-2024 | Vanitha       | mandatory field trade implementation
*
*/

import { Card, Grid, FormControl, RadioGroup, Paper, colors, FormControlLabel, Radio, Typography, CardActionArea, CardContent, CardMedia, Link } from "@mui/material";
import { Box, Stack, display, useTheme } from "@mui/system";
import { useEffect, useState } from "react";
import { tokens } from "../../../hooks/theme";
import roofing from "../../../assets/imgs/iStock-1149313214-res.webp";
import GreenHouse from "../../../assets/imgs/GreenHouse.jpg";

import siding from "../../../assets/imgs/siding-replacement-in-indianapolis-01-634x483.webp";
import gutter from "../../../assets/imgs/images.jpg";
import asphalt from "../../../assets/imgs/news-page-4.jpg";
import certainteed from "../../../assets/imgs/CertainTeed-Logo-500x313.png";
import gaf from "../../../assets/imgs/download (2).png";
import owens from "../../../assets/imgs/download (1).png";
import James from "../../../assets/imgs/james.png";
import norandex from "../../../assets/imgs/norandex.png";
import smartside from "../../../assets/imgs/smartside.png";
import acm from "../../../assets/imgs/Acm.png";
import berger from "../../../assets/imgs/berger.png";
import us from "../../../assets/imgs/US.png";
import vinyl from "../../../assets/imgs/Vinyl siding.jpg";
import aluminum from "../../../assets/imgs/aluminiumgutter.jpg";


import "../CreateBid.scss";
import { useOidcAccessToken } from "@axa-fr/react-oidc";
import { getTradeByTradeOid } from "../../../services/TradeConfigurationService";

const SelectionCard: React.FC<any> = (props) => {
    // console.log(props)
    const [cardselected, setcardselected] = useState<any>(props.data.selectedValue || null);
    const [data, setData] = useState(Object(props.data));
    const trade = "trade";
    const [formValidator, setFormValidator] = useState(false);
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { accessToken } = useOidcAccessToken();

    useEffect(() => {

        if (props.count !== 0) {
            setFormValidator(true);
        }

        setData(Object(props.data));

    }, [props.count])

    useEffect(() => {
        // if (props.data.selectedValue) {
        //     setcardselected(props.data.selectedValue)
        // }

    }, [])

    const onchange = async (e: any) => {
        props.function(e);
        setcardselected(e.target.title);
        // console.log(cardselected)
        if (e.target.name === trade) {
            setFormValidator(false);
        }
    }

    const handleChoose = (e: any) => {
        if (!cardselected) {
            setFormValidator(true);
        } else {
            const randomIndex = Math.floor(Math.random() * props.data.data.length);
            const randomlySelectedCard = props.data.data[randomIndex];
            setcardselected(randomlySelectedCard);
            props.function({ target: { title: randomlySelectedCard, name: props.data.type } });
        }
    };


    function handleClick(e: any) {
        throw new Error("Function not implemented.");
    }
    const getImageForValue = (value: string) => {
        switch (value) {
            case 'Roofing':
                return roofing;
            case 'Guttering':
                return gutter;
            case 'Siding':
                return siding;
            case 'Asphalt Shingles':
                return asphalt;
            case 'Certain Teed':
                return certainteed;
            case 'GAF':
                return gaf;
            case 'Owens Corning':
                return owens;
            case 'James Hardie':
                return James;
            case 'Norandex':
                return norandex;
            case 'Smart Side':
                return smartside;
            case 'ACM':
                return acm;
            case 'Berger':
                return berger;
            case 'US Aluminum Inc':
                return us;
            case 'Vinyl Siding':
                return vinyl;
            case 'Aluminum':
                return aluminum;
            case 'GreenHouse':
                return GreenHouse;
            case 'Green House':
                return GreenHouse;
            case 'green house':
                return GreenHouse;
            default:
                return roofing;
        }
    };
    useEffect(() => {
        // console.log("cardselected updated:", cardselected);
    }, [cardselected]);

    return (
        <Card variant="outlined" className='selectionCard'>
            <Grid container spacing={1}>
                <Grid item xs={12} className="selectionCard-title">
                    <Grid container spacing={2}>
                        <Grid item xs={8}>
                            <Stack direction={'row'}>
                                <Typography variant="h5" color="rgb(60 60 60 / 87%)">{Object(props.data).title}</Typography>
                                {formValidator ? <Typography variant="h6">{"(Must Select Any One Option)"}</Typography> : <></>}
                            </Stack>
                        </Grid>
                        <Grid item xs={4}>
                            <Box display="flex" justifyContent="flex-end" pr={2}>
                                {Object(props.data).suggestion && <Link component='button' underline="none" key="1" color="inherit" id='' onClick={(e) => handleChoose(e)} sx={{ "&:hover": { color: colors.primary[500] + ' !important', background: 'none !important' } }} className='formSubTitle'>Make this decision for me</Link>}
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} className="options">
                    <Grid container >
                        {(props.data.data).length > 0 ? props.data.data.map((value: any) => (
                            <Grid item xs={12} md={12} lg={6} xl={3} pb={2} pr={{ lg: 3 }}>
                                {/* <Typography>{props.data.selectedValue + ",  " + cardselected + ",  " + value.tradeOid}</Typography> */}
                                <Card
                                    className="optionCard"
                                    sx={
                                        props.data.selectedValue !== cardselected
                                            ? props.data.selectedValue === value.tradeOid
                                                ? {}
                                                : cardselected === value.tradeOid
                                                    ? { inset: -1, border: `2px solid ${colors.primary[500]}` }
                                                    : {}
                                            : cardselected === value.tradeOid
                                                ? { inset: -1, border: `2px solid ${colors.primary[500]}` }
                                                : {}
                                    }
                                >
                                    {props.update ? (
                                        // Render a read-only Card if `selectedValue` is present
                                        <>
                                            <CardMedia
                                                sx={{
                                                    height: 100,
                                                    margin: 'auto',
                                                }}
                                                image={getImageForValue(value.name)}
                                                title={value.tradeOid}
                                            />
                                            <Card sx={{ height: 20 }}>
                                                <Typography
                                                    variant="h6"
                                                    component="h2"
                                                    sx={{ textAlign: "center", color: 'gray', fontWeight: 1 }}
                                                    title={value.name}
                                                >
                                                    {value.name}
                                                </Typography>
                                            </Card>
                                        </>
                                    ) : (
                                        // Render interactive Card if no `selectedValue`
                                        <CardActionArea
                                            onClick={(e: any) => {
                                                e.target.name = props.data.type;
                                                onchange(e);
                                            }}
                                        >
                                            <CardMedia
                                                sx={{
                                                    height: 100,
                                                    margin: 'auto',
                                                }}
                                                image={getImageForValue(value.name)}
                                                title={value.tradeOid}
                                            />
                                            <Card sx={{ height: 20 }}>
                                                <Typography
                                                    variant="h6"
                                                    component="h2"
                                                    sx={{ textAlign: "center", color: 'gray', fontWeight: 1 }}
                                                    title={value.name}
                                                >
                                                    {value.name}
                                                </Typography>
                                            </Card>
                                        </CardActionArea>
                                    )}
                                </Card>


                            </Grid>
                        )) : <></>}
                    </Grid>
                </Grid>
            </Grid>

        </Card>
    )
}

export default SelectionCard;