import React, { useEffect, useState } from "react";
import { FormControl, FormControlLabel, FormHelperText, Radio, RadioGroup } from "@mui/material";
import { CodeListItem, ItemData, ItemDef } from "../../../interface/SdmInterfaces";

interface Props {
    itemDef: ItemDef;
    // setTextData: React.Dispatch<React.SetStateAction<any>>;
    onItemchange: React.Dispatch<React.SetStateAction<any>>;
}

const RadioType: React.FC<any> = ({ OID, itemDef, conditionDef, codeList, onItemchange, showStatus, itemDatas, error }) => {

    //console.log(itemDatas);

    const [clicked, setClicked] = useState(false);
    const [itemData, setItemData] = useState<ItemData>((itemDef?.OID === itemDatas[0]?.ItemOID) ? itemDatas[0] : []);

    useEffect(() => {
        if (itemDatas?.length > 0) {
            itemDatas?.map((i: any) => {
                if (i?.ItemOID === itemDef?.OID) {
                    i?.CodeList?.map((cd: any) => {
                        cd?.CodeListItem?.map((c: any) => {
                            codeList?.map((cl: any) => {
                                cl?.CodeListItem?.map((cli: any) => {
                                    if (cli?.CodedValue === c?.CodedValue) {
                                        cli.IsEnabled = c.IsEnabled;
                                        if (c.IsEnabled) {
                                            conditionDef?.map((cnd: any) => {
                                                showStatus(cnd?.FormalExpression?.text?.includes(i.ItemOID), cnd.OID, OID);
                                            })
                                        }

                                    }
                                }
                                )
                            })
                        })
                    })
                }
            })
        }
    }, [itemDatas]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        console.log(event.target.value);
        //setClicked(event.target.value === "Yes" ? true : false);
        if (event.target.value === "Yes") {
            conditionDef.map((cd: any) => {
                showStatus(cd.FormalExpression.text.includes(event.target.value), cd.OID, OID);
            });
        } else {
            conditionDef.map((cd: any) => {
                showStatus(cd.FormalExpression.text.includes(event.target.value), cd.OID, OID);
            });
        }

        if (itemDatas.length > 0 && itemDatas[0] !== undefined) {
            setItemData({ ...itemDatas[0], Value: event.target.value });
            onItemchange({ ...itemDatas[0], Value: event.target.value });
        } else {
            let tempItemData: ItemData = { ItemOID: itemDef.OID, Value: event.target.value, CodeList: [], MetaKey: itemDef.MetaKey };
            setItemData(tempItemData);
            onItemchange(tempItemData);
        }
    }

    return (
        <>
            <FormControl>
                <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    // sx={{ m: 1 }}
                    value={itemData?.Value}
                    onChange={handleChange}
                >
                    <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                    <FormControlLabel value="No" control={<Radio />} label="No" />
                </RadioGroup>
                <FormHelperText>{(error?.OID === itemDef.OID) ? 'Please select an option' : null}</FormHelperText>
            </FormControl>
        </>
    )

}

export default RadioType;