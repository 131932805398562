import { Box, FormControl, Grid, InputLabel, MenuItem, OutlinedInput, Select, TextField } from '@mui/material';
import { useOidcAccessToken } from '@axa-fr/react-oidc';
import * as React from 'react';
import { getPredecessorTasks, getSuccessorTasks, getTaskList, getTasksByProjectIdAndTaskId, getTasksList, getTaskTypeList } from '../../services/TaskService';
import { LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { count } from 'console';
import dayjs from 'dayjs';
import { getConfiguredDataByKey } from '../../services/TradeConfigurationService';
import { weekdays } from '../../utils/Dateutils';
import { units } from '../../utils/TaskUtils';
import { invoiceTaskGroup, poTaskGroup } from '../../utils/TaskConstants';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 2.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const TaskForm: React.FC<any> = (props) => {
    const [predecessorTask, setPredecessorTask] = React.useState<any>([]);
    const [successorTask, setSuccessorTask] = React.useState<any>([]);
    const [taskTypes, setTaskTypes] = React.useState<any>([]);
    const { accessToken } = useOidcAccessToken();
    const taskStatus = ["new", "scheduled", "active"];
    const [tasks, setTasks] = React.useState<any>([]);
    const [show, setShow] = React.useState<any>(true);
    const [taskId, setTaskId] = React.useState<any>(props?.task?.taskId);
    const [selectedTask, setSelectedTask] = React.useState<any>();
    const [dependencies, setDependencies] = React.useState<any[]>(props?.task?.dependencies);
    const[startTimeHour,setStartTimeHour] = React.useState<any>();
    const[endTimeHour,setendTimeHour] = React.useState<any>();
    const [count, setCount] = React.useState(0);
    const [progressingTasks, setProgressingTasks] = React.useState<any>([]);


    const handleChange = (event: any) => {
        if (event.target.name === "taskId") {
            setSelectedTask(event.target.value);
            getSuccessorTask(event.target.value.taskId);
            getPredecessorTask(event.target.value.taskId);
            setDependencies([]);
            props.resetData();
        }
        if (event.target.name === "dependencies") {
            setShow(false);
            setDependencies(
                typeof event.target.value === 'string' ? event.target.value.split(',') : event.target.value
            );
        }
        // if (event.target.name === "otherDependencies") {
        //     setDependencies(
        //         typeof event.target.value === 'string' ? event.target.value.split(',') : event.target.value
        //     );
        // }
        if (event.target.name === "taskType") {
            if (poTaskGroup.includes(event.target.value) || invoiceTaskGroup.includes(event.target.value)) {
                getTask(event.target.value);
            }
        }
        props.changeTaskData(event);
    }
    const onDateChange = (event: any, field: any) => {
        if (field === 'startTime'){
        setShow(false)
        const taskHour = event.hour();
        setStartTimeHour(taskHour);
        setCount(count + 1);
        }
        if (field === 'endTime'){ 
            const taskHour = event.hour();
            setendTimeHour(taskHour);
            setCount(count + 1);
            }
        props.dateChange(event, field)
    }

    const getTaskById = (taskId:any) =>{
        return tasks.filter((task:any) => task.taskId === taskId)[0];
    }

    const getTask = async (taskType: any) => {
        let result = await getTasksList(props?.projectId, taskType, props?.taskId, accessToken);
        setPredecessorTask(result?.data);
    }

    const getTaskListByProjectIdAndTaskId = async () => {
        let result = await getTasksByProjectIdAndTaskId(props?.projectId, props?.taskId, accessToken);
        if(result.status === 200 || result.status === 201){
            result?.data?.map((childTask:any)=>{
                if(childTask.status === "completed" || childTask.status === "active"){
                    progressingTasks.push(childTask.taskId);
                }
            })
            let filteredTasks = (result?.data)?.filter((task: any) => (task?.wbs)?.split(".")?.length > 2 && parseInt(task?.taskId) > Math.max(...progressingTasks));
            // result?.data?.map((task: any) => {
            //     console.log(!tasks.includes(task))
            //     if(((task?.wbs)?.split(".")?.length > 2 && parseInt(task?.taskId) > Math.max(...progressingTasks))){
            //         if(!tasks.includes(task)){
            //             tasks.push(task)
            //         }
            //     }
            // });
            setTasks(filteredTasks);

            if (props?.task?.taskId !== undefined && props?.task?.taskId !== "" && props?.editView !== true) {
                var task = filteredTasks.filter((task:any) => task.taskId === props?.task?.taskId)[0];
                setSelectedTask(task);
                getSuccessorTask(task.taskId);
                getPredecessorTask(task.taskId);
            }else{
                if(props?.state !== "Gps"){
                    getSuccessorTask(props?.taskId);
                    getPredecessorTask(props?.taskId);
                }
            }
        }
    }


    const getSuccessorTask = async (taskId:any) => {
        let result = await getSuccessorTasks(props?.projectId, taskId, taskStatus, accessToken);
        let successorTasks = (result?.data).filter((task: any) => (task?.wbs).split(".").length > 2);
        setSuccessorTask(successorTasks);
    }

    const getPredecessorTask = async (taskId:any) => {
        let result = await getPredecessorTasks(props?.projectId,props?.state === "Gps" ? taskId - 1 : taskId, taskStatus, accessToken);
        let predecessorTasks = (result?.data).filter((task: any) => (task?.wbs).split(".").length > 2);
        setPredecessorTask(predecessorTasks);
    }

    // const getPoTask = () =>
    // {
    //     predecessorTask
    // }

    const getTaskTypes = async () => {
        let result = await getTaskTypeList(accessToken);
        setTaskTypes(result.data);
    }


    const shouldDisableTime: any = (value: any, view: any, row: any) => {
        if (row != undefined) {
            return (view === 'minutes' && value.minute() <= dayjs((row?.startTime)?.replace(/Z/g, '')).minute() && value.hour() === dayjs((row?.startTime)?.replace(/Z/g, '')).hour()
                && value.date() === dayjs((row?.startTime)?.replace(/Z/g, '')).date() && value.month() === dayjs((row?.startTime)?.replace(/Z/g, '')).month() && value.year() === dayjs((row?.startTime)?.replace(/Z/g, '')).year())
                || (value.hour() >= props?.workEndTime.hour() && value.minute() > props?.workEndTime.minute()) || value.hour() > props?.workEndTime.hour() || value.hour() < props?.workStartTime.hour()
        } else {
            return (view === 'minutes' && value.minute() < props?.workStartTime.minute()) || (value.hour() >= props?.workEndTime.hour() && value.minute() > props?.workEndTime.minute()) || value.hour() > props?.workEndTime.hour() || value.hour() < props?.workStartTime.hour()
        }
    };

    const shouldDisableStartTime: any = (value: any, view: any, row: any) => {
        return (view === 'minutes' && value.minute() < props?.workStartTime.minute()) || (value.hour() >= props?.workEndTime.hour() && value.minute() > props?.workEndTime.minute()) || value.hour() > props?.workEndTime.hour() || value.hour() < props?.workStartTime.hour()
    }


    const shouldDisableDates: any = (value: any, view: any, row: any) => {
        return !props.workDays.includes(value.day());
    };


    React.useEffect(() => {
        getTaskListByProjectIdAndTaskId();

        getTaskTypes();

    }, []);

    return (
        <>
            <Box>
                <Grid container spacing={2}>
                    {props?.state === "Gps" ?
                        <Grid item xs={6}>
                            <FormControl fullWidth size='small'>
                                <InputLabel id="demo-simple-select-label" error={props?.task?.taskId === undefined && props?.count !== 0}>Task Id</InputLabel>
                                <Select

                                    name="taskId"
                                    value={selectedTask === undefined ? '' : selectedTask}
                                    error={props?.task?.taskId === undefined && props?.count !== 0}
                                    label="Task Id"
                                    onChange={(e) => handleChange(e)}
                                    MenuProps={{ PaperProps: { sx: { maxHeight: 100 } } }}
                                >
                                    {tasks?.map((task:any) => 
                                            <MenuItem value={task}>{task?.taskId}</MenuItem>
                                        )}
                                </Select>
                            </FormControl>
                        </Grid>
                        :
                        <></>
                    }
                    <Grid item xs={props?.state === "Gps" ? 6 : 12}>
                        <TextField
                            id="demo-helper-text-aligned"
                            label="Task Name"
                            name="taskName"
                            size="small"
                            value={props?.task?.taskName}
                            error={(props?.task?.taskName === '' && props?.count !== 0)}
                            fullWidth
                            onChange={(e) => handleChange(e)}
                            required
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            id="demo-helper-text-aligned"
                            label="Duration"
                            name="estimatedHours"
                            size="small"
                            fullWidth
                            value={props?.task?.estimatedHours}
                            error={(props?.task?.estimatedHours === '' && props?.count !== 0)}
                            disabled={(props?.task?.phase === "sales" ? true : false || props?.state === "Gps" || props?.state === "Project Information")}
                            type="number"
                            onChange={(e) => handleChange(e)}
                            required
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth size='small'>
                            <InputLabel id="demo-simple-select-label" error={(props?.task?.taskType === '' && props?.count !== 0)} required>Task Type</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                name="taskType"
                                value={props?.task?.taskType}
                                error={(props?.task?.taskType === '' && props?.count !== 0)}
                                label="Task Type"
                                onChange={(e) => handleChange(e)}
                                MenuProps={MenuProps}
                                required
                            >
                                {taskTypes?.map((type: any, index: any) => {
                                    return (
                                        <MenuItem key={index} value={type}>{(type)?.split(/(?=[A-Z])/).join(" ").replace(/^./, type[0]?.toUpperCase())}</MenuItem>
                                    )
                                })}


                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth size='small'>
                            <InputLabel id="demo-simple-select-label"
                            // error={props?.task?.dependencies.length  === 0 && props?.count !== 0}
                            >Set Predecessor Task</InputLabel>
                            <Select
                                labelId="demo-multiple-checkbox-label"
                                id="demo-multiple-checkbox"
                                multiple
                                value={dependencies}
                                label="Set Predecessor Task"
                                name="dependencies"
                                disabled={predecessorTask.length === 0}
                                // error={props?.task?.dependencies.length  === 0 && props?.count !== 0}
                                onChange={(e) => handleChange(e)}
                                input={<OutlinedInput label="Set Predecessor Task" />}
                                renderValue={(selected) => selected.map(item => (typeof item === 'object' && item.taskId ? item.taskId : item))  // Extract taskId if it's an object
                                    .join(', ')}
                                // MenuProps={{ PaperProps: { sx: { maxHeight: 100 } } }}
                                MenuProps={MenuProps}
                            >
                                {predecessorTask?.map((task: any, index: any) => {
                                    return (
                                        <MenuItem key={index} value={task}>{task?.taskName}</MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth size='small'>
                            <InputLabel id="demo-simple-select-label"
                            // error={props?.task?.otherDependencies.length  === 0 && props?.count !== 0}
                            >Set Successor Task</InputLabel>
                            <Select
                                labelId="demo-multiple-checkbox-label"
                                id="demo-multiple-checkbox"
                                multiple
                                value={props?.task?.otherDependencies}
                                label="Set Successor Task"
                                name="otherDependencies"
                                disabled={successorTask.length === 0}
                                // error={props?.task?.otherDependencies.length  === 0 && props?.count !== 0}
                                onChange={(e) => handleChange(e)}
                                input={<OutlinedInput label="Set Successor Task" />}
                                renderValue={(selected) => selected.join(",")}
                                // MenuProps={{ PaperProps: { sx: { maxHeight: 100 } } }}
                                MenuProps={MenuProps}
                            >
                                {successorTask?.map((task: any, index: any) => {
                                    return (
                                        <MenuItem key={index} value={task?.taskId}>{task?.taskName}</MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    {(props?.state === "Gps" || props?.state === "Project Information") ?
                        <>
                            <Grid item xs={6}>
                                <FormControl fullWidth size='small'>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DateTimePicker
                                            // format='DD MMM YYYY'
                                            label="Task Start Time"
                                            slotProps={{
                                                textField: {
                                                  fullWidth: true,
                                                  size: "small",
                                                  error: (count !== 0 || props?.count !== 0)  && (
                                                    props.task?.startTime === '' ||
                                                    startTimeHour < props?.workStartTime?.hour() || 
                                                    startTimeHour >= props?.workEndTime?.hour()
                                                  ),
                                                  helperText: (count !== 0 || props?.count !== 0) && (
                                                    startTimeHour < props?.workStartTime?.hour() || startTimeHour >= props?.workEndTime?.hour()
                                                  ) ? "Selected time is outside working hours" : ""
                                                }
                                              }}
                                            disablePast
                                            name="startTime"
                                            closeOnSelect={false}
                                            //onClose={(e)=>console.log}
                                            //disabled={ids.includes(index) ? true : false}
                                            shouldDisableDate={shouldDisableDates}
                                            shouldDisableTime={(value, view) => shouldDisableStartTime(value, view, props?.task?.startTime)}
                                            value={props?.task?.startTime === "" ? null : dayjs((props?.task?.startTime)?.replace(/Z/g, ''))}
                                            onChange={(e) => onDateChange(e, 'startTime')}
                                        />
                                    </LocalizationProvider>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl fullWidth size='small'>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DateTimePicker
                                            // format='DD MMM YYYY'
                                            label="Task End Time"
                                            // slotProps={{
                                            //     textField: {
                                            //         fullWidth: true, size: "small",
                                            //         error: (props.task?.endTime === '' && props.task?.startTime >= props.task?.endTime && props?.count !== 0)
                                            //     }
                                            // }}
                                            slotProps={{
                                                textField: {
                                                  fullWidth: true,
                                                  size: "small",
                                                  error: (count !== 0 || props?.count !== 0) && (
                                                    !props?.task?.endTime ||
                                                    endTimeHour < props?.workStartTime?.hour() || 
                                                    endTimeHour >= props?.workEndTime?.hour()
                                                  ),
                                                  helperText: (count !== 0 || props?.count !== 0) && (
                                                    endTimeHour < props?.workStartTime?.hour() || endTimeHour >= props?.workEndTime?.hour()
                                                  ) ? "Selected time is outside working hours" : ""
                                                }
                                              }}
                                            minDateTime={dayjs((props?.task?.startTime)?.replace(/Z/g, ''))}
                                            value={props?.task?.endTime === "" ? null : dayjs((props?.task?.endTime)?.replace(/Z/g, ''))}

                                            shouldDisableDate={shouldDisableDates}
                                            shouldDisableTime={(value, view) => shouldDisableTime(value, view, props.task)}
                                            disablePast
                                            disabled={show}
                                            name="endTime"
                                            // disabled={show}
                                            closeOnSelect={false}
                                            // onClose={getAssignees}
                                            // value={dayjs((taskData?.startTime)?.replace(/Z/g, ''))}
                                            onChange={(e) => onDateChange(e, 'endTime')} />
                                    </LocalizationProvider>
                                </FormControl>
                            </Grid>
                        </>
                        :
                        <></>
                    }
                    <>
                        {props?.task?.taskType === "Install Task" || props?.task?.taskType === "Remove Task" ?
                            <>
                                <Grid item xs={6}>
                                    <TextField
                                        id="demo-helper-text-aligned"
                                        label="Enter a Value"
                                        name="value"
                                        size="small"
                                        fullWidth
                                        value={props?.task?.value}
                                        error={(props?.task?.value === '' && props?.count !== 0)}
                                        // disabled={(props?.task?.phase === "sales" ? true : false)}
                                        type="number"
                                        onChange={(e) => handleChange(e)}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl fullWidth size='small'>
                                        <InputLabel id="demo-simple-select-label">Unit</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            name="unit"
                                            value={props?.task?.unit}
                                            error={(props?.task?.unit === '' && props?.count !== 0)}
                                            label="Unit"
                                            onChange={(e) => handleChange(e)}
                                            MenuProps={MenuProps}
                                            required
                                        >
                                            {units?.map((type: any, index: any) => {
                                                return (
                                                    <MenuItem key={index} value={type}>{type}</MenuItem>
                                                )
                                            })}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </>
                            :
                            <></>
                        }
                    </>
                    {props?.task?.taskType === "Install Task" || props?.task?.taskType === "Remove Task" || props?.task?.taskType === "Verify Task" ?
                        <>
                            <Grid item xs={12}>
                                <TextField
                                    id="demo-helper-text-aligned"
                                    label="Question"
                                    name="value"
                                    size="small"
                                    fullWidth
                                    // value={JSON.parse(props?.task?.queries)?.labour}
                                    value={props?.task?.taskType !== "Verify Task" ? ((props?.task?.queries)?.labour) : ((props?.task?.queries)?.foreman)}
                                    // value={(props?.task?.queries).labour}
                                    // error={(props?.task?.estimatedHours === '' && props?.count !== 0)}
                                    // disabled={(props?.task?.phase === "sales" ? true : false)}
                                    // type="number"
                                    // onChange={(e) => handleChange(e)}
                                    disabled
                                    required
                                />
                            </Grid>
                        </>
                        :
                        <></>
                    }
                </Grid>
            </Box>
        </>
    );
}

export default TaskForm;