/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 20-Nov-2023
* @author      : Priyankaa.S
* @since       : 0.0.1
* Requirement# :
* Purpose      : Listing of Bid added to Cart
* -----------------------------------------------------------------------------
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem |    DATE    |   AUTHOR    |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
*  1175          | 27-03-2024 | shiyamkumar |  Salesperson appoinment Calendar Blocked dates Issue Fixed
*
*
*/
import * as React from 'react';
import {
    DialogContent, DialogActions, Button,
    DialogTitle, Dialog, Box, Typography, DialogContentText
} from '@mui/material';
import "../cart/CartDialog.scss"
import dayjs, { Dayjs } from 'dayjs';
import { DatePicker, DateTimePicker, PickersDay, PickersDayProps, TimePickerProps } from "@mui/x-date-pickers";
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { isSameDay } from "date-fns";
import { useOidcAccessToken } from '@axa-fr/react-oidc';
import { useContext, useEffect, useRef, useState } from 'react';
import { getdisabledDatesByStartDateAndResourceType } from '../../services/ProjectService';
import SalesBookingCheckout from '../salesBookingConfirmation/SalesBookingCheckout';
import './SalesmanCalendar.scss'
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import { UserContext, getUserSession } from '../../hooks/UserSession';
import { getConfiguredDataByKey, getPreconstructionPeriod } from '../../services/TradeConfigurationService';
import { weekdays } from '../../utils/Dateutils';
import utc from 'dayjs/plugin/utc'; // Import UTC plugin
import timezone from 'dayjs/plugin/timezone'; // Import timezone plugin


// Extend dayjs with plugins
dayjs.extend(utc);
dayjs.extend(timezone);
const SalesmanCalendar: React.FC<any> = (props) => {

    const { accessToken, accessTokenPayload } = useOidcAccessToken();
    const [selectedDate, setSelectedDate] = useState<any>(
        props?.props?.startDate 
          ? dayjs(props.props.startDate).local() 
          : props?.props?.salesStartDate 
            ? dayjs(props.props.salesStartDate).local() 
            : null
      );
      
          const [showAnotherComponent, setShowAnotherComponent] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const componentData = useRef<any>();
    const [user, setUser] = React.useState<any>();

    const { userSessionDetails, setUserDetails } = useContext(UserContext);

    const startDate = dayjs().add(1, 'day'); // Initialize startDate as the current date plus one day
    // const maxRecommendedStartDate = dayjs(props.props?.maxRecomendedStartdate);
    const lastWeekDate = new Date(new Date(props.props?.startDate).getTime() - 7 * 24 * 60 * 60 * 1000);
    const maxRecommendedStartDate = dayjs(dayjs(new Date()).format('YYYY-MM-DD') + "T00:00:00.000Z");
    const jobStartdate = dayjs(lastWeekDate);
    const diffTime = Math.abs(maxRecommendedStartDate.diff(jobStartdate));
    const diffDays = Math.ceil(diffTime / (24 * 60 * 60 * 1000));
    const formattedDates: Dayjs[] = [];
    const [disabledDates, setDisabledDates] = useState<any>([]);
    const blockedDates = useRef<any>({ disableDates: [] });
    const [isLoading, setIsLoading] = useState(true);
    const [preconstructionPeriod, setPerConstructinPeriod] = React.useState<any>();
    const [workingDays, setWorkingDays] = React.useState<any>([]);
    const [startTime, setStartTime] = React.useState<any>();
    const [endTime, setEndTime] = React.useState<any>();

    for (let i = 0; i <= diffDays; i++) {
        const newDate = startDate.add(i, 'day');
        formattedDates.push(newDate);
    }
    const dateValidator = (date: Date) => {
        if (new Date(date).setHours(0, 0, 0, 0) <= new Date().setHours(0, 0, 0, 0)) {
            const nextdate = new Date(new Date(date).setDate(new Date(date).getDate() + 1));
            if (nextdate.getDay() === 0) {
                return new Date(new Date(date).setDate(new Date(date).getDate() + 1));
            }
            if (nextdate.getDay() === 6) {
                return new Date(new Date(date).setDate(new Date(date).getDate() + 2));
            }
            else {
                return nextdate;
            }
        }
        else {
            if (date.getDay() === 0) {
                return new Date(new Date(date).setDate(new Date(date).getDate() + 1));
            }
            if (date.getDay() === 6) {
                return new Date(new Date(date).setDate(new Date(date).getDate() + 2));
            }
            else {
                return date;
            }
        }
    }

    let exeactDate: any;

    const findNextAvailableDate = (date: any, disabledDates: any) => {
        // console.log(disabledDates);
        if (disabledDates.includes(dayjs(date).format("YYYY-MM-DD"))) {
            findNextAvailableDate(dateValidator(new Date(new Date(date).setDate(new Date(date).getDate() + 1))), disabledDates);
        } else {
            exeactDate = date;
        }
        return exeactDate;
    }

    const startDateValidator = (date: Date) => {
        console.log(date);
        let nextavailabledate: any;
        let availableDate = new Date(new Date().setDate(new Date().getDate() + 1));
        if (new Date(date).setHours(0, 0, 0, 0) <= new Date().setHours(0, 0, 0, 0)) {
            return dateValidator(findNextAvailableDate(availableDate, blockedDates.current.disableDates));
        } else {
            return dateValidator(findNextAvailableDate(date, blockedDates.current.disableDates));
        }
    }

    const isDateDisablesAvailable = (date: Dayjs) => {
        const formattedDate = date.format("YYYY-MM-DD");

        // Check if the date is in the disabledDates array
        if (disabledDates.includes(formattedDate)) {
            return true;
        }
        // Check if the date is a Saturday (6) or Sunday (0)
        return date.day() === 6 || date.day() === 0;
    };
    const filteredDates: Dayjs[] = formattedDates.filter(date => !isDateDisablesAvailable(date));

    const highlightedDays: Array<Dayjs> = filteredDates

    const CustomDay: React.FC<PickersDayProps<Dayjs>> = (props) => {
        // Check if the current day matches any highlighted day
        const isMatched = highlightedDays.some(v => isSameDay(props.day.toDate(), v.toDate()) && !props.disabled);
        // console.log(isMatched)
        const selectedDate = dayjs(componentData.current.startDate);
        // Define custom styles for matched days
        const matchedStyles = isMatched
            ? {
                backgroundColor: 'green',
                borderTopRightRadius: '0%',
                borderBottomRightRadius: '0%',
                borderTopLeftRadius: '0%',
                borderBottomLeftRadius: '0%',
                margin: '0',
                color: 'white',
                width: '13% !important',
                "&:hover": {
                    background: "#47a947"
                }
            }
            : {}; // For non-matched days, leave styles empty (default)
        const selectedDateDetails = selectedDate
            ? {
                backgroundColor: 'orange',
                borderTopRightRadius: '0%',
                borderBottomRightRadius: '0%',
                borderTopLeftRadius: '0%',
                borderBottomLeftRadius: '0%',
                margin: '0',
                color: 'white',
                width: '13% !important',
                "&:hover": {
                    background: "#47a947"
                }
            }
            : {};
        // Render the day with the appropriate styles
        return <PickersDay {...props} sx={{ ...matchedStyles }} />;
    };


    const handleDateChange = (newDate: any) => {

        if (newDate) {
            setSelectedDate(new Date(newDate));
            // setShowAnotherComponent(true);
            // props['selectedDate'] = new Date(newDate);
            props.function(dayjs(newDate.$d).format('YYYY-MM-DDTHH:mm[Z]'));
            const isHighlighted = filteredDates.some((highlightedDate) =>
                isSameDay(newDate.toDate(), highlightedDate.toDate())
            );

            if (!isHighlighted) {
                setShowAlert(true);
                setShowAnotherComponent(false);
            }
        }
    };

    const handleCloseAlert = () => {
        setShowAlert(false);
        setShowAnotherComponent(true);
    };
    const isDateDisabled = (date: Dayjs) => {
        const today = dayjs();  // Get the current date
        // Disable dates up to and including today

        if (date.isSame(date.startOf('month'))) {
            handleMonthChange(date)
        }
        if (date.isBefore(today, 'day') || date.isSame(today, 'day') || date.isAfter(dayjs(props.props?.startDate).subtract(1, 'week'), 'day')) {
            return true;
        }
        // Additional disabling logic (if needed)
        if (blockedDates.current.disableDates.includes(date.format("YYYY-MM-DD"))) {
            return true;
        }
        return date.day() === 6 || date.day() === 0;
    };


    const handleMonthChange = async (newMonth) => {
        // Automatically select the first day of the new month
        const firstDayOfNextMonth = newMonth.startOf('month');
        const formattedDate = firstDayOfNextMonth.format('YYYY-MM-DDTHH:mm[Z]');
        const result = await getdisabledDatesByStartDateAndResourceType(formattedDate, componentData.current?.resourceType, accessToken)
        if (result.status === 200) {
            if (result.status === 200) {
                var dates = blockedDates.current.disableDates;
                if (result.data.length !== 0 && result.data !== '') {
                    result.data.map(dateString => {
                        if (!blockedDates.current.disableDates.includes(dayjs(dateString).format("YYYY-MM-DD"))) {
                            blockedDates.current.disableDates.push(dayjs(dateString).format("YYYY-MM-DD"))
                        }
                    })
                    setDisabledDates(dates)
                }
            }
        }
    };

    const workSchedule = async () => {
        const result = await getConfiguredDataByKey("workingHours", accessToken);
        if (result.data !== undefined && (result.status === 200 || result.status === 201)) {
            var object = JSON.parse(result.data);
            setStartTime(dayjs(object.startTime, 'HH:mm'));
            setEndTime(dayjs(object.endTime, 'HH:mm'));
        }
        const response = await getConfiguredDataByKey("workingDays", accessToken);
        if (response.data !== undefined && (response.status === 200 || response.status === 201)) {
            let tenantWokringDays = response.data.split(",");
            weekdays.map((day: any) => {
                if (tenantWokringDays.includes(day)) {
                    workingDays.push(weekdays.indexOf(day));
                }
            })
            //setWorkingDays(response.data.split(",").map((num:any) => parseInt(num)));
        }
    }

    useEffect(() => {
        setUser(userSessionDetails);
        if (props.props !== undefined) {
            componentData.current = {
                startDate: props?.props?.startDate,
                resourceType: "sales",

            };
            const fetchData = async () => {
                try {
                    const result = await getdisabledDatesByStartDateAndResourceType(dayjs(props?.props?.startDate).add(1, "day").startOf('month').format('YYYY-MM-DDTHH:mm[Z]'), componentData.current.resourceType, accessToken);
                    if (result.status === 200) {
                        if (result.status === 200) {
                            var dates = result.data.length !== 0
                                ? result.data.map(dateString => dayjs(dateString).format("YYYY-MM-DD"))
                                : []
                            setDisabledDates(dates)
                            blockedDates.current.disableDates = dates
                            setSelectedDate(startDateValidator(new Date(props?.props?.salesStartDate)));
                        }
                    }
                } catch (error) {
                    //console.error(error);
                }
                finally {
                    setIsLoading(false);
                }
            };
            fetchData();
            getPreConstructionPeriod();
            workSchedule();
        }
    }, [props.props?.startDate]);

    const onClose = () => {
        // setSelectedDate(startDateValidator(new Date(props?.props?.startDate)));
        setShowAnotherComponent(false)
        props.handleClose();
    }

    const closeDateConfirmation = () => {
        // setSelectedDate(startDateValidator(new Date(props?.props?.startDate)));
        setShowAnotherComponent(false);
    }

    const closeAlter = () => {
        // setSelectedDate(startDateValidator(new Date(props?.props?.startDate)));
        setShowAlert(false);
    }

    const getPreConstructionPeriod = async () => {
        const result = await getPreconstructionPeriod("preconstructionPeriod", accessToken);
        setPerConstructinPeriod(result?.data);
    }

    // get disabled date from active construction start date
    const getDisabledDates = (date: Dayjs) => {
        const today = dayjs();  // Get the current date
        const selectedDate = props?.props?.jobStartDate === undefined ? dayjs(props?.props?.startDate) : dayjs(props?.props?.jobStartDate);
        let period = preconstructionPeriod;
        const salesStartDate = props.props?.salesStartDate ? dayjs(props.props?.salesStartDate) : props.props?.startDate;

        const blockDates: any = [];
        for (let i = 0; i < period; i++) {
            let d = dayjs(selectedDate.subtract(i, 'day'));
            if (d.day() === 0 || d.day() === 6) {
                period++
            }
            else {
                blockDates.push(d.format("YYYY-MM-DD"));
            }
        }
        if (blockDates.includes(date.format("YYYY-MM-DD")) || date.isAfter(selectedDate, 'day')) {
            return true;
        }
        if (date.isSame(date.startOf('month'))) {
            handleMonthChange(date)
        }
        if (!workingDays.includes(date.day())) {
            return true;
        }
        // Additional disabling logic (if needed)
        if (blockedDates.current.disableDates.includes(date.format("YYYY-MM-DD"))) {
            return true;
        }
        // Ensure the salesStartDate is never disabled
        if (salesStartDate && date.isSame(salesStartDate, 'day')) {
            return false; // Do not disable this date
        }
        return date.day() === 6 || date.day() === 0;
    };


    // Disables the hours between 12 AM and 3 PM.
    const shouldDisableTime: any = (value: any, view: any, row: any) => {
        return (view === 'minutes' && value.minute() < startTime.minute()) || (value.hour() >= endTime.hour() && value.minute() > endTime.minute()) || value.hour() > endTime.hour() || value.hour() < startTime.hour()
    }

   
    if (isLoading) {
        return <div></div>;
    }

    return (
        <>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                    slots={{ day: CustomDay }}//date reange
                    disablePast
                    shouldDisableDate={getDisabledDates}//disable properties     
                    disableHighlightToday
                    disabled={!(props?.props?.salesStartDate || selectedDate || props?.props?.jobStartDate) && props?.isDisabled}
                    shouldDisableTime={shouldDisableTime}
                    slotProps={{
                        textField: { size: "small" },
                        // popper: {
                        //     // sx: {
                        //     //     ...{ '& .MuiPickersDay-root:not(.Mui-disabled)': { backgroundColor: 'green' } }
                        //     // }
                        // }
                    }}
                    closeOnSelect={false}
                    // value={props?.props?.selectedDate !== undefined ? dayjs(props.props?.selectedDate) : selectedDate !== null ? dayjs(startDateValidator(new Date(selectedDate))) : null}
                    value={
                        props.props?.salesStartDate
                            ? dayjs(props.props.salesStartDate.replace("Z", "")) // Remove 'Z' to treat as local time
                            : selectedDate
                                ? dayjs(startDateValidator(dayjs(selectedDate).toDate())).local() // Convert to Date and apply startDateValidator
                                : null
                    }
                    
                    
                    
                    onChange={handleDateChange}
                    onMonthChange={handleMonthChange}
                />
            </LocalizationProvider>

            <Dialog open={showAlert}>
                <DialogTitle className='warning'>
                    <Box paddingLeft={8} paddingRight={8}>
                        <Typography textAlign={'center'}>
                            <WarningAmberRoundedIcon sx={{ fontSize: "50px", color: "white" }} /></Typography>
                        <Typography textAlign={'center'} color='white' fontSize={'20px'} fontWeight={'400'} >
                            Warning
                        </Typography>
                    </Box>
                </DialogTitle>
                <Box paddingLeft={8} paddingRight={8}>
                    <DialogContent style={{ marginTop: "10px" }}>
                        <DialogContentText sx={{ fontWeight: "500", fontSize: "18px" }}>
                            Selected date <span className='warningDate'>{dayjs(selectedDate).format("DD MMM YYYY")}</span> will change your actual construction date .
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions style={{ justifyContent: "center" }}>
                        <Button onClick={() => closeAlter()} variant='contained' sx={{ backgroundColor: "#df3333ed" }}>Close</Button>
                        <Button onClick={handleCloseAlert} variant='contained' color="primary">
                            Ok
                        </Button>
                    </DialogActions>
                </Box>
            </Dialog>
            {showAnotherComponent && (
                <SalesBookingCheckout open={showAnotherComponent} bid={props.props} bidData={props.bidData} user={user} selectedDate={dayjs(selectedDate).format("DD MMM YYYY")} onClose={() => closeDateConfirmation()} />
            )}
        </>
    );
}


export default SalesmanCalendar;





