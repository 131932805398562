import { TabContext, TabPanel } from '@mui/lab';
import { Button, FormControl, Grid, InputLabel, MenuItem, Select, Tab, Tabs } from '@mui/material';
import { Box } from '@mui/system';
import React, { useContext, useEffect, useRef, useState } from 'react'
import QuestionCard from './QuestionCard';
import { ItemDef, CodeList, EventDef, FormDef, ItemGroupDef, Protocol, TemplateVersion, TranslatedText, GlobalVariables,  SDM, ConditionDef, CodeListItem, ItemRef, Description, BasicDefinitions, Building } from '../../../interface/SdmInterfaces';
import { getTradeNames } from '../../../services/TradeConfigurationService';
import { useOidcAccessToken } from '@axa-fr/react-oidc';
import { createQuestionTemplate, getTemplatesByTradeName } from '../../../services/TradeTemplateService';
import { UserContext } from '../../../hooks/UserSession';
import CustomDialog from '../../Dialog';
import { getTradeDetails } from '../../../services/BidService';
import { set } from 'date-fns';

interface QuestionProps {
  // Define your prop types here
  templateQuestionData?: any; // Update with the correct type
  questionType?: any;
  tradeTypeName?: any;


}
const Questions: React.FC<QuestionProps> = ({ templateQuestionData, questionType, tradeTypeName }) => {

  const defaultItemDef: ItemDef[] = [{
    Question: { TranslatedText: { lang: "en", text: "" } },
    OID: '',
    Name: '',
    DataType: ''
  }];

  const newFormDefs: FormDef[] = [
    {
      Description: {
        TranslatedText: {
          lang: "en",
          text: "Trade Questions"
        }
      },
      ItemGroupRef: [

      ],
      OID: "F.1",
      Name: "F.1",
      Repeating: "No"
    },
    // {
    //   Description: {
    //     TranslatedText: {
    //       lang: "en",
    //       text: "Optional Questions"
    //     }
    //   },
    //   ItemGroupRef: [], // Add an empty array to satisfy the type
    //   OID: "F.2",
    //   Name: "F.2",
    //   Repeating: "No"
    // }
  ];

  const newAssetFormDefs: FormDef[] = [

    {
      Description: {
        TranslatedText: {
          lang: "en",
          text: "Asset"
        }
      },
      ItemGroupRef: [], // Add an empty array to satisfy the type
      OID: "F.1",
      Name: "F.1",
      Repeating: "No"
    }
  ];
  const newConditionalDefs: ConditionDef[] = [
    {
      Description: {
        TranslatedText: {
          lang: "en",
          text: ""
        }
      },
      FormalExpression: {
        Context: "",
        text: ""
      },
      OID: "",
      Name: ""
    }
  ]
  // Default Description object
  const defaultDescription: Description = {
    TranslatedText: { lang: "", text: "" }
  };

  // Default ItemRef object
  const defaultItemRef: ItemRef = {
    ItemOID: "",
    Mandatory: "No"
  };

  // Default ItemGroupDef object
  const defaultItemGroupDef: ItemGroupDef[] = [{
    Description: defaultDescription,
    ItemRef: [defaultItemRef],
    OID: '',
    Name: '',
    Repeating: 'No'
  }];

  // Default CodeListItem object
  const defaultCodeListItem: CodeListItem = {
    Decode: { TranslatedText: { lang: "", text: "" } },
    CodedValue: "",
    IsEnabled: false
  };

  // Default CodeList array with one default CodeList object
  const defaultCodeList: CodeList[] = [
    {
      CodeListItem: [defaultCodeListItem],
      OID: '',
      Name: '',
      DataType: ''
    }
  ];
  const basicDefinitions: BasicDefinitions = {
    MeasurementUnit: [
      {
        Name: "MU.1",
        OID: "MU.1",
        Symbol: {
          TranslatedText: {
            lang: "en",
            text: "%"
          }
        }
      },
      {
        Name: "MU.2",
        OID: "MU.2",
        Symbol: {
          TranslatedText: {
            lang: "en",
            text: "kg"
          }
        }
      },
      {
        Name: "MU.3",
        OID: "MU.3",
        Symbol: {
          TranslatedText: {
            lang: "en",
            text: "mL"
          }
        }
      },
      {
        Name: "MU.5",
        OID: "MU.5",
        Symbol: {
          TranslatedText: {
            lang: "en",
            text: "cm<sup>3</sup>"
          }
        }
      },
      {
        Name: "MU.6",
        OID: "MU.6",
        Symbol: {
          TranslatedText: {
            lang: "en",
            text: "s"
          }
        }
      },
      {
        Name: "MU.7",
        OID: "MU.7",
        Symbol: {
          TranslatedText: {
            lang: "en",
            text: "(24HR)"
          }
        }
      },
      {
        Name: "MU.8",
        OID: "MU.8",
        Symbol: {
          TranslatedText: {
            lang: "en",
            text: "(m)"
          }
        }
      },
      {
        Name: "MU.10",
        OID: "MU.10",
        Symbol: {
          TranslatedText: {
            lang: "en",
            text: "(mGy)"
          }
        }
      },
      {
        Name: "MU.12",
        OID: "MU.12",
        Symbol: {
          TranslatedText: {
            lang: "en",
            text: "(°C)"
          }
        }
      },
      {
        Name: "MU.4",
        OID: "MU.4",
        Symbol: {
          TranslatedText: {
            lang: "en",
            text: "Equipment"
          }
        }
      },
      {
        Name: "MU.13",
        OID: "MU.13",
        Symbol: {
          TranslatedText: {
            lang: "en",
            text: "KG 0 LB"
          }
        }
      },
      {
        Name: "MU.15",
        OID: "MU.15",
        Symbol: {
          TranslatedText:
          {
            lang: "en",
            text: "kg"
          },

        }
      },
      {
        Name: "MU.16",
        OID: "MU.16",
        Symbol: {
          TranslatedText: {
            lang: "en",
            text: "% of surface"
          }
        }
      },
      {
        Name: "MU.19",
        OID: "MU.19",
        Symbol: {
          TranslatedText: {
            lang: "en",
            text: "Return of Conduction"
          }
        }
      },
      {
        Name: "MU.20",
        OID: "MU.20",
        Symbol: {
          TranslatedText: {
            lang: "en",
            text: "in"
          }
        }
      },
      {
        Name: "MU.21",
        OID: "MU.21",
        Symbol: {
          TranslatedText: {
            lang: "en",
            text: "lb"
          }
        }
      },
      {
        Name: "MU.17",
        OID: "MU.17",
        Symbol: {
          TranslatedText: {
            lang: "en",
            text: "cm"
          }
        }
      },
      {
        Name: "MU.18",
        OID: "MU.18",
        Symbol: {
          TranslatedText: {
            lang: "en",
            text: "mg"
          }
        }
      },
      {
        Name: "MU.14",
        OID: "MU.14",
        Symbol: {
          TranslatedText: {
            lang: "en",
            text: "(mL)"
          }
        }
      },
      {
        Name: "MU.9",
        OID: "MU.9",
        Symbol: {
          TranslatedText: {
            lang: "en",
            text: "(mm:ss)"
          }
        }
      },
      {
        Name: "MU.22",
        OID: "MU.22",
        Symbol: {
          TranslatedText: {
            lang: "en",
            text: "mL/m<sup>2</sup>"
          }
        }
      },
      {
        Name: "MU.23",
        OID: "MU.23",
        Symbol: {
          TranslatedText: {
            lang: "en",
            text: "mcg"
          }
        }
      },
      {
        Name: "MU.24",
        OID: "MU.24",
        Symbol: {
          TranslatedText:
          {
            lang: "en",
            text: "in"
          }

        }
      },
      {
        Name: "MU.25",
        OID: "MU.25",
        Symbol: {
          TranslatedText:
          {
            lang: "en",
            text: "sq.ft"
          }

        }
      },
      {
        Name: "MU.26",
        OID: "MU.26",
        Symbol: {
          TranslatedText:
          {
            lang: "en",
            text: "PSF"
          }

        }
      },
      {
        Name: "MU.27",
        OID: "MU.27",
        Symbol: {
          TranslatedText:
          {
            lang: "en",
            text: "days"
          }

        }
      },
      {
        Name: "MU.28",
        OID: "MU.28",
        Symbol: {
          TranslatedText:
          {
            lang: "en",
            text: "$"
          }

        }
      },
      {
        Name: "MU.29",
        OID: "MU.29",
        Symbol: {
          TranslatedText:
          {
            lang: "en",
            text: "ft"
          }

        }
      },{
        Name: "MU.30",
        OID: "MU.30",
        Symbol: {
          TranslatedText:
          {
            lang: "en",
            text: "years"
          }
 
        }
      },{
        Name: "MU.31",
        OID: "MU.31",
        Symbol: {
          TranslatedText:
          {
            lang: "en",
            text: "LF"
          }
 
        }
      }
    ]

  }


  //////////console.log(templateQuestionData)
  const [jsonData, setJsonData] = useState<any>({});
  const { accessToken, accessTokenPayload } = useOidcAccessToken();
  const [formDef, setFormDef] = useState<FormDef[]>(questionType === 'asset' ? newAssetFormDefs : newFormDefs);
  const [keyQuestionFormDef, setKeyQuestionFormDef] = useState<any>({});
  const [optionalFormDef, setOptionalFormDef] = useState<any>({});
  const [ItemGroupDef, setItemGroupDef] = useState<ItemGroupDef[]>(defaultItemGroupDef);
  const [questions, setQuestions] = useState<ItemDef[]>(defaultItemDef);
  const [newEventData, setNewEventData] = useState<ItemDef[]>(defaultItemDef);
  const [oldTemplateVersion, setOldTemplateVersion] = useState<any>();
  const [incompleteQuestions, setIncompleteQuestions] = useState<string[]>([]);
  const [codeLists, setCodeLists] = useState<CodeList[]>(defaultCodeList);
  const [conditionalDef, setConditionalDef] = useState<any[]>(newConditionalDefs);

  const [value, setValue] = React.useState('1');
  const [tradeNames, setTradeNames] = useState<any>([]);
  const [selectedTradeItem, setSelectedTradeItem] = useState<any>('');
  const [createdBy, setCreatedBy] = useState('');
  const tradeType = useRef<any>('');
  const [error, setError] = React.useState(false);
  const [openDialogs, setOpenDialogs] = React.useState(false);
  const [createSuccess, setCreateSuccess] = React.useState(false);
  const [olItemGroupDefs, setOlItemGroupDefs] = useState<ItemGroupDef[]>([]);

  const [keyItemGroupDef, setKeyItemGroupDef] = useState<ItemGroupDef[]>([]);
  const [basicDefinition, setBasicDefinition] = useState<BasicDefinitions>(basicDefinitions);
  const [loading, setLoading] = useState<any>(false);
  const [oldConditionalDef, setOldConditionalDef] = useState<ConditionDef[]>(newConditionalDefs);
  const [oldItemDef, setOldItemDef] = useState<ItemDef[]>(defaultItemDef);
  const oldCodeList = useRef<any>();
  const [template, setTemplate] = useState<TemplateVersion[]>([]);
  const [codeOid, setCodeOid] = useState<Set<string>>(new Set())
  const [itemOid, setItemOid] = useState<Set<string>>(new Set())
  const [conditionOid, setConditionOid] = useState<Set<string>>(new Set())
  const [role, setRole] = React.useState<any>([] as any[]);
  const tradeOidRef = useRef<any>(''); // useRef instead of useState
  const ASSET="asset";
  const ASSET_OID='A.1';
  const QUESTION_STATUS = "publish";

  // Force re-render
  const { userSessionDetails, setUserDetails } = useContext(UserContext);




  React.useEffect(() => {
    setRole(userSessionDetails.role.name);
  }, [])

  // Force re-render
  const [forceRender, setForceRender] = useState(0);

  const triggerRerender = () => setForceRender(prev => prev + 1);


  const templateData = useRef<any>({});

  const questionTemplateRef = useRef({
    tradeOid: '',
    name: '',
    createdDate: '',
    createdBy: '',
    status: '',
    invalid: false,
    questionTemplateData: '',
    type: ''
  });

  // Initialize with a default eventDef
  useEffect(() => {
    // //console.log(templateQuestionData)
    const initializeData = async () => {
      handleTradeNames();
    };
    //console.logg(templateQuestionData)
    ////////console.log(templateQuestionData);
    const param = questionType ===ASSET ? questionType : templateQuestionData;
    //////console.log(param)
    handleTradeType(param);
    // Reset state to default values
    setQuestions([...defaultItemDef]);
    setCodeLists([...defaultCodeList]);
    setItemGroupDef([...defaultItemGroupDef]);
    if (questionType === ASSET) {
      setValue('3');

      setFormDef(newAssetFormDefs);
    } else {
      setFormDef(newFormDefs);
    }
    ////////console.log(formDef)
    setConditionalDef([...newConditionalDefs]);
    if (questionType === ASSET) {
      setValue('3');
      handleSelectTradeDAta(questionType);
      tradeOidRef.current = ASSET_OID;

    }
    else if (templateQuestionData !== undefined) {
      //console.log('hii')
      setSelectedTradeItem(templateQuestionData)
      ////////console.log(tradeNames.includes(selectedTradeItem));
      handleSelectTradeDAta(templateQuestionData);
      // }
    }
    else {
      Object.assign(questions, [...defaultItemDef]);
      Object.assign(codeLists, [...defaultCodeList]);
      Object.assign(ItemGroupDef, [...defaultItemGroupDef]);
      if (questionType === ASSET) {
        setValue('3');

        Object.assign(formDef, [...newAssetFormDefs]);
      }
      else {
        Object.assign(formDef, [...newFormDefs]);

      }
      Object.assign(conditionalDef, [...newConditionalDefs]);
      ////////console.log(ItemGroupDef, codeLists, conditionalDef, "questions", questions, "formDef", formDef)

      let data = { SDM: newSDM as SDM }
      Object.assign(jsonData, data)
      ////////console.log(ItemGroupDef, codeLists, conditionalDef, "questions", questions, "formDef", formDef)
    }

    if (questionType === ASSET) {
      setValue('3');

      handleSampleQuestions("Asset");
    }
    if (value === '1') {
      // Handle logic for "Trade Questions" tab
      // Assign trade name to global variable project name for "Trade Questions"
      handleSampleQuestions("Trade Questions")
    } else if (value === '2') {
      handleSampleQuestions("Optional Questions")
    }

    //////////console.log("userSessionDetails", userSessionDetails)
    // setCreatedBy(userSessionDetails.firstname + " " + userSessionDetails.lastname)
    initializeData();
  }, [templateQuestionData]);

  useEffect(() => {
    ////////console.log(ItemGroupDef, codeLists)
  }, [forceRender, selectedTradeItem]);
  useEffect(() => {
    ////////console.log(ItemGroupDef, codeLists)
  }, [oldCodeList.current, oldConditionalDef,oldItemDef,olItemGroupDefs]);

  const handleDialogClose = () => {
    setOpenDialogs(false);
  };


  const handleTradeType = async (tradeName: any) => {
    //////console.log('Trade Name:', tradeName);
    setCodeLists(defaultCodeList);
    try {
      const res = await getTradeDetails(tradeName, accessToken);

      //////console.log('Response:', res);
      tradeOidRef.current = res.data.tradeOid;  // Update ref directly

      if (res.data && (res.status === 200 || res.status === 201)) {
        tradeType.current = res.data.type;
        //////console.log('Trade Type:', tradeType.current);
        someFunctionThatRequiresTradeType();
        questionTemplateRef.current = {
          ...questionTemplateRef.current,
          type: res.data.type,
          
        };
      } else {
        questionTemplateRef.current = {
          ...questionTemplateRef.current,
          type: ASSET
        };
        tradeType.current = ASSET;
        tradeOidRef.current = ASSET_OID;  // Update ref directly

      }
    } catch (error) {
      // console.error('Error fetching trade details:', error);
      questionTemplateRef.current = {
        ...questionTemplateRef.current,
        type: ASSET
      };
      tradeType.current = ASSET;
      tradeOidRef.current = ASSET_OID;

    }

    // This log will be printed after the async operation completes
    //////console.log('Updated Trade Type:', tradeType.current);
  };
  useEffect(() => {
    //console.log("ItemGroupDef updated:", ItemGroupDef);
  }, [ItemGroupDef]);

  useEffect(() => {
    // console.log("Questions updated:", questions);
  }, [questions]);

  useEffect(() => {
    // console.log("CodeLists updated:", codeLists);
  }, [codeLists]);

  const handleSelectTradeDAta = async (type: string) => {
    templateData.current = null; // Clear any old data
    setOldTemplateVersion('')
    setLoading(true);

    // Reset to default state
    resetToDefaultState(questionType);

    try {
      const resData = await getTradeDetails(type, accessToken);
      if (resData.data && (resData.status === 200 || resData.status === 201)) {
        tradeType.current = resData.data.type;
        questionTemplateRef.current = {
          ...questionTemplateRef.current,
          type: resData.data.type
        };
        tradeOidRef.current = resData.data.tradeOid;  // Update ref directly

      } else {
        questionTemplateRef.current = { ...questionTemplateRef.current, type: ASSET };
        tradeOidRef.current = ASSET_OID;

      }

      const effectiveTradeType = questionType === ASSET ? questionType : tradeType.current;
      const templateRes = await getTemplatesByTradeName(tradeOidRef.current,QUESTION_STATUS, accessToken);
      if (templateRes.data && templateRes.ok === true && templateRes.data.questionTemplateData) {
        //console.log("API Response:", templateRes.data);

        templateData.current = JSON.parse(templateRes.data.questionTemplateData);
        const templateVersions = templateData.current.SDM.Building.TemplateVersion;
        const lastTemplateVersion = templateVersions.slice(-1)[0];

        setFormDef(lastTemplateVersion.FormDef);
        setItemGroupDef(lastTemplateVersion.ItemGroupDef);

        setOldTemplateVersion(templateData.current);

        const { itemDefs, codeLists, conditionDefs } = processDefinitions(templateVersions);
        setConditionalDef(conditionDefs);
        setCodeLists(codeLists);
        setQuestions(itemDefs);

      } else {
        // Log here to verify that the else block is running
        //console.log('hii - Resetting to default values');

        // Reset to default values
        setQuestions([...defaultItemDef]);
        setCodeLists([...defaultCodeList]);
        setItemGroupDef([...defaultItemGroupDef]);

        if (questionType === ASSET) {
          setFormDef([...newAssetFormDefs]);
        } else {
          setFormDef([...newFormDefs]);
        }

        setConditionalDef([...newConditionalDefs]);
      }
    } catch (error) {
      console.error("Error fetching trade data:", error);
      resetToDefaultState(questionType);
    } finally {
      setLoading(false);
    }

    // Log the final values after loading
    //console.log(ItemGroupDef, questions, codeLists, formDef);
  };


  const resetToDefaultState = (questionType: string) => {
    setQuestions([...defaultItemDef]);
    setCodeLists([...defaultCodeList]);
    setItemGroupDef([]); // Use setItemGroupDef directly to update the state

    if (questionType === ASSET) {
      setFormDef([...newAssetFormDefs]); // Use setFormDef if this is part of your state
    } else {
      setFormDef([...newFormDefs]);
    }

    setConditionalDef([...newConditionalDefs]);
  };

  const someFunctionThatRequiresTradeType = () => {
    //////console.log('Trade type outside:', tradeType.current);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const newEventDef: EventDef = {
    Description: {
      TranslatedText: {
        lang: 'en',
        text: 'Bid', // Default to empty string
      }
    },
    FormRef: [
      {
        FormOID: "F.1",
        Mandatory: "No"
      },
      // {
      //   FormOID: "F.2",
      //   Mandatory: "No"
      // }
    ],
    OID: 'E.1',
    Name: 'E.1',
    Repeating: "No",
    Type: "Common"
  };

  const newAssetEventDef: EventDef = {
    Description: {
      TranslatedText: {
        lang: 'en',
        text: 'Asset', // Default to empty string
      }
    },
    FormRef: [
      {
        FormOID: "F.1",
        Mandatory: "No"
      },

    ],
    OID: 'E.1',
    Name: 'E.1',
    Repeating: "No",
    Type: "Common"
  };



  const defaultProtocol: Protocol = {
    EventRef: [{
      EventOID: 'E.1',
      Mandatory: "No"
    }]
  };
  const globalVariables: GlobalVariables = {
    Name: questionType === ASSET ? questionType : selectedTradeItem,
    Description: '',
    ProtocolName: questionType === ASSET ? questionType : selectedTradeItem,

  };
  let newTemplateVersion: TemplateVersion = {
    Protocol: defaultProtocol,
    EventDef: [questionType !==ASSET ? newEventDef : newAssetEventDef],
    FormDef: formDef,
    ItemGroupDef: ItemGroupDef,
    ItemDef: questions,
    CodeList: codeLists,
    ConditionDef: conditionalDef,
    OID: "MDV.1",
    Name: "MetaDataVersion",
  };

  // Determine TemplateVersion to use
  //console.log(template, newTemplateVersion)

  // Use template if available and not empty; otherwise use [latestTemplateVersion]
  const templateVersionsToUse = template && template.length > 0 ? template || [] : [newTemplateVersion];
  //console.log(templateVersionsToUse);
  let projects: Building = {
    GlobalVariables: globalVariables,
    TemplateVersion: [newTemplateVersion],
    BasicDefinition: basicDefinitions,
    OID: questionType !== ASSET ? tradeOidRef.current : ASSET_OID
  }


  const newSDM: SDM = {

    Building: projects as Building,
    xmlns: "",
    FileType: "Snapshot",
    FileOID: "New Trade",
    CreationDateTime: "2024-06-24T10:48:11.355Z",
    ODMVersion: "1.3.2",
    SourceSystem: "",
    AdminData: {
      User: {
        FirstName: "New",
        LastName: "User",
        OID: "U.1"
      },
      OID: questionType !== ASSET ? tradeOidRef.current :ASSET_OID
    },
    BuildingData: {
      // AuditRecord: {
      //   UserRef: {
      //     UserOID: "U.1"
      //   },
      //   LocationRef: {
      //     LocationOID: "-"
      //   },
      //   DateTimeStamp: "2024-06-24T10:47:31.016Z"
      // },
      EventData: [{
        FormData: [{
          ItemGroupData: [
            {
              ItemData: [
              ],
              ItemGroupOID: "",
              ItemGroupRepeatKey: 0
            },
            {
              ItemData: [

              ],
              ItemGroupOID: "",
              ItemGroupRepeatKey: 0

            }
          ],
          FormOID: "F.3",
          TransactionType: "Insert"
        }],
        EventOID: "E.3"
      }],
      Key: "123",
      OID: questionType !== ASSET ? tradeOidRef.current :ASSET_OID,
      MetaDataVersionOID: "MDV.1"
    },
  };

  // Function to get all ItemOID values from ItemGroupDefs
  const getItemOIDsFromItemGroupDefs = (itemGroupDefs: ItemGroupDef[] | undefined): string[] => {
    const itemOIDs: string[] = [];

    // Check if itemGroupDefs is defined and is an array
    if (Array.isArray(itemGroupDefs)) {
      // Loop through each ItemGroupDef
      for (const itemGroupDef of itemGroupDefs) {
        // Loop through each ItemRef within the current ItemGroupDef
        for (const itemRef of itemGroupDef.ItemRef) {
          // Add the ItemOID to the array
          itemOIDs.push(itemRef.ItemOID);
        }
      }
    } else {
      // console.warn("itemGroupDefs is not an array or is undefined.");
    }

    return itemOIDs;
  };


  //to convert new date to date time  users format
  const dateTimeFormatter = (value: any) => {
    const date = new Date(value);
    const month = date.getMonth().toString();
    return date.getFullYear() + "-" + (month.length === 1 ? "0" + (date.getMonth() + 1).toString() : date.getMonth() + 1) + "-" + ((date.getDate()).toString().length === 1 ? "0" + (date.getDate()).toString() : date.getDate()) + "T00:00:00.000Z";
  };

  // Use newSDM in some meaningful way, e.g., setting it in state or passing it to another function

  // const handleData = () => {
  //   //////////console.log("sdm..", newSDM)

  //   let data = {
  //     SDM: newSDM as SDM
  //   }
  //   //////////console.log(data)
  //   Object.assign(jsonData, data);
  // }

  // const handlePropsChange = () => {
  //   ////////console.log(questions, ItemGroupDef, codeLists, formDef);
  //   setQuestions(questions);
  //   setItemGroupDef(ItemGroupDef);
  //   setCodeLists(codeLists);
  //   setFormDef(formDef);
  //   Object.assign(basicDefinition, basicDefinitions)
  //   handleData()


  // }

  const processItemDefDefinitions = (templateVersions: any | null) => {
    if (!templateVersions || templateVersions.length === 0) {
      // console.error('No TemplateVersion found');
      return {
        itemDefs: []
      };
    }

    let allItemDefs: ItemDef[] = [];
    let latestItemDefs: ItemDef[] = [];

    // Determine the index to check for the latest definitions
    const latestIndex = templateVersions.length - 1;
    //  //console.logg(templateVersions[templateVersions.length - 1])
    let fallbackIndex = latestIndex - 1;

    // Loop through each TemplateVersion
    for (let i = 0; i < templateVersions.length; i++) {
      const { ItemDef = [] } = templateVersions[i];

      // Collect all definitions

      allItemDefs = latestItemDefs.concat(ItemDef);
      // allItemGroupDefs=allItemGroupDefs.concat[ItemGroupDef];
      //console.logg(templateVersions[i].ItemGroupDef)
      // Track latest definitions
      if (i === latestIndex) {
        if (!ItemDef || ItemDef.length === 0) {
          // Use the second-to-last TemplateVersion as a fallback
          if (fallbackIndex >= 0) {
            const fallback = templateVersions[fallbackIndex];

            latestItemDefs = fallback.ItemDef || [];
          } else {
            // Fallback to empty arrays if there are not enough TemplateVersions       
            latestItemDefs = [];
          }
        } else {
          // Use the latest TemplateVersion's definitions
          latestItemDefs = ItemDef;
        }
      }
    }

    const finalItemDefs = latestItemDefs.length > 0
      ? [
        ...allItemDefs.filter(item => !latestItemDefs.some(latest => latest.OID === item.OID)),
        ...latestItemDefs
      ]
      : allItemDefs;

    // Log the final results for debugging
    //console.log(finalItemDefs, finalConditionDefs, finalCodeLists);

    // Update the state or perform other actions as needed

    setOldItemDef(finalItemDefs);
    //console.looldCodeList.current)

    return {

      itemDefs: finalItemDefs
    };
  }
  const processCodeListDefinitions = (templateVersions: any | null) => {
    if (!templateVersions || templateVersions.length === 0) {
      // console.error('No TemplateVersion found');
      return {
        codeLists: []
      };
    }

    let allCodeLists: CodeList[] = [];
    let latestCodeLists: CodeList[] = [];

    // Determine the index to check for the latest definitions
    const latestIndex = templateVersions.length - 1;
    //  //console.logg(templateVersions[templateVersions.length - 1])
    let fallbackIndex = latestIndex - 1;

    // Loop through each TemplateVersion
    for (let i = 0; i < templateVersions.length; i++) {
      const { ItemDef = [], CodeList = [] } = templateVersions[i];

      // Collect all definitions

      allCodeLists = allCodeLists.concat(CodeList);
      // allItemGroupDefs=allItemGroupDefs.concat[ItemGroupDef];
      //console.logg(templateVersions[i].ItemGroupDef)
      // Track latest definitions
      if (i === latestIndex) {
        if (!ItemDef || ItemDef.length === 0) {
          // Use the second-to-last TemplateVersion as a fallback
          if (fallbackIndex >= 0) {
            const fallback = templateVersions[fallbackIndex];

            latestCodeLists = fallback.CodeList || [];
          } else {
            // Fallback to empty arrays if there are not enough TemplateVersions       
            latestCodeLists = [];
          }
        } else {
          // Use the latest TemplateVersion's definitions
          latestCodeLists = CodeList;
        }
      }
    }

    const finalCodeLists = latestCodeLists.length > 0
      ? [
        ...allCodeLists.filter(item => !latestCodeLists.some(latest => latest.OID === item.OID)),
        ...latestCodeLists
      ]
      : allCodeLists;

    // Log the final results for debugging
    //console.log(finalItemDefs, finalConditionDefs, finalCodeLists);

    // Update the state or perform other actions as needed

    oldCodeList.current = finalCodeLists;
    //console.looldCodeList.current)

    return {

      codeLists: finalCodeLists
    };
  }
  const processConditionDefinitions = (templateVersions: any | null) => {
    if (!templateVersions || templateVersions.length === 0) {
      // console.error('No TemplateVersion found');
      return {
        coditionLists: []
      };
    }

    let allConditionLists: ConditionDef[] = [];
    let latestConditionLists: ConditionDef[] = [];

    // Determine the index to check for the latest definitions
    const latestIndex = templateVersions.length - 1;
    //  //console.logg(templateVersions[templateVersions.length - 1])
    let fallbackIndex = latestIndex - 1;

    // Loop through each TemplateVersion
    for (let i = 0; i < templateVersions.length; i++) {
      const { ItemDef = [], ConditionDef = [] } = templateVersions[i];

      // Collect all definitions

      allConditionLists = allConditionLists.concat(ConditionDef);
      // allItemGroupDefs=allItemGroupDefs.concat[ItemGroupDef];
      //console.logg(templateVersions[i].ItemGroupDef)
      // Track latest definitions
      if (i === latestIndex) {
        if (!ItemDef || ItemDef.length === 0) {
          // Use the second-to-last TemplateVersion as a fallback
          if (fallbackIndex >= 0) {
            const fallback = templateVersions[fallbackIndex];

            latestConditionLists = fallback.ConditionDef || [];
          } else {
            // Fallback to empty arrays if there are not enough TemplateVersions       
            latestConditionLists = [];
          }
        } else {
          // Use the latest TemplateVersion's definitions
          latestConditionLists = ConditionDef;
        }
      }
    }

    const finalConditionLists = latestConditionLists.length > 0
      ? [
        ...allConditionLists.filter(item => !latestConditionLists.some(latest => latest.OID === item.OID)),
        ...latestConditionLists
      ]
      : allConditionLists;

    // Log the final results for debugging
    //console.log(finalItemDefs, finalConditionDefs, finalCodeLists);

    // Update the state or perform other actions as needed
console.log(finalConditionLists)
    setOldConditionalDef(finalConditionLists);
    Object.assign(oldConditionalDef)
    // console.log(oldConditionalDef)
    //console.looldCodeList.current)

    return {

      codeLists: finalConditionLists
    };
  }

  const processDefinitions = (templateVersions: any | null) => {
    if (!templateVersions || templateVersions.length === 0) {
      return {
        itemDefs: [],
        conditionDefs: [],
        codeLists: []
      };
    }

    let allItemDefs: ItemDef[] = [];
    let allConditionDefs: ConditionDef[] = [];
    let allCodeLists: CodeList[] = [];
    let latestItemDefs: ItemDef[] = [];
    let latestConditionDefs: ConditionDef[] = [];
    let latestCodeLists: CodeList[] = [];

    const latestIndex = templateVersions.length - 1;

    // Collect definitions from all template versions
    for (let i = 0; i < templateVersions.length; i++) {
      const { ItemDef = [], ConditionDef = [], CodeList = [] } = templateVersions[i];

      // Collect all definitions
      allItemDefs = allItemDefs.concat(ItemDef);
      allConditionDefs = allConditionDefs.concat(ConditionDef);
      allCodeLists = allCodeLists.concat(CodeList);

      // Handle latest definitions
      if (i === latestIndex) {
        latestItemDefs = ItemDef;
        latestConditionDefs = ConditionDef;
        latestCodeLists = CodeList;
      }
    }

    // Track OIDs of latest definitions
    const latestItemDefOIDs = new Set(latestItemDefs.map(item => item.OID));
    const latestConditionDefOIDs = new Set(latestConditionDefs.map(item => item.OID));
    const latestCodeListOIDs = new Set(latestCodeLists.map(item => item.OID));

    // Build final definitions
    const finalItemDefs: ItemDef[] = [];
    const finalConditionDefs: ConditionDef[] = [];
    const finalCodeLists: CodeList[] = [];

    // Add latest item definitions
    for (const item of latestItemDefs) {
      finalItemDefs.push(item);
    }

    // Add remaining item definitions that are not in the latest definitions
    for (const item of allItemDefs) {
      if (!latestItemDefOIDs.has(item.OID)) {
        finalItemDefs.push(item);
      }
    }

    // Add latest condition definitions
    for (const item of latestConditionDefs) {
      finalConditionDefs.push(item);
    }

    // Add remaining condition definitions that are not in the latest definitions
    for (const item of allConditionDefs) {
      if (!latestConditionDefOIDs.has(item.OID)) {
        finalConditionDefs.push(item);
      }
    }

    // Add latest code lists
    for (const item of latestCodeLists) {
      finalCodeLists.push(item);
    }

    // Add remaining code lists that are not in the latest definitions
    for (const item of allCodeLists) {
      if (!latestCodeListOIDs.has(item.OID)) {
        finalCodeLists.push(item);
      }
    }

    // Filter to only include ItemDefs relevant to the latest ItemGroupDef OIDs
    const allItemDefsOid = getItemOIDsFromItemGroupDefs(templateVersions[latestIndex].ItemGroupDef);
    const updatedFinalItemDefs = finalItemDefs.filter(itemDef => allItemDefsOid.includes(itemDef.OID));

    return {
      itemDefs: updatedFinalItemDefs,
      conditionDefs: finalConditionDefs,
      codeLists: finalCodeLists
    };
  };


  // Define your separate function
  // const processItemDefs = (templateVersions): ItemDef[] => {


  //   if (templateVersions.length === 0) {
  //    // console.error('No TemplateVersion found');
  //     return [];
  //   }

  //   // Extract all ItemDef from all TemplateVersion
  //   let allItemDefs: ItemDef[] = [];
  //   let latestItemDefs: ItemDef[] = [];
  //   let latestItemDefsOIDs: Set<string> = new Set();

  //   // Loop through each TemplateVersion
  //   for (let i = 0; i < templateVersions.length; i++) {
  //     const itemDefs = templateVersions[i].ItemDef;

  //     // Collect all ItemDefs
  //     allItemDefs = allItemDefs.concat(itemDefs);
  //     // Track ItemDefs from the latest TemplateVersion
  //     if (i === templateVersions.length - 1) {
  //       latestItemDefs = itemDefs;
  //       //console.log(itemDefs)
  //       latestItemDefsOIDs = new Set(itemDefs.map(item => item.OID));
  //     }
  //   }

  //   // Identify OIDs from old ItemDefs (i.e., those not from the latest)
  //   const oldItemDefsOIDs = new Set(
  //     allItemDefs
  //       .filter(item => !latestItemDefsOIDs.has(item.OID))
  //       .map(item => item.OID)
  //   );

  //   // Ensure latest ItemDefs are included
  //   const filteredItemDefs = latestItemDefs.filter(item =>
  //     !oldItemDefsOIDs.has(item.OID)
  //   );

  //   // Combine all ItemDefs, prioritizing latest ones
  //   const finalItemDefs = [
  //     ...allItemDefs.filter(item => !latestItemDefsOIDs.has(item.OID)),
  //     ...filteredItemDefs
  //   ];
  //   //console.log(finalItemDefs)
  //   setQuestions(finalItemDefs)
  //   Object.assign(questions, finalItemDefs)
  //   return finalItemDefs;
  // };

  // Function to check for changes between existing and selected ItemDefs
  const detectItemDefChanges = (selectedItemDefs: ItemDef[], existingItemDefs: ItemDef[]): ItemDef[] => {
    // Create a map of existing ItemDefs by OID for quick lookup
    const existingItemDefMap = new Map(existingItemDefs.map(item => [item.OID, item]));
    // Collect modified ItemDefs
    const modifiedItemDefs: ItemDef[] = selectedItemDefs.filter(selectedItem => {
      const existingItem = existingItemDefMap.get(selectedItem.OID);
      if (existingItem) {
        // Check if any property has changed
        return (
          selectedItem.DataType !== existingItem.DataType ||
          (selectedItem.CodeListRef?.CodeListOID !== existingItem.CodeListRef?.CodeListOID) ||
          (selectedItem.MeasurementOid !== existingItem.MeasurementOid) || (selectedItem.Category !== existingItem.Category) ||
          selectedItem.Question.TranslatedText.text !== existingItem.Question.TranslatedText.text
        );
      }
      return false;
    });
    return modifiedItemDefs;
  };

  const getConditionOIDsWithCollectionExceptions = (conditionalDefs: ConditionDef[]): Set<string> => {
    const oidsWithCollectionExceptions = new Set<string>();
    conditionalDefs.forEach(cond => {
      // Collect OIDs that are used in CollectionExceptionConditionOID
      oidsWithCollectionExceptions.add(cond.OID);
    });
    return oidsWithCollectionExceptions;
  };

  const filterItemDefsByCollectionExceptions = (
    itemGroupDefs: ItemGroupDef[],
    itemDefs: ItemDef[],
    collectionExceptionOIDs: Set<string>
  ): ItemDef[] => {
    const itemOIDsFromExceptions = new Set<string>();
    // Gather all ItemRef from ItemGroupDef that have CollectionExceptionConditionOID
    itemGroupDefs.forEach(group => {
      group.ItemRef.forEach(ref => {
        //console.loref)
        if (ref?.CollectionExceptionConditionOID && collectionExceptionOIDs.has(ref?.CollectionExceptionConditionOID)) {
          itemOIDsFromExceptions.add(ref.ItemOID);
        }
      });
    });
    // Filter ItemDefs that match the ItemOIDs from exceptions
    return questions.filter(itemDef => itemOIDsFromExceptions.has(itemDef.OID));
  };

  const detectItemDefRequired = (ItemGroupDefs, oldItemGroupDefs) => {
    const changedItemOids = new Set();
    ItemGroupDefs.forEach(group => {
      const oldGroup = oldItemGroupDefs.find(oldGroup => oldGroup.OID === group.OID);
      if (oldGroup) {
        group.ItemRef.forEach(ref => {
          const oldRef = oldGroup.ItemRef.find(oldRef => oldRef.ItemOID === ref.ItemOID);
          if (oldRef && ref.Mandatory !== oldRef.Mandatory) {
            // If the Mandatory field has changed, add the ItemOID to the set
            changedItemOids.add(ref.ItemOID);
          }
        });
      }
    });

    // Do something with the changedItemOids set
    //console.logg("Changed Item OIDs:", changedItemOids);
    return changedItemOids;
  };



  // Function to detect changes in CodeLists
  const detectCodeListChanges = (selectedCodeLists: CodeList[], existingCodeLists: CodeList[]): CodeList[] => {
    return selectedCodeLists.filter(selectedCodeList => {
      const existingCodeList = existingCodeLists.find(cl => cl.OID === selectedCodeList.OID);
      if (existingCodeList) {
        // Check if any property or item has changed
        const itemsChanged = selectedCodeList.CodeListItem.some((item, idx) => {
          const existingItem = existingCodeList.CodeListItem[idx];
          return !existingItem ||
            item.CodedValue !== existingItem.CodedValue ||
            item.Decode.TranslatedText.text !== existingItem.Decode.TranslatedText.text ||
            item.IsEnabled !== existingItem.IsEnabled;
        });
        return (
          selectedCodeList.Name !== existingCodeList.Name ||
          selectedCodeList.DataType !== existingCodeList.DataType ||
          itemsChanged
        );
      }
      return false;
    });
  };

  // Function to detect changes in ConditionDefs
  const detectConditionDefChanges = (selectedConditions: ConditionDef[], existingConditions: ConditionDef[]): ConditionDef[] => {
    return selectedConditions.filter(selectedCondition => {
      const existingCondition = existingConditions.find(cond => cond.OID === selectedCondition.OID);
      if (existingCondition) {
        // Check if any property has changed
        return (
          selectedCondition.Name !== existingCondition.Name ||
          selectedCondition.Description.TranslatedText.text !== existingCondition.Description.TranslatedText.text ||
          selectedCondition.FormalExpression.text !== existingCondition.FormalExpression.text
        );
      }
      return false;
    });
  };

  // Function to select latest ItemDefs
  const selectLatestItemDefs = (newItemDefs: ItemDef[], existingItemDefs: ItemDef[], newCodeLists: CodeList[], existingCodeLists: CodeList[], newConditionalDefs: ConditionDef[], existingConditionalDefs: ConditionDef[]): ItemDef[] => {
    // Extract existing OIDs for comparison
    const existingOIDs = new Set(oldItemDef.map(item => item.OID));
    // Select new ItemDefs that are not present in existing ones
    const latestItemDefs = newItemDefs.filter(item => !existingOIDs.has(item.OID));
    const selectedItemDefs = questions.filter(item => itemOid.has(item.OID));
    console.log(oldItemDef,"existingItemDefs",latestItemDefs)
    const updatedCodeLists = selectLatestCodeListDefs(newCodeLists, existingCodeLists);
    const updatedCodeListOIDs = new Set(updatedCodeLists.map(codeList => codeList.OID));
    // Filter ItemDefs where CodeListRef.CodeListOID is in the updated CodeList OIDs
    const ItemDefs = questions.filter(itemDef =>
      itemDef.CodeListRef && updatedCodeListOIDs.has(itemDef.CodeListRef.CodeListOID));
    const updatedConditionalDefs = selectLatestConditionalDefs(newConditionalDefs, existingConditionalDefs);
    // Identify OIDs with Collection Exceptions
    const collectionExceptionOIDs = getConditionOIDsWithCollectionExceptions(updatedConditionalDefs);
    const itemDefConditionChanges=updateItemGroupDefsWithCollectionExceptions(ItemGroupDef,collectionExceptionOIDs)
    // Filter ItemDefs based on Collection Exception OIDs
    // const itemDefsFromExceptions = filterItemDefsByCollectionExceptions(ItemGroupDef, selectedItemDefs, collectionExceptionOIDs);
    const lastTemplateVersion = oldTemplateVersion.SDM.Building.TemplateVersion[oldTemplateVersion.SDM.Building.TemplateVersion.length - 1];
    const itemDefRequired = detectItemDefRequired(ItemGroupDef, lastTemplateVersion.ItemGroupDef);
    const conditionalItems = questions.filter(itemDef => itemDefConditionChanges.has(itemDef.OID));
    const requiredItems = questions.filter(itemDef => itemDefRequired.has(itemDef.OID));
    const changedItemDefs = detectItemDefChanges(selectedItemDefs, oldItemDef)
    const updatedItemDefs = [
      ...newItemDefs.filter(item => !oldItemDef.some(existing => existing.OID === item.OID)), // New ItemDefs
      ...changedItemDefs, // Modified ItemDefs,
      ...conditionalItems,
      ...ItemDefs,
      ...requiredItems
    ];
    const uniqueItemDefs = Array.from(new Map(updatedItemDefs.map(item => [item.OID, item])).values());
    return uniqueItemDefs;
  };

  //to get the changesd condition def item oid
  const updateItemGroupDefsWithCollectionExceptions = (
    itemGroupDefs: ItemGroupDef[], 
    collectionExceptionOIDs: Set<string>
  ) => {
    const changedItemOids = new Set<string>();
    itemGroupDefs.forEach(itemGroup => {
      // Iterate over each ItemRef inside ItemGroupDef
      itemGroup.ItemRef.forEach(itemRef => {
        // Ensure CollectionExceptionConditionOID is defined before checking in the Set
        if (itemRef.CollectionExceptionConditionOID && collectionExceptionOIDs.has(itemRef.CollectionExceptionConditionOID)) {
          // If present, add the ItemOID to the changedItemOids set
          if (itemRef.ItemOID) {
            changedItemOids.add(itemRef.ItemOID);
          }
        }
      });
    });
  
    return changedItemOids;
  };
  
  //latest codeList
  const selectLatestCodeListDefs = (newCodeLists: CodeList[], existingCodeLists: CodeList[]): CodeList[] => {
    // Extract existing OIDs for comparison
    const existingOIDs = new Set(existingCodeLists.map(codeList => codeList.OID));
    // Select new ItemDefs that are not present in existing ones
    const latestCodeLists = newCodeLists.filter(codeList => !existingOIDs.has(codeList.OID));
    const selectedCodeList = codeLists.filter(codeList => codeOid.has(codeList.OID));
    const changedCodeLists = detectCodeListChanges(selectedCodeList, existingCodeLists);
    const updatedCodeLists = [
      ...newCodeLists.filter(item => !existingCodeLists.some(existing => existing.OID === item.OID)), // New ItemDefs
      ...changedCodeLists // Modified ItemDefs
    ];
    //  //console.logg(updatedCodeLists, "updatedCodeLists")

    return updatedCodeLists;
  };


  //latest coditionalDef
  // Function to select latest ConditionDefs and update with changes
  const selectLatestConditionalDefs = (newConditions: ConditionDef[], existingConditionalDefs: ConditionDef[]): ConditionDef[] => {
    // Extract existing OIDs for comparison
    const existingOIDs = new Set(oldConditionalDef.map(condition => condition.OID));
    // Select new ConditionDefs that are not present in existing ones (i.e., new additions)
    const latestConditions = newConditions.filter(condition => !existingOIDs.has(condition.OID));
    console.log(latestConditions,oldConditionalDef)
    // Now select conditions that exist in both but have been modified
    const selectedConditions = newConditions.filter(condition => conditionOid.has(condition.OID));
    // Detect condition changes (using a similar helper function to detect changes)
    const changedConditions = detectConditionDefChanges(selectedConditions, oldConditionalDef);
      // Combine the new and changed conditions into one updated list
    const updatedConditions = [
      ...newConditions.filter(item => !oldConditionalDef.some(existing => existing.OID === item.OID)),  // New ConditionDefs
      ...changedConditions  // Modified ConditionDefs
    ];
  console.log(updatedConditions)
    return updatedConditions;
  };
  function generateNewId(lastId) {
    // Extract the numeric part of the ID
    const match = lastId.match(/(\d+)$/);
    if (match) {
      const numberPart = parseInt(match[1], 10);
      const newNumberPart = numberPart + 1; // Increment the number
      const newId = lastId.replace(/\d+$/, newNumberPart.toString().padStart(match[0].length, '0')); // Replace the number part
      //console.log(newId)
      return newId;
    } else {
      // Handle the case where there is no numeric part
      return lastId + '.1'; // Or some other logic if no numeric part exists
    }
  }
  // to store data to db
  const handleSaveFunctionality = () => {
    //////////console.log(questions, ItemGroupDef, codeLists, conditionalDef);
    // handleData();
    // selectLatestItemDefs(questions, oldItemDef);
    // selectLatestCodeListDefs(codeLists, oldCodeList);
    // selectLatestConditionalDefs(conditionalDef, oldConditionalDef)
    if (questions.length > 1) {
      const itemOIDsInKeyItemGroups = ItemGroupDef.flatMap(group =>
        group.ItemRef.map(ref => ref.ItemOID)
      );
      // Filter questions to find those that are incomplete
      const incompleteQuestionOIDs = questions
        .filter(question =>
          itemOIDsInKeyItemGroups.includes(question.OID) &&
          !question.Question.TranslatedText.text
        )
        .map(question => question.OID);
      if (incompleteQuestionOIDs.length > 0) {
        // Set the validation state for incomplete questions
        setIncompleteQuestions(incompleteQuestionOIDs);
        return; // Do not proceed with adding a new question
      }
      // Clear incomplete questions state if all questions are complete
      setIncompleteQuestions([]);
    }
    let data;
    let lastTemplateVersion;
    console.log('Type of oldTemplateVersion:', oldTemplateVersion);
    //console.log(oldTemplateVersion.SDM.Building.TemplateVersion[oldTemplateVersion.SDM.Building.TemplateVersion.length - 1].OID, questions)
    if (oldTemplateVersion?.SDM?.Building?.TemplateVersion) {
      lastTemplateVersion = oldTemplateVersion?.SDM?.Building?.TemplateVersion[oldTemplateVersion?.SDM?.Building?.TemplateVersion?.length - 1];

    
      const lastId = lastTemplateVersion.OID;
      processCodeListDefinitions(oldTemplateVersion.SDM.Building.TemplateVersion);
      processConditionDefinitions(oldTemplateVersion.SDM.Building.TemplateVersion);
      processItemDefDefinitions(oldTemplateVersion.SDM.Building.TemplateVersion);
      // Function to generate a new ID based on the last ID
      //console.looldCodeList.current);
      const latestTemplateVersion: TemplateVersion = {
        Protocol: defaultProtocol,
        EventDef: [questionType !== ASSET ? newEventDef : newAssetEventDef],
        FormDef: formDef,
        ItemGroupDef: ItemGroupDef,
        ItemDef: selectLatestItemDefs(questions, oldItemDef, codeLists, oldCodeList.current, conditionalDef, oldConditionalDef),
        CodeList: selectLatestCodeListDefs(codeLists, oldCodeList.current),
        ConditionDef: selectLatestConditionalDefs(conditionalDef, oldConditionalDef),
        OID: generateNewId(lastId),
        Name: "MetaDataVersion",
      };
      //console.log(oldTemplateVersion.SDM.Building, latestTemplateVersion);
      oldTemplateVersion.SDM.Building.TemplateVersion.push(latestTemplateVersion);
      // //console.log('Type of oldTemplateVersion:', oldTemplateVersion.SDM.Building.TemplateVersion.push(latestTemplateVersion));
      Object.assign(template, oldTemplateVersion.SDM.Building.TemplateVersion);
      setTemplate(oldTemplateVersion.SDM.Building.TemplateVersion)
      //console.log(template, (template && template.length > 0))
      //console.log(typeof template)
      //console.log(projects)
      if (oldTemplateVersion) {
        let projects: Building = {
          GlobalVariables: globalVariables,
          TemplateVersion: oldTemplateVersion.SDM.Building.TemplateVersion,
          BasicDefinition: basicDefinitions,
          OID: questionType !== ASSET ? tradeOidRef.current : ASSET_OID
        }


        const newSDM: SDM = {

          Building: projects as Building,
          xmlns: "",
          FileType: "Snapshot",
          FileOID: "New Trade",
          CreationDateTime: "2024-06-24T10:48:11.355Z",
          ODMVersion: "1.3.2",
          SourceSystem: "",
          AdminData: {
            User: {
              FirstName: "New",
              LastName: "User",
              OID: "U.1"
            },
           OID: questionType !== ASSET ? tradeOidRef.current : ASSET_OID
          },
          BuildingData: {
            // AuditRecord: {
            //   UserRef: {
            //     UserOID: "U.1"
            //   },
            //   LocationRef: {
            //     LocationOID: "-"
            //   },
            //   DateTimeStamp: "2024-06-24T10:47:31.016Z"
            // },
            EventData: [{
              FormData: [{
                ItemGroupData: [
                  {
                    ItemData: [
                    ],
                    ItemGroupOID: "",
                    ItemGroupRepeatKey: 0
                  },
                  {
                    ItemData: [

                    ],
                    ItemGroupOID: "",
                    ItemGroupRepeatKey: 0

                  }
                ],
                FormOID: "F.3",
                TransactionType: "Insert"
              }],
              EventOID: "E.3"
            }],
            Key: "123",
            OID: questionType !== ASSET? tradeOidRef.current : 'A.1',
            MetaDataVersionOID: "MDV.1"
          },
        };
        data = {
          SDM: newSDM as SDM
        }
      }
    }

    // //console.log(newTemplateDataVersions)
    // projects: Trade = {
    //   GlobalVariables: globalVariables,
    //   TemplateVersion: newTemplateDataVersions as TemplateVersion[],
    //   BasicDefinition: basicDefinitions,
    //   OID: questionType !== 'asset' ? 'P.1' : 'A.1'
    // }
    else {
      data = {
        SDM: newSDM as SDM
      }
    }

    //console.logg(data)
    questionTemplateRef.current = {
      ...questionTemplateRef.current,
      tradeOid: tradeOidRef.current,
      name: data.SDM.Building.GlobalVariables.TradeName,
      createdDate: dateTimeFormatter(new Date()),
      createdBy: createdBy,
      status: 'active',
      invalid: false,
      questionTemplateData: JSON.stringify(data)
    };
    ////console.log(questionTemplateRef.current)
    createQuestionTemplate(questionTemplateRef.current, accessToken).then((res) => {
      if ((res.data >= 1) && (res.status === 201 || res.status === 200) && (res.ok === true)) {
        setOpenDialogs(true);
        setCreateSuccess(true);
        setError(false);
      }
      else {
        setOpenDialogs(true);
        setCreateSuccess(false);
        setError(true);
      }
    })
    //////////console.log("questionTemplate", questionTemplateRef.current)
    const dataStr = 'data:application/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(data));
    const download = document.createElement('a');
    download.setAttribute('href', dataStr);
    download.setAttribute('download', 'CardData' + '.json');
    document.body.appendChild(download);
    download.click();
    download.remove();

  }

  // let data = { SDM: newSDM as SDM }
  // to show trade names in select box 
  const handleTradeNames = () => {
    getTradeNames(accessToken).then(res => {
      if (res.data && res.ok === true) {
        setTradeNames(res.data || []);
      }
    }).catch(error => {
      // console.error('Error fetching trade names:', error);
    });
  };

  // Default ItemDef object
  const handleSelectChange = async (event: React.ChangeEvent<HTMLSelectElement>) => {
    ////console.log(ItemGroupDef, codeLists, conditionalDef, "questions", questions, "formDef", formDef)

    let values = event.target.value || '';
    setSelectedTradeItem(values);
    //////console.log(values)
    setLoading(true);

    await handleTradeType(event.target.value);
    try {
      const effectiveTradeType = questionType === ASSET ? questionType : tradeType.current;
      //////console.log(values, effectiveTradeType)
      const res = await getTemplatesByTradeName(tradeOidRef.current,QUESTION_STATUS, accessToken);
      //////console.log(res)
      if (res.data && res.ok) {
        //////console.log("API Response Data:", res.data);

        // Parse and assign the template data
        const templateDataParsed = JSON.parse(res.data.questionTemplateData);
        const newFormDef = templateDataParsed.SDM.Building.TemplateVersion.FormDef || [];
        const newItemGroupDef = templateDataParsed.SDM.Building.TemplateVersion.ItemGroupDef || [];
        const newConditionalDef = templateDataParsed.SDM.Building.TemplateVersion.ConditionDef || [];
        const newCodeLists = templateDataParsed.SDM.Building.TemplateVersion.CodeList || [];
        const newQuestions = templateDataParsed.SDM.Building.TemplateVersion.ItemDef || [];

        setOldTemplateVersion(JSON.parse(res.data.questionTemplateData));

        // Set state values
        setFormDef(newFormDef);
        setItemGroupDef(newItemGroupDef);
        setConditionalDef(newConditionalDef);
        setCodeLists(newCodeLists);
        setQuestions(newQuestions);

        Object.assign(questions, newQuestions)
        Object.assign(formDef, newFormDef)
        Object.assign(ItemGroupDef, newItemGroupDef)
        Object.assign(codeLists, newCodeLists)
        Object.assign(conditionalDef, newConditionalDef);
        ////console.log(ItemGroupDef, codeLists, conditionalDef, "questions", questions, "formDef", formDef)

        // Additional logic based on the selected value
        if (questionType === ASSET) {
          handleSampleQuestions("Asset");
        }
        else if (value === '1') {
          handleSampleQuestions("Trade Questions");
        } else if (value === '2') {
          handleSampleQuestions("Optional Questions");
        }

      }
      else if (tradeType.current) {
        handleSelectTradeDAta(tradeType.current);
      }
      else {

        setQuestions(defaultItemDef);
        setCodeLists(defaultCodeList);
        setItemGroupDef(defaultItemGroupDef);
        if (questionType === ASSET) {
          setFormDef(newAssetFormDefs);
        } else {
          setFormDef(newFormDefs);
        }
        setConditionalDef(newConditionalDefs);
      }
    } catch (error) {
      // console.error("Error fetching templates:", error);
    }
    // finally{
    //   setLoading(false)
    // }
  };

  // to generate the json format and pass different item to db 
  const handleSampleQuestions = (questionName: any) => {
    setItemGroupDef(ItemGroupDef);

    // setLoading(true); // Start loading
    // handlePropsChange();
    // ////////console.log(questions, ItemGroupDef, codeLists, conditionalDef, value);
    // Object.assign(jsonData,{SDM: newSDM as SDM})
    //console.logg(ItemGroupDef[0].OID==='',ItemGroupDef,conditionalDef)
    //console.log(questions, ItemGroupDef, "..................")
    let data = {
      SDM: newSDM as SDM
    }
    Object.assign(jsonData, data)
    // ////////console.log(data)
    // if(data.SDM.Building.TemplateVersion.ItemDef!==null){
    let questionData = jsonData;
    //console.log("questionData", jsonData)
    // Function to map and filter itemGroupData
    const mapTranslatedText = (translatedText: { "xml:lang": string; text: string }): TranslatedText => {
      return {
        lang: translatedText["xml:lang"],
        text: translatedText.text
      };
    };
    //console.log(questionData.SDM.Building.TemplateVersion)
    const templateVersion = questionData.SDM.Building.TemplateVersion[questionData.SDM.Building.TemplateVersion.length - 1];
    ////////console.log(templateVersion,questionName)
    // Find the relevant 'res' based on the condition
    const relevantRes = questionData.SDM.Building.TemplateVersion[questionData.SDM.Building.TemplateVersion.length - 1].FormDef.find((res) => {
      if (questionName === 'Trade Questions' && res.OID === "F.1") {
        return true;
      }
      if (questionName !== 'Trade Questions' && res.OID === 'F.2') {
        return true;
      }
      if (questionName === 'Asset' && res.OID === 'F.1') {
        return true;
      }
      return false;
    });

    //console.log(relevantRes, "relevantRes")
    // Check if relevantRes is undefined or false
    if (!relevantRes) {
      //////////console.log("No relevant FormDef found.");
      return; // Or handle the case where no relevant FormDef is found
    }
    // If a relevant 'res' is found, map and filter itemGroupData
    const ItemGroups: ItemGroupDef[] = relevantRes.ItemGroupRef?.map((item: any) => {
      const itemGroupData = templateVersion.ItemGroupDef.find((itemGroupData: any) => item.ItemGroupOID === itemGroupData.OID);
      if (itemGroupData) {
        return {
          ...itemGroupData,
          Description: {
            TranslatedText: {
              "xml:lang": 'en',
              text: itemGroupData.Description.TranslatedText.text
            }
          }
        };
      }
      return undefined;
    })?.filter((itemGroupData): itemGroupData is ItemGroupDef => itemGroupData !== undefined) || [];
    // Set the appropriate state based on the value of relevantRes
    if (relevantRes.OID === "F.1") {
      setKeyItemGroupDef(ItemGroups);
    } else {
      setKeyItemGroupDef(ItemGroups);
    }
    //console.log("itemGroups", ItemGroupDef[0].Description.TranslatedText.text)
    const { itemDefs, conditionDefs, codeLists } = processDefinitions(questionData.SDM.Building.TemplateVersion);

    ////////console.log( templateVersion.FormDef)
    const FormData: FormDef[] = templateVersion.FormDef.map((formDef) => {
      const mappedConditionDef: FormDef = {
        Description: {
          TranslatedText: {
            lang: formDef.Description.TranslatedText.lang,
            text: formDef.Description.TranslatedText.text
          }
        },
        ItemGroupRef: formDef.ItemGroupRef.map(item => ({
          ItemGroupOID: item.ItemGroupOID,
          Mandatory: item.Mandatory
        })),
        OID: formDef.OID,
        Name: formDef.Name,
        Repeating: formDef.Repeating
      };
      return mappedConditionDef;
    });
    ////////console.log( FormData)
    setFormDef(FormData);
    // const f2Data = FormData.find((formDef) => formDef.OID === "F.2");
    const f2Data = FormData.find((formDef) => formDef.OID === "F.2");
    const f1Data = FormData.find((formDef) => formDef.OID === "F.1");
    // ////////console.log("optionalFormDef", f1Data, f2Data)
    // Object.assign(keyQuestionFormDef, f2Data);
    Object.assign(optionalFormDef, f2Data);
    Object.assign(keyQuestionFormDef, f1Data);
    // ////////console.log("FormData", FormData)
    setFormDef(FormData)
    ////////console.log(formDef)
  //   setQuestions(itemDefs)
  //   setCodeLists(codeLists);
  //   setConditionalDef(conditionDefs)
  // setOldItemDef(itemDefs);
  // setOldConditionalDef(conditionDefs)
  // oldCodeList.current=codeLists;
  // setOlItemGroupDefs(ItemGroupDef);
    //console.log(loading)
    console.log(ItemGroupDef, formDef, questions,conditionalDef);
  };
  const renderTabs = () => {
    if (questionType === ASSET) {
      return [
        <Tab label="Asset" value="3" key="3" onClick={() => handleSelectTradeDAta(ASSET)} />,
      ];
    } else {
      return [
        <Tab label="Trade Questions" value="1" key="1" onClick={() => handleSelectTradeDAta(questionType)} />,
        // <Tab label="Optional Questions" value="2" key="2" onClick={() => handleSampleQuestions("Optional Questions")} />,
        // <Tab label="Asset" value="3" key="3" onClick={() => handleSampleQuestions("Asset")} />,
      ];
    }
  };

  return (
    <div>
      <Box sx={{ width: '100%', typography: 'body1', marginLeft: "0px" }}>
        <Grid container spacing={2} mt={0}>
          {questionType !== ASSET && (
            <Grid item xs={12} md={7}>
              <FormControl sx={{ m: 1 }} size="small" fullWidth>
                <InputLabel id={`demo-select-small-label`}>Select Your Trade</InputLabel>
                <Select
                  labelId={`demo-select-small-label`}
                  id={`demo-select-small`}
                  value={selectedTradeItem}
                  label="Select Your Trade"
                  onChange={(e: any) => handleSelectChange(e)}
                  fullWidth
                  readOnly
                >
                  {tradeNames.map((item, index) => (
                    <MenuItem key={index} value={item}>
                      {item}
                    </MenuItem>
                  ))}

                </Select>
              </FormControl>
              {/* <Button onClick={handlePropsChange}>save</Button> */}
            </Grid>
          )}
        </Grid>
        <TabContext value={value} >
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} aria-label="lab API tabs example">
              {renderTabs()}
            </Tabs>
          </Box>
          <TabPanel value="1">
            <Box sx={{ width: '100%', typography: 'body1', marginLeft: "0px" }}>
              <Grid>
                <Grid container spacing={2} >
                  <Grid item xs={12} md={12}>
                    <QuestionCard
                      itemGroupDefs={ItemGroupDef}
                      setItemGroupDefs={setItemGroupDef}
                      questions={questions}
                      setQuestions={setQuestions}
                      codeLists={codeLists}
                      setCodeLists={setCodeLists}
                      conditionalDef={conditionalDef}
                      setConditionalDef={setConditionalDef}
                      formDef={keyQuestionFormDef}
                      formDefs={formDef}
                      setFormDef={setFormDef}
                      basicDefinitionData={basicDefinition}
                      isloading={loading}
                      // setNewEventData={setNewEventData}
                      // newEventData={newEventData}
                      setConditionOid={setConditionOid}
                      setItemOId={setItemOid}
                      setCodeOid={setCodeOid}
                      incompleteQuestions={incompleteQuestions}
                      setIncompleteQuestions={setIncompleteQuestions} selectedTab={selectedTradeItem} />
                    {role !== 'admin' && (ItemGroupDef.length !== 0 ?
                      <Button className="mt-2"
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={handleSaveFunctionality}>
                        save
                      </Button>
                      :
                      <Button className="mt-2"
                        fullWidth
                        variant="contained"
                        color="primary"
                        disabled
                        onClick={handleSaveFunctionality}>
                        save
                      </Button>
                    )}
                  </Grid>

                  <Grid item xs={12} md={2}>
                    {/* <PreviewQuestions /> */}
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </TabPanel>
          <TabPanel value="2">
            <Box sx={{ width: '100%', typography: 'body1', marginLeft: "0px" }}>
              <Grid>
                <Grid container spacing={2} >
                  <Grid item xs={12} md={7}>
                    <QuestionCard
                      itemGroupDefs={ItemGroupDef}
                      setItemGroupDefs={setItemGroupDef}
                      questions={questions}
                      setQuestions={setQuestions}
                      codeLists={codeLists}
                      setCodeLists={setCodeLists}
                      conditionalDef={conditionalDef}
                      setConditionalDef={setConditionalDef}
                      formDef={optionalFormDef}
                      formDefs={formDef}
                      setFormDef={setFormDef}
                      basicDefinitionData={basicDefinition}
                      isloading={loading}
                      // setNewEventData={setNewEventData}
                      // newEventData={newEventData}
                      setConditionOid={setConditionOid}
                      setItemOId={setItemOid}
                      setCodeOid={setCodeOid}
                      incompleteQuestions={incompleteQuestions}
                      setIncompleteQuestions={setIncompleteQuestions} selectedTab={selectedTradeItem} />
                    {role !== 'admin' && (ItemGroupDef.length !== 0 ?
                      <Button className="mt-2"
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={handleSaveFunctionality}>
                        save
                      </Button>
                      :
                      <Button className="mt-2"
                        fullWidth
                        variant="contained"
                        color="primary"
                        disabled
                        onClick={handleSaveFunctionality}>
                        save
                      </Button>
                    )}
                  </Grid>
                  <Grid item xs={12} md={5}>
                    {/* <PreviewQuestions /> */}
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </TabPanel>
          <TabPanel value="3">
            <Box sx={{ width: '100%', typography: 'body1', marginLeft: "0px" }}>
              <Grid>
                <Grid container spacing={2} >
                  <Grid item xs={12} md={7}>
                    <QuestionCard
                      itemGroupDefs={ItemGroupDef}
                      setItemGroupDefs={setItemGroupDef}
                      questions={questions}
                      setQuestions={setQuestions}
                      codeLists={codeLists}
                      setCodeLists={setCodeLists}
                      conditionalDef={conditionalDef}
                      setConditionalDef={setConditionalDef}
                      formDef={keyQuestionFormDef}
                      formDefs={formDef}
                      setFormDef={setFormDef}
                      basicDefinitionData={basicDefinition}
                      isloading={loading}
                      setConditionOid={setConditionOid}
                      setItemOId={setItemOid}
                      setCodeOid={setCodeOid}
                      incompleteQuestions={incompleteQuestions}
                      setIncompleteQuestions={setIncompleteQuestions} selectedTab={selectedTradeItem}                      // setNewEventData={setNewEventData}
                    // newEventData={newEventData}
                    />
                    {/* {role} */}
                    {role !== 'admin' && (ItemGroupDef.length !== 0 ?
                      <Button className="mt-2"
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={handleSaveFunctionality}>
                        save
                      </Button>
                      :
                      <Button className="mt-2"
                        fullWidth
                        variant="contained"
                        color="primary"
                        disabled
                        onClick={handleSaveFunctionality}>
                        save
                      </Button>
                    )}
                  </Grid>

                  <Grid item xs={12} md={5}>
                    {/* <PreviewQuestions /> */}
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </TabPanel>
        </TabContext>
      </Box>
      <CustomDialog
        open={openDialogs}
        // onClose={() => setOpenDialogs(false)}
        onClose={handleDialogClose}
        success={createSuccess}
        error={error}
        Content={createSuccess ? "Template Uploaded succesfully" : " Template Upload Failed "} />
    </div>
  )
}

export default Questions;