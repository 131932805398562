/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 3-Nov-2023
* @author      : Shiyamkumar.G
* @since       : 0.0.1
* Requirement# :
* Purpose      : Bid Service
* -----------------------------------------------------------------------------
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem |    DATE    |     AUTHOR    |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
*       1385     | 01-04-2024 | Shiyam Kumar  | Calculate Every Month Average For PriceTrend
* 2235           |  10/06/24   |   Vanitha    | Most Lead and Bid Total Esitmate for month display implementation
* 2235           |  10/06/24   |   Vanitha    | Most Lead and Bid Total Esitmate for year  display implementation
* 2235           |  21/06/24   |   Vanitha    | Bid Total Esitmate for Month  display implementation
* 2235           |  25/06/24   |   Vanitha    | Bid Total count display implementation


*
*/

import { executeGetData, executePostData, executePutData } from "./ApiBase";
import { ConfigUrl } from "../auth/OidcConfig";

// export const getBids = async () => {
//   const url = `${Config.baseURL}/subjects?page=0&size=10&sortDir=ASC&sort=firsNumber`;
//   //   return await executeGet(url, accessToken);
//   return bidDatas;
// };

export const getUsers = async (accessToken: any) => {
  const url = `${ConfigUrl.baseURL}/customers/getCustomers`;
  return await executeGetData(url, accessToken);
}
export const saveCustomer = async (customer: any, accessToken: any) => {
  const url = `${ConfigUrl.baseURL}/customers/addcustomer`;
  //console.log(url);
  return await executePostData(url, customer, accessToken);
};

export const getTrades = async (accessToken: any) => {
  const url = `${ConfigUrl.baseURL}/trades/getalltrades`;
  return await executeGetData(url, accessToken)
}
export const getTradesHavingTemplate = async (accessToken: any) => {
  const url = `${ConfigUrl.baseURL}/trades/getValidTradeName`;
  return await executeGetData(url, accessToken)
}

export const getMaterials = async (accessToken: any, trade: any) => {
  const url = `${ConfigUrl.baseURL}/trades/material/${trade}`;
  return await executeGetData(url, accessToken)
}

export const getManufacturers = async (accessToken: any, trade: any, material: any) => {
  const url = `${ConfigUrl.baseURL}/trades/manufacturer/${trade}/${material}`;
  return await executeGetData(url, accessToken)
}

export const getAssets = async (accessToken: any, customerId: any) => {
  const url = `${ConfigUrl.baseURL}/assets/getAssetByCustomerUid/customerUid/${customerId}`;
  return await executeGetData(url, accessToken)
}

export const getAssetsByAssetUid = async (accessToken: any, assetUid: any) => {
  const url = `${ConfigUrl.baseURL}/assets/getAssetByAssetUid/assetUid/${assetUid}`;
  return await executeGetData(url, accessToken)
}

export const getBidTotalEstimate = async (accessToken: any,startDate:any,endDate:any) => {
  const url = `${ConfigUrl.baseURL}/bids/getBidEstimate/from/${startDate}/to/${endDate}`;
  return await executeGetData(url, accessToken)
}

export const getEstimateByYear = async (accessToken: any) => {
  const url = `${ConfigUrl.baseURL}/bids/getBidEstimateByYear`;
  return await executeGetData(url, accessToken)
}

export const getBidCount = async (accessToken: any) => {
  const url = `${ConfigUrl.baseURL}/bids/count`;
  return await executeGetData(url, accessToken)
}

export const getEstimateByMonth = async (accessToken: any,status:any) => {
  const url = `${ConfigUrl.baseURL}/bids/getBidEstimateByMonth/status/${status}`;
  return await executeGetData(url, accessToken)
}

// export const createBid = async (bidData: any, accessToken: any) => {
//   console.log(bidData);
//   const url = `${ConfigUrl.baseURL}/bids/createBid`;
//   return await executePostData(url, bidData, accessToken)
// }

export const revalidate = async (projectId: any, accessToken: any) => {
  const url = `${ConfigUrl.baseURL}/bids/revalidateProject/projectId/${projectId}`;
  return await executePostData(url, null, accessToken)
}

export const addToCart = async (bidId:any, bidNo:any, status:any, accessToken:any) => {
    const url = `${ConfigUrl.baseURL}/bids/addToCart/bidId/${bidId}/bidNo/${bidNo}/status/${status}`;
    return await executePutData(url, null, accessToken)
}

// export const getAllBid = async () => {
//   try {
//     const response = await axios.get(`${Config.baseURL}/bid/getAllBid`);
//     return response.data;
//   } catch (error: any) {
//     if (error.response) {
//       throw new Error(`Server Error: ${error.response.data}`);
//     } else if (error.request) {
//       throw new Error('No response received from the server.');
//     } else {
//       throw new Error(`Error: ${error.message}`);
//     }
//   }
// };


// export const getPriceChart = async (item: any) => {
//   console.log(item);
//   const url = `${Config.baseURL}/analyticalDatas/search?page=0&size=50`;
//   return await executePost(url, item);
// }

export const deleteBidById = async (projectId: any, status:any, accessToken: any) => {
  const url = `${ConfigUrl.baseURL}/bids/deleteProject/projectId/${projectId}/status/${status}`;
  return await executePutData(url, null, accessToken);
}
export const getPriceChartData = async (item: any, accessToken: any) => {
  const searchCriteriaList:any = [];
  Object.keys(item).map(key =>{ 
    searchCriteriaList.push({
            "key": key,
            "value": item[key],
            "operation": "eq",
            "logicalOp": "and"
          })
  })
  const url = `${ConfigUrl.baseURL}/analyticalDatas/search`;
  const payload ={"searchCriteriaList": searchCriteriaList}
  return await executePostData(url, payload, accessToken);
}

export const createBid = async (bidData: any, accessToken: any) => {
  console.log(bidData);
  const url = `${ConfigUrl.baseURL}/bids/createBid`;
  return await executePostData(url, bidData, accessToken)
}

export const getCartCount = async (accessToken: any, status:any) => {
  const url = `${ConfigUrl.baseURL}/bids/getMyBidCount/status/${status}`;
  return await executeGetData(url, accessToken)
}

export const getCartList = async (accesstoken: any, status:any) => {
  const url = `${ConfigUrl.baseURL}/bids/getBidsByCustomerUidAndStatus/status/${status}`;
  return await executeGetData(url, accesstoken);
};

export const getTradeDetails = async ( tradeName,accessToken: any) => {
  const url = `${ConfigUrl.baseURL}/trades/tradeName/${tradeName}`;
  const response= await executeGetData(url,accessToken);
  //console.log(response)
  return response;
}

export const getBidStatus = async (dateRange:any, status:any, accessToken: any) => {
  const url = `${ConfigUrl.baseURL}/bids/bidCountStatistics/dateRange/${dateRange}/status/${status}`;
  return await executeGetData(url, accessToken)
}

export const createEstimate = async (projectId:any, jobStartDate:any, accessToken: any) => {
  let url:any;
  if(jobStartDate != null){
    url = `${ConfigUrl.baseURL}/bids/createEstimate/projectId/${projectId}/jobStartDate/${jobStartDate}`;
  }else{
    url = `${ConfigUrl.baseURL}/bids/createEstimate/projectId/${projectId}`;
  }
  return await executePostData(url, null, accessToken)
}

export const getValidTradeDetails = async (accessToken: any) => {
  const url = `${ConfigUrl.baseURL}/trades/getValidTradeDetails`;
  return await executeGetData(url, accessToken)
}

export const getValidTradeData = async (accessToken: any) => {
  const url = `${ConfigUrl.baseURL}/trades/getValidTradeData`;
  return await executeGetData(url, accessToken)
}