
export interface OidcConfiguration {
    client_id: string;
    client_secret: string;
    redirect_uri: string;
    silent_redirect_uri: string;
    scope: string;
    authority: string;
}

// const oidcConfiguration: OidcConfiguration = {
//   client_id: "f2bf96c0-3457-47e0-a5bf-4edfd3f6ef5c",
//   client_secret: "secret",
//   redirect_uri: "https://ca-gmscloud-webapps-qa-ncus.agreeabledesert-6a142711.northcentralus.azurecontainerapps.io/callback", 
//   silent_redirect_uri: "https://ca-gmscloud-webapps-qa-ncus.agreeabledesert-6a142711.northcentralus.azurecontainerapps.io/silent-callback",
//   scope: "openid",
//   authority: "https://gmscloudprod.b2clogin.com/gmscloudprod.onmicrosoft.com/B2C_1_signin_only_nomfa"
// };

const oidcConfiguration: OidcConfiguration = {
    client_id: "d7285d5f-db23-4d86-bbfb-8190087bea7c",
    client_secret: "secret",
    redirect_uri: "https://thegms.ai/callback",
    silent_redirect_uri: "https://thegms.ai/silent-callback",
    scope: "openid",
    authority: "https://gmscloudprod.b2clogin.com/gmscloudprod.onmicrosoft.com/B2C_1_signin_only_nomfa"
};

// const oidcConfiguration: OidcConfiguration = {
//   client_id: "f2bf96c0-3457-47e0-a5bf-4edfd3f6ef5c",
//   client_secret: "secret",
//   redirect_uri: "http://localhost:3000/callback",
//   silent_redirect_uri: "http://localhost:3000/silent-callback",
//   scope: "openid",
//   authority: "https://gmscloudprod.b2clogin.com/gmscloudprod.onmicrosoft.com/B2C_1_signin_only_nomfa"

// };
export const ConfigUrl = {
    baseURL: "https://ca-gmscloud-tenant-api-ncus-pr.redwave-438f6a4d.northcentralus.azurecontainerapps.io/gms-provider/api/v2"
};

export default oidcConfiguration;
