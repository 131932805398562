/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 6-Nov-2023
* @author      :Asish P Saji
* @since       : 0.0.1
* Requirement# :
* Purpose      :for contract template
* -----------------------------------------------------------------------------0
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem |   DATE    |   AUTHOR  |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
* 
*
*
*/
import React, { useState, useRef, useContext } from 'react';
import { Box, DialogActions, DialogContentText, DialogTitle, Grid, IconButton, Modal, styled, Tooltip, Typography } from '@mui/material';
// import MUIRichTextEditor from 'mui-rte';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import SignatureCanvas from 'react-signature-canvas';
import { createContract, getContract, getContractDetails, getDynamicContractDetails } from '../../services/ContractSigningService';
import { useOidcAccessToken } from '@axa-fr/react-oidc';
import { useLocation, useNavigate } from 'react-router-dom';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import moment from 'moment';
import { getUserSession, UserContext } from "../../hooks/UserSession";
import { getSignature, getTenantDetails } from '../../services/TenantService';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import html2pdf from 'html2pdf.js';
import Editor from '../ReactQuil/tableModule';
// import  RichTextEditor  from 'quill';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { APPROVE_CONTRACT_TASK } from '../../utils/TaskConstants';
import { getDependentTask } from '../../services/TaskService';
import TaskWarningDialog from '../task/TaskWarningDialog';

pdfMake.vfs = pdfFonts.pdfMake.vfs;
const ContractSigningTemplate: React.FC = () => {

  const location = useLocation();
  // const id = location.state.project.projectId || location.state.project.id
  const [editorHtml, setEditorHtml] = useState(
    '<h2>Contract Signing ,</h2><Stack>this is a <em>basic</em> example of <strong>tiptap</strong>. Sure, there are all kind of basic text styles you’d probably expect from a text editor. But wait until you see the lists:</Stack><ul><li>That’s a bullet list with one …</li><li>… or two list items.</li></ul><p>Isn’t that great? And all of that is editable. But wait, there’s more. Let’s try a code block:</p><pre><code className="language-css" style= {{display: none;}}></code></pre><p>I know, I know, this is impressive. It’s only the tip of the iceberg though. Give it a try and click a little bit around. Don’t forget to check the other examples too.</p><blockquote>Wow, that’s amazing. Good work, Raj! 👏<br />— Sales</blockquote>'
  );
  const [file, setFile] = useState<File | any>(null);

  // const { projectDTO } = location?.state;

  const handleChange = (html) => {
    setEditorHtml(html);
    // setFile()

  };
  const { accessToken, accessTokenPayload } = useOidcAccessToken();

  const [matRecSignature, setMatRecSignature] = useState<string | null>(null);
  // const [editorHtml, setEditorHtml] = useState('<h2>Contract Signing</h2><p>Sign below:</p>');
  const editorRef = useRef<ReactQuill>(null); // Explicitly define the type of editorRef
  const [matrecSign, setMatrecSign] = useState<string>(""); // Declare matrecSign state variable
  const signatureRef = useRef<any>(null); // Declare signatureRef with its type
  const [openmatrec, setOpenmatrec] = useState<boolean>(false); // Declare openMatrec state variable
  const [success, setSuccess] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [disabled, setDisabled] = React.useState(false);
  const [responses, setResponse] = useState<any>();
  const [content, setContent] = useState<any>();
  const [count, setCount] = React.useState(0);
  const { userSessionDetails } = useContext(UserContext);

  const [financeData, setFinanceData] = useState<any>();

  const [dynamiData, setDynamicData] = useState<any>();

  const [role, setRole] = useState('');
  const [tenantDetails, setTenantDetails] = useState<any>();

  const [signature, setSignature] = useState<File | any>(null);
  const [showWarning, setShowWarning] = React.useState(false);
  const navigate = useNavigate();
  const handleAlertClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    setSuccess(false);
    setError(false);
    navigate("/salesestimate");
  }
  const [loading, setLoading] = React.useState(false);
  // const getTenantConfig = async () => {
  //   const response = await getTenantDetails(accessToken);
  //   //console.llog(response.data)
  //   if (response.data == undefined) {
  //     navigate("/tenantconfiguration");
  //   }
  //   let details = response.data
  //   //console.log(response.data)
  //   setTenantDetails(response.data);
  //   // Check if response.data is defined before accessing its elements

  // }
  const contentRef = useRef<HTMLDivElement>(null);
  const [editorContent, setEditorContent] = useState('');

  const convertToPdf = () => {
    const content = contentRef.current;

    const options = {
      filename: 'my-document.pdf',
      margin: 1,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: {
        unit: 'in',
        format: 'letter',
        orientation: 'portrait',
      },
    };

    html2pdf().set(options).from(content).save();
  };


  const handlematrecSave = () => {
    ////console.log(signatureRef.current)
    if (signatureRef.current) {
      // Get a reference to the canvas element
      const canvas = signatureRef.current.getCanvas();

      // Create a temporary canvas with the desired dimensions
      const tempCanvas = document.createElement('canvas');
      tempCanvas.width = 150; // Set the new width
      tempCanvas.height = 100; // Set the new height

      // Draw the original canvas content onto the temporary canvas with the new dimensions
      const tempCtx = tempCanvas.getContext('2d');
      if (tempCtx) {
        tempCtx.drawImage(canvas, 0, 0, tempCanvas.width, tempCanvas.height);
      }

      // Convert the temporary canvas to a data URL
      const signatureBase64 = tempCanvas.toDataURL();
      const updatedEditorHtml = responses + `<img src="${signatureBase64}" alt="Signature" /><div style="display: block;"><div>${accessTokenPayload.name}, Customer</div></div>`;
      ////console.log(updatedEditorHtml)
      setResponse(updatedEditorHtml);
      setFile(signatureBase64)

      // Create a download link for the signature image
      const link = document.createElement('a');
      link.href = signatureBase64;
      // link.download = 'signature.png'; // Set the file name for the downloaded image
      // document.body.appendChild(link);
      // link.click();
      // document.body.removeChild(link);

      // Update state with the signature data
      setMatrecSign(signatureBase64.replace(/^data:image\/(png|jpeg);base64,/, ""));
      setMatRecSignature(signatureBase64);
    }
    setOpenmatrec(false);
  };
  // const quillRef = useRef<ReactQuill>(null);

  const handlematrecCancel = () => {
    setOpenmatrec(false);
  };
  const handlematrecOpen = () => {
    Dynamic();
    setOpenmatrec(true);
  };


  // const getContractSignature = () => {
  //   getSignature(accessToken).then((res) => {////console.log(res);
  //     const imgTag = res.data;
  //     const srcMatch = imgTag.match(/src="([^"]+)"/);
  //     //console.log(res)
  //     if (srcMatch && srcMatch[1]) {
  //       const srcValue = srcMatch[1];
  //       setSignature(srcValue)
  //       setResponse((prevHtml) => `${prevHtml}${imgTag}<div style="display: block;">${tenantDetails}</div>`);

  //       // setFile(srcValue);
  //     } else {
  //       console.error("No src attribute found in the image tag.");
  //     }
  //   }
  //   );
  //   ////console.log(response);
  // }
  const placeholderText = '[Details of payment schedule, e.g., deposit, progress payments, final payment]';


  const Dynamic = () => {
    getContract(accessToken).then((responseData) => {
      //console.log(responseData)
      getSignature(accessToken).then((res) => {
        const imgTag = res.data;
        const srcMatch = imgTag.match(/src="([^"]+)"/);
        //console.log(imgTag,srcMatch)
        // if (srcMatch && srcMatch[1]) {
        const srcValue = srcMatch[1];
        // setSignature(srcValue);
        //console.log(srcValue)

        getDynamicContractDetails(location?.state?.project, accessToken).then(async (res) => {
          console.log(res);
          setDynamicData(res.data);
          //console.log(res.data)
          // Parse and format address
          const addressObj = JSON.parse(res?.data?.customerAddress);
          const addressString = `${addressObj?.address}, ${addressObj?.city}, ${addressObj?.state} ${addressObj?.zipcode}, ${addressObj?.country}`;
          const manualPayment = JSON.parse(res?.data?.manualPayment);
          const digitalPayment = JSON.parse(res?.data?.digitalPayment);

          // Format date
          const offsetDateTime = res?.data?.projectStartDate;
          const date = new Date(offsetDateTime);
          const americanDateFormat = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
          const endDate = new Date(res?.data?.projectEndDate);
          const endDateFormat = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;

          // Handle base64 image

          const response = await getTenantDetails(accessToken);

          const tenantImgTag = `<div style="height: 100px !important;"><img src="${response.data.tenantLogo}" alt="Tenant Logo"   width="130" height="110"/></div>`;
          const formatToHumanReadable = (text) => {
            return text.replace(/([A-Z])/g, ' $1') // Convert camelCase to words
              .replace(/_/g, ' ')        // Convert snake_case to words
              .toLowerCase()             // Convert the entire string to lowercase
              .replace(/^./, str => str.toUpperCase()); // Capitalize the first letter
          };

          // Prepare tool services and materials strings
          const toolServicesString = res?.data?.toolServices
            .map(formatToHumanReadable)
            .join(', ');
          setFinanceData(res?.data?.tenantConfigurationNameValuePairs.map(item => item));

          const materialString = res?.data?.materials
            .map(formatToHumanReadable)
            .join(', ');
          // const addressString = `${addressObj?.address}, ${addressObj?.city}, ${addressObj?.state} ${addressObj?.zipcode}, ${addressObj?.country}`;
          const validProjectName = res.data.projectName
            .split(" ") // Split by space (or another delimiter if needed)
            .filter((word) => word && word.toLowerCase() !== "null") // Remove `null` entries
            .join(" "); // Rejoin the valid words
            const sectionToCheck = '<p><strong>2. Confirming Details:</strong> Once you are ready to order, here are the final details I will need to confirm with the factory below as [trade] Specifications</p><p><strong>[trade] Specifications:</strong> The [trade] shall be constructed in accordance with the following specifications:</p><ul><li><strong>Dimensions:</strong> [Length] x [Width] x [Height] feet.</li><li><strong>Frame Material:</strong> [Material, e.g., galvanized steel, aluminum].</li><li><strong>Glazing Material:</strong> [Material, e.g., polycarbonate panels, glass].</li><li><strong>Frame Color:</strong></li><li><strong>Kneewall/ Door Drop Height:</strong></li><li><strong>Roof Pitch:</strong></li><li><br></li></ul>';

            let updatedHtmls = responseData.data;
            
            // Check if the section exists in the response data
            // console.log(responseData.data, responseData.data.includes(sectionToCheck));
            
            // if (responseData.data.includes(sectionToCheck)) {
            //   if (validProjectName.trim().toLowerCase() !== "greenhouse") {
            //     updatedHtmls = updatedHtmls.replace(sectionToCheck, " ");  // Update updatedHtml
            //   }
            // }
           let updatedHtml = updatedHtmls
            .replaceAll('[Contractor Name]', `<b>${res?.data?.tenantName}</b>`)
            .replaceAll('[Contractor Address]', `${res?.data?.tenantAddress}, &nbsp;${res?.data?.tenantCity},&nbsp;</br> ${res?.data?.tenantState},&nbsp;${res?.data?.tenantCountry}`)
            .replaceAll('[Customer Name]', `<b>${res?.data?.customerName}</b>`)
            .replaceAll('[Customer Address]', `<b>${addressObj?.address}</b>`)
            .replaceAll('[City, State, ZIP Code]', ` ${addressObj?.city}, ${addressObj?.state} ${addressObj?.zipcode}`)
            .replaceAll('[trade]', `<b>${validProjectName}</b>`)

            .replaceAll('[Description of any additional services]', `<b>${toolServicesString} are the services providing</b>`)
            .replaceAll('[Description of work to be performed]', `<b>${validProjectName} is the work to be performed</b>`)
            .replaceAll('[Description of materials to be used]', `<b>${materialString} are the materials to be used</b>`)
            .replaceAll('[Date]', `<b>${americanDateFormat}</b>`)
            .replaceAll('[Timeline, e.g., number of days or weeks]', `<b>${res.data.totalDays}</b>`)
            .replaceAll('[Total Amount]', `<b>$${res.data.totalCost}</b>`)
            .replaceAll('[Project Start Date]', `<b>${americanDateFormat}</b>`)
            .replaceAll('[Project Completion Date]', `<b>${endDateFormat}</b>`)
            .replaceAll('[Customer Phone Number]', `<b>${res?.data?.customerMobileNo}</b>`)
            .replaceAll('[Tenant Phone Number]', `<b>${res?.data?.tenantMobileNo}</b>`)
            .replaceAll('[Customer Email Address]', `<b>${res?.data?.customerEmail}</b>`)
            .replaceAll('[Tenant Email Address]', `<b>${res?.data?.tenantEmail}</b>`)
            .replaceAll('[Accepted payment methods]', `<b>${manualPayment},&nbsp;${digitalPayment} are the accepted payment methods </b>`)
            .replaceAll(placeholderText, () => {
              const tableHeader = res.data.tenantConfigurationNameValuePairs.map(item => `<td><b>${item.name}</b></td>`).join('');
              const tableRow = `<tr>${res.data.tenantConfigurationNameValuePairs.map(item => `<td>${item.value}</td>`).join('')}</tr>`;

              return `
                  <table >
                    <thead >
                      <tr>
                        ${tableHeader}
                      </tr>
                    </thead>
                    <tbody>
                      ${tableRow}
                    </tbody>
                  </table>
                  <div>&nbsp;</div>
                  `;
            })
            .replaceAll('$[Deposit Amount]', () => {
              // Find the item where name is 'partPay'
              const partPayItem = res.data.tenantConfigurationNameValuePairs.find(item => item.name === "part pay");

              // If found, use its value; otherwise, default to an empty string or a fallback message
              const partPayValue = partPayItem ? partPayItem.value : "No partPay value found";

              // Return the value directly as the replacement for placeholderText
              return `<b>${partPayValue}  &nbsp;</b>`;
            })
            .replaceAll('$[Final Payment Amount] ', () => {
              // Find the item where name is 'partPay'
              const partPayItem = res.data.tenantConfigurationNameValuePairs.find(item => item.name === "final");

              // If found, use its value; otherwise, default to an empty string or a fallback message
              const partPayValue = partPayItem ? partPayItem.value : "No partPay value found";

              // Return the value directly as the replacement for placeholderText
              return `<b>${partPayValue} &nbsp; </b>`;
            })
            .replaceAll('<h2>Residential Contract Agreement</h2>', `<div style="width:30px; display:block; font-size:20px;"><div style="width:100%; display:flex; justify-content:center; align-items:center; flex-direction:column; font-size:20px; text-align:center;"><h2>Residential Contract Agreement</h2></div><div>${tenantImgTag}</div>
                <div>From:</div>
                <div ><b>${res?.data?.tenantName}</b></div>
                <div><b>${res?.data?.tenantAddress}, &nbsp;${res?.data?.tenantCity},&nbsp;${res?.data?.tenantState},&nbsp;${res?.data?.tenantCountry}</b></div>
<div>${res?.data?.tenantMobileNo}</div>


                <div>&nbsp;</div>
                <div>To:</div>
                <div><b>${res?.data?.customerName}</b></div>
                <div><b>${addressString}</b></div>
                <div>&nbsp;</div>

                </div>`)


            // Set the response with the updated HTML
            setResponse((prevHtml) => `
  <div style="width:30px; display:block; font-size:20px;">

    <div>${tenantImgTag}</div>
   
  </div>
     `+ `${updatedHtml}${imgTag}<div ><div>${res?.data?.tenantName}</div></div>`);
          });
        // }
        //  else {
        //   console.error("No src attribute found in the image tag.");
        // }
      }
      );
    });
  };
  const handleEditorChange = (content: string) => {
    setContent(content);
    //console.log(content.length)
  };
  const doc = async () => {
    // const response = await getUserSession(accessToken);
    //console.log("hii");
    setRole(userSessionDetails?.role.name);

    if (userSessionDetails?.role.name === "sales") {
      setLoading(true);
      try {
        Dynamic();

      } catch (error) {
        console.error('Error fetching contract:', error);
      } finally {
        setLoading(false);
      }
      // const data = await fetch(res.data);
      // ////console.log(data)
    } else {
      ////console.log(location.state.project.projectId)
      const response = await getContractDetails(location?.state?.project, accessToken);
      //console.log(response.data)
      setResponse(response.data);
    }
  }
  React.useEffect(() => {
    // getTenantConfig();


    // Initialize the Quill editor
    if (editorRef.current) {
      const quill = editorRef.current.getEditor();
      if (content !== undefined) {
        quill.clipboard.dangerouslyPasteHTML(content);

      }
      else {
        quill.clipboard.dangerouslyPasteHTML(responses);
      }

    }
    // getDynamicContract();
    doc();
  }, []);


  const modules = {
    toolbar: [
      [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
      [{ 'size': [] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
      ['link', 'image', 'video'],
      ['clean'],
    ],
  };
  const handleContent = () => {
    setResponse(content);
  }


  const checkDependentTaskCompleted = async () => {
    const result = await getDependentTask(location?.state?.project, APPROVE_CONTRACT_TASK, accessToken);
    if (result.data === false) {
      handleSaveToDatabase();
    }
    else {
      setShowWarning(true);
    }
  }

  const handleSaveToDatabase = async () => {
    //console.log(content)

    // handleContent();
    try {
      const formData = new FormData();
      // formData.append('file', file);

      // const contractData = {
      //   contractInfo: formData,
      // };
      //console.log("hii", content, responses);
      if (content !== undefined) {
        const blob = new Blob([content], { type: 'text/html' });
        // let blob: any = document.getElementById("contractTemplate");
        // blob = new Blob([blob], {type: 'text/html'})
        //console.log(content)
        formData.append('file', blob, 'contract.html');
        if (role === 'customer') {
          formData.append('image', file);

        }

      }
      else {
        const response = new Blob([responses], { type: 'text/html' });
        // let blob: any = document.getElementById("contractTemplate");
        // blob = new Blob([blob], {type: 'text/html'})
        //console.log(content)

        formData.append('file', response, 'contract.html');
        if (role === 'customer') {
          formData.append('image', file);

        }

      }
      // formData.append('image', signature);
      // formData.append('image', file);

      // //console.log(responses)

      if (count === 0) {
        setDisabled(true);
        const response = await createContract(formData, location?.state?.project, accessToken);
        //console.log(response)

        if (response.status === 200) {
          setCount(count + 1);
          setSuccess(true)
        }
        else {
          setError(true);
          setDisabled(false);
        }
      }

      // Handle response here
    } catch (error) {
      console.error("Error while saving to database:", error);
      // Handle error here
    }
  };

  //   if (response.ok) {
  //     // Data was saved successfully

  //     setOpenConfirmationFailed(true);
  //   } else {
  //     // Handle error response from the server
  //     console.error('Failed to save data to the database');
  //   }

  // })
  //   .catch((error) => {
  //     // Handle network error
  //     console.error('Network error:', error);
  //   });

  const handleFile = async (tempFile: any) => {
    setFile(tempFile);

  }



  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

  // const handleEditorChange = (response) => {
  //   //console.log(response)
  //   setResponse(response);
  // };
  return (
    <>

      <Grid item xs={12} md={10} mb={1} mt={1} className='headerTable ' style={{ fontWeight: '500 !important' }}>Contract  View
        {/* <Typography className='flaotEnd '><span><DownloadIcon /></span>&nbsp; <span><PreviewIcon /></span> &nbsp; <span><ImportExportIcon /></span>&nbsp; <span><SendIcon /></span></Typography> */}
      </Grid>
      {/* {loading ? <div>Loading...</div>: */}
      <Grid container rowSpacing={1} mb={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }} justifyContent="center" className='contractCard '>
        {/* <Grid item xs={12} md={12} mt={5} m={3} className={loading ? 'blur-background' : ''}>
            <Backdrop open={loading} style={{ zIndex: 9999 }}>
                <CircularProgress color="inherit" />Loading...
            </Backdrop> */}
        <Grid item xs={12} md={12} mt={5} m={3}>
          <Grid item xs={12} container justifyContent="flex-end" >
            <Tooltip title="Download Pdf Format">
              <IconButton onClick={() => convertToPdf()} sx={{ color: "red" }}>
                <PictureAsPdfIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          <div ref={contentRef}>
            {/* <RichTextEditor
      value={responses}
      onChange={undefined}
    /> */}
            {/* <ReactQuill 
              theme="snow"
              value={responses}
              //onChange={handleChange}
              modules={modules}
              readOnly={role === 'customer'}

            /> */}
            <Editor
              value={responses}
              readOnly={role === 'customer'}// Change to true if you want the editor to be read-only
              onChange={handleEditorChange} // Pass the callback to the Editor component
            // onChange={function (content: string): void {
            //   throw new Error('Function not implemented.');
            // } }        // onChange={handleEditorChange}
            />
            {/* <RichTextEditor onChange={handleEditorChange} /> */}
            {/* <Typography variant='h4' align='center' mb={1}><img src={logo} alt="" width='80px' height='80px' /></Typography>


            <Typography variant='h4' align='center' mb={2}>Residential Contract Agreement</Typography>


            This Residential Contract Agreement ("Agreement") is entered into between [Contractor Name], located at [Contractor Address] ("Contractor") and [Customer Name], located at [Customer Address] ("Customer") on [Date].


            <Typography variant='h5' mt={1}>1. Scope of Work:</Typography>
            The Contractor agrees to perform the following services (the "Work"):
            - [Description of work to be performed]
            - [Description of materials to be used]
            - [Description of any additional services]


            <Typography variant='h5' mt={1}>2. Payment:</Typography>
            The Customer agrees to pay the Contractor the sum of [Total Amount] for the Work described in Section 1. Payment shall be made as follows:
            - [Details of payment schedule, e.g., deposit, progress payments, final payment]
            - [Accepted payment methods]


            <Typography variant='h5' mt={1}>3. Timeline:</Typography>
            The Contractor agrees to complete the Work within [Timeline, e.g., number of days or weeks] from the commencement date.


            <Typography variant='h5' mt={1}>4. Changes and Additional Work:</Typography>
            Any changes to the scope of work or additional services requested by the Customer must be agreed upon in writing by both parties. Additional work may incur additional charges.


            <Typography variant='h5' mt={1}>5. Permits and Approvals:</Typography>
            The Contractor is responsible for obtaining all necessary permits and approvals required for the completion of the Work.


            <Typography variant='h5' mt={1}>6. Warranties:</Typography>
            The Contractor warrants that all work performed will be of good quality and in accordance with industry standards. Any defects arising from the Work will be rectified by the Contractor at no additional cost to the Customer.


            <Typography variant='h5' mt={1}>7. Termination:</Typography>
            Either party may terminate this Agreement upon written notice if the other party fails to fulfill its obligations under this Agreement. In the event of termination, the Customer shall pay the Contractor for all work performed up to the date of termination.


            <Typography variant='h5' mt={1}>8. Dispute Resolution:</Typography>
            Any disputes arising out of or related to this Agreement shall be resolved through arbitration in accordance with the laws of [Jurisdiction].


            <Typography variant='h5' mt={1}>9. Governing Law:</Typography>
            This Agreement shall be governed by and construed in accordance with the laws of [Jurisdiction].


            <Typography variant='h5' mt={1}>10. Entire Agreement:</Typography>
            This Agreement constitutes the entire agreement between the parties with respect to the subject matter hereof and supersedes all prior agreements and understandings, whether written or oral.


            <Typography mt={2}>IN WITNESS WHEREOF, the parties have executed this Agreement as of the date first above written.</Typography> */}

            <Grid container spacing={1}>

              <Grid item xs={12} md={10}>
                {/* [Signature of Contractor]
                [Printed Name of Contractor]
                [Date] */}
              </Grid>
              <Grid item xs={12} md={2}>
                <Box
                  //sx={{ margin: "8px" }}
                  //p={2}
                  width={100}
                  height={100}
                  margin="20px auto"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                ><img src={file} alt="" /></Box>
                {/* [Signature of Customer] */}
                {role === 'customer' && (
                  <>
                    <Typography>{accessTokenPayload.name} &nbsp; Customer</Typography>
                    <Typography>{moment().format('DD MMM YYYY')}</Typography>
                  </>
                )}
              </Grid>
            </Grid>


            {/* <Grid container spacing={1}> */}
            <Grid container spacing={1}>

              {role === 'customer' ? (
                <> <Grid item xs={12} md={10}>
                </Grid>

                  <Grid item xs={12} md={2}>

                    {/* {matRecSignature && (
                  <Box
                    sx={{ margin: "8px" }}
                    border={1}
                    borderColor="primary.main"
                    borderRadius="10px"
                    p={2}
                    width={150}
                    height={100}
                    margin="20px auto"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <img
                      src={matRecSignature}
                      alt="Signature"
                      style={{
                        maxWidth: "100%",
                        maxHeight: "100%",
                      }}
                    />
                  </Box>
                )} */}

                    <>
                      <Button
                        sx={{ margin: '8px', minWidth: '100px' }}
                        className="primaryTonalButton"
                        onClick={handlematrecOpen}
                      >
                        Signature
                      </Button>
                      {!signatureRef.current || signatureRef.current.isEmpty() ? (
                        <Tooltip title="Please put your signature first" arrow placement="top-end">
                          <span>
                            <Button
                              sx={{ margin: '8px', minWidth: '100px' }}
                              // className='successButton'
                              disabled
                            >
                              Save
                            </Button>
                          </span>
                        </Tooltip>
                      ) : (
                        <Tooltip title="Click to save" arrow placement="top-end">
                          <Button
                            sx={{ margin: '8px', minWidth: '100px' }}
                            className="successButton"
                            onClick={handleSaveToDatabase}
                          >
                            Save
                          </Button>
                        </Tooltip>
                      )}
                    </>


                  </Grid>
                </>
              ) : (
                <> <Grid item xs={12} md={11}>
                </Grid>

                  <Grid item xs={12} md={1}>

                    <Tooltip title="Click to save" arrow placement="top-end">
                      <Button
                        sx={{ margin: '8px', minWidth: '100px' }}
                        className="successButton"
                        onClick={handleSaveToDatabase}
                        disabled={disabled}
                      >
                        Save
                      </Button>
                    </Tooltip>
                  </Grid>
                </>
              )

              }
            </Grid>

            <Modal
              open={openmatrec}
              aria-labelledby="signature-pad-modal"
            >
              <div
                style={{
                  position: "fixed",
                  top: 100,
                  right: 100,
                  bottom: 100,
                  left: 100,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: 500, // Set your desired height
                  width: 500, // Set your desired width
                  backgroundColor: "white",
                  margin: "0 auto",
                }}
              >
                <h4>Please put your signature here</h4>
                <Grid sx={{ border: "1px solid" }}>
                  <SignatureCanvas
                    ref={signatureRef}
                    penColor="black"
                    canvasProps={{ width: 400, height: 250 }}
                  />
                </Grid>

                <div
                  style={{
                    marginTop: "16px",
                    marginBottom: "16px",
                  }}
                >
                  <Button
                    sx={{ margin: "8px" }}
                    variant="contained"
                    onClick={() => signatureRef.current.clear()}
                  >
                    Clear
                  </Button>
                  <Button
                    sx={{ margin: "8px" }}
                    className='SucessSolid'
                    onClick={handlematrecSave}
                  >
                    Save
                  </Button>
                  <Button
                    sx={{ margin: "8px" }}
                    className='CancelSolid'
                    onClick={handlematrecCancel}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </Modal>
            <Dialog open={success} >
              <DialogTitle className='success'>
                <Box paddingLeft={8} paddingRight={8}>
                  <Typography textAlign={'center'}>
                    <CheckCircleOutlinedIcon sx={{ fontSize: "50px", color: "white" }} /></Typography>
                  <Typography textAlign={'center'} color='white' fontSize={'20px'} fontWeight={'400'} >
                    Success
                  </Typography>
                </Box>
              </DialogTitle>
              <Box paddingLeft={8} paddingRight={8}>
                <DialogContent >
                  <DialogContentText textAlign={'center'} fontSize={'18px'} fontWeight={'500'}>Contract Upload Successfully</DialogContentText>
                </DialogContent>
                <DialogActions style={{ justifyContent: "center" }}>
                  <Button variant='contained' className="successButton" onClick={handleAlertClose}>Ok</Button>
                </DialogActions>
              </Box>
            </Dialog>

            <Dialog open={error} >
              <DialogTitle className='error'>
                <Box paddingLeft={8} paddingRight={8}>
                  <Typography textAlign={'center'}>
                    <CancelOutlinedIcon sx={{ fontSize: "50px", color: "white" }} /></Typography>
                  <Typography textAlign={'center'} color='white' fontSize={'20px'} fontWeight={'400'} >
                    Error
                  </Typography>
                </Box>
              </DialogTitle>
              <Box paddingLeft={8} paddingRight={8}>
                <DialogContent >
                  <DialogContentText textAlign={'center'} fontSize={'18px'} fontWeight={'500'}>Error Couldn't Upload Contract </DialogContentText>
                </DialogContent>
                <DialogActions style={{ justifyContent: "center" }}>
                  <Button variant='contained' sx={{ backgroundColor: "#df3333ed" }} onClick={handleAlertClose}>Close</Button>
                </DialogActions>
              </Box>

            </Dialog>
          </div >
        </Grid>
      </Grid>

      {/* } */}
    </>
  );
};


export default ContractSigningTemplate;
